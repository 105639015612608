import React from 'react'

function CreatePortfolio({formik,filterVerify,servicesOptions}) {
    return (
        <div>
            <h1 className='text-center text-xl text-gray-950 '>Create Portfolio</h1>
            {/* <ServiceProviderStep /> */}
            <form
                onSubmit={formik.handleSubmit}
                className='flex flex-col space-y-3 px-5 py-8'
            >
                <div className='relative w-full '>
                    <input
                        type='text'
                        id='name'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    // onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='name'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Name
                    </label>
                </div>

               
                <div className='relative w-full mb-6'>
                    <select name='service'
                        onChange={formik.handleChange}
                        value={formik.values.service}
                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                    >
                        <option>Select services</option>
                        {servicesOptions.map((option, index) => (
                            <option key={index} value={option} >
                                {option}
                            </option>
                        ))}
                    </select>
                    <label
                        htmlFor='service'
                        className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Service <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.service && formik.errors.service ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formik.errors.service}
                        </div> : null}
                </div>
                <div className='relative w-full mt-1'>
                    <textarea
                        id='description'
                        name='description'
                        className='p-2 w-full border border-gray-600 focus:outline-none placeholder-slate-600 text-black rounded-lg'
                        placeholder='Enter Description'
                        rows='5'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                    <label
                        htmlFor='description'
                        className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                    >
                        Description
                    </label>
                </div>
                {/* <input
            type='file'
            className='max-w-md w-52 text-white'
          /> */}
                <div className='flex justify-center items-center'>
                    {/* <button className='btn max-w-md w-52' onClick={() => handleBack()}>
                        Back
                    </button> */}
                    <input
                        type='submit'
                        className='btn max-w-md w-52 ml-2'
                        value='submit'
                    />
                </div>
            </form>
        </div>
    )
}

export default CreatePortfolio