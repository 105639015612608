import { Base_url, org_url } from "../../utils/constant";
import Axios from "axios"

const token=localStorage.getItem('authToken')

export const organizationImages = ()=>{
    return Axios({
        method:"GET",
        url:`${Base_url}/api/organization/organization-list/`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const allOrgs = ()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const unverifiedCompanies = (userId)=>{
    console.log("test inside unverifiedCompanies")
    return Axios({
        method:"GET",
        url:`${org_url}/company/unverified/${userId}`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const organizationRegister = (
    payload,
)=>{
   
    return Axios({
        method:"POST",
        url:`${org_url}/company`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const postReview = (
    payload,
)=>{
   
    return Axios({
        method:"POST",
        url:`${org_url}/review`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const uploadBuisnessProof = (
    formData,
    org_id,
    docType
)=>{
   
    return Axios({
        method:"POST",
        url:`${org_url}/company/uploadFiles/${org_id}?documentFileType=${docType}`,
        // body:formData,
        headers:{
           Accept:'application/json',
           'Content-Type': 'multipart/form-data',
           Authorization:`Bearer ${token}`
        },
        data:formData
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}



export const addUserToOrg = (
    companyId,
    userId,
)=>{
   
    return Axios({
        method:"PUT",
        url:`${org_url}/company/addUsers/${companyId}?userIds=${userId}`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        // data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const companyProfile = (organization_id)=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/details/${organization_id}`,
        headers:{
            //    Accept:'multipart/form-data',
            //    'Content-Type': 'multipart/form-data',
            Authorization:`Bearer ${token}`
            },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getDocTypes = ()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/document-types`,
        // headers:{
        //     //    Accept:'multipart/form-data',
        //     //    'Content-Type': 'multipart/form-data',
        //     Authorization:`Bearer ${token}`
        //     },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getCountries = ()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/location/countries`,
        headers:{
            //    Accept:'multipart/form-data',
               'Content-Type': 'application/json',
            },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getProvince = (countryId)=>{
    return Axios({
        method:"GET",
        url:`${org_url}/location/countries/${countryId}/provinces`,
        headers:{
            //    Accept:'multipart/form-data',
               'Content-Type': 'application/json',
            },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getCities = (provinceId)=>{
    return Axios({
        method:"GET",
        url:`${org_url}/location/provinces/${provinceId}/cities`,
        headers:{
            //    Accept:'multipart/form-data',
               'Content-Type': 'application/json',
            },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const userOrganization = ()=>{
    return Axios({
        method:"GET",
        // url:`${org_url}/company/userId=${userId}`,
        url:`${org_url}/company`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const orgReviews = (org_id)=>{
    return Axios({
        method:"GET",
        // url:`${org_url}/company/userId=${userId}`,
        url:`${org_url}/review/${org_id}`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}


export const getCompanyLogo = (companyId)=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/logo/${companyId}`,
        responseType:'blob'
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getAllCompanies = (query)=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/search?${query}`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}


export const organizationSearch = (name="abcde")=>{
    return Axios({
        method:"GET",
        url:`${org_url}/api/company?name=${name}`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const organizationUpdate = (
    payload,
    id,
)=>{
   
    return Axios({
        method:"PUT",
        url:`${org_url}/company`,
        body:JSON.stringify(),
        // headers:{
        // //    Accept:'multipart/form-data',
        // //    'Content-Type': 'multipart/form-data',
        // // Authorization:`Bearer ${token}`
        // },
        data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getAgrigatedCity = ()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/aggregatedCity`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getEmployeeRange = ()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/company/employeeRange`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

