import { useFormik } from "formik"
import { forgotPasswordSchema, loginSchema, signUpSchema } from "../../validation"

export const useLoginForm=(handleSubmit)=>{
    return useFormik({
        initialValues:{
          userName:"",
          password:""
        },
        validationSchema:loginSchema,
        onSubmit:values=>{
            handleSubmit(values)
        }
      })
}


// export const userProfileForm=(handleSubmit)=>{
//   return useFormik({
//       initialValues:{
//         phoneNumber:"",
//         addressLine1:"",
//         addressLine2:"",
//         pinCode:"",
//         country:"",
//         province:"",
//         city:""
//       },
//       // validationSchema:loginSchema,
//       onSubmit:values=>{
//           handleSubmit(values)
//       }
//     })
// }

export const useRegisterForm=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            firstName: "",
            lastName:"",
            email: "",
            phoneNumber:"",
            addressLine1:"",
            addressLine2:"",
            postalCode:"",
            country:"",
            state:"",
            city:"",
            password: "",
            captcha: "",
            terms: false
          },
          validationSchema: signUpSchema,
          onSubmit: values => {
            console.log("values",values)
            handleSubmit(values)
          }
      })
}

export const useForgotPasswordForm=(handleSubmit)=>{
  return useFormik({
      initialValues: {
          email: "",
          newPassword: ""
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: values => {
          handleSubmit(values)
        }
    })
}