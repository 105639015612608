import React from 'react'

function SocialMediaInfo({ formik }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mt-2 grid grid-cols-2 gap-2 p-4'>
                <div className='relative mb-4'>
                    <input
                        type='url'
                        name='LinkedIn'
                        id='LinkedIn'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.LinkedIn && formik.errors.LinkedIn ? 'border-red-600' : ' border-gray-400'}`}
                        placeholder='Enter the LinkedIn Url'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.LinkedIn}
                    />
                    {formik.touched.LinkedIn && formik.errors.LinkedIn ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.LinkedIn}
                        </div> : null
                    }
                    <label
                        htmlFor='LinkedIn'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        LinkedIn
                    </label>
                </div>

                <div className='relative mb-4'>
                    <input
                        type='url'
                        name='faceBook'
                        id='faceBook'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.faceBook && formik.errors.faceBook ? 'border-red-600' : ' border-gray-400'}`}
                        placeholder='Enter the Facebook Url'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.faceBook}
                    />
                    {formik.touched.faceBook && formik.errors.faceBook ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.faceBook}
                        </div> : null
                    }
                    <label
                        htmlFor='faceBook'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Facebook
                    </label>
                </div>

                <div className='relative mb-4'>
                    <input
                        type='url'
                        name='instagram'
                        id='instagram'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.instagram && formik.errors.instagram ? 'border-red-600' : ' border-gray-400'}`}
                        placeholder='Enter the Instagram Url'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.instagram}
                    />
                    {formik.touched.instagram && formik.errors.instagram ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.instagram}
                        </div> : null
                    }
                    <label
                        htmlFor='instagram'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Instagram
                    </label>
                </div>

                <div className='relative mb-4'>
                    <input
                        type='url'
                        name='xMedia'
                        id='xMedia'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.xMedia && formik.errors.xMedia ? 'border-red-600' : ' border-gray-400'}`}
                        placeholder='Enter the X(Twitter) Url'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.xMedia}
                    />
                    {formik.touched.xMedia && formik.errors.xMedia ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.xMedia}
                        </div> : null
                    }
                    <label
                        htmlFor='xMedia'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        X(Twitter)
                    </label>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <button type='submit' className='btn'>Submit</button>
            </div>
        </form>
    )
}

export default SocialMediaInfo