import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Inbox() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const navigate = useNavigate()
    const emails = [
        {
            id: 1,
            sender: 'john@example.com',
            subject: 'Meeting Reminder',
            content: 'Don’t forget about the meeting tomorrow at 10:00 AM.',
            time: '10:00 AM',
        },
        {
            id: 2,
            sender: 'jane@example.com',
            subject: 'Project Update',
            content: 'The project is on track and will be delivered by next week.',
            time: '11:30 AM',
        },
        {
            id: 3,
            sender: 'support@example.com',
            subject: 'Account Verification',
            content: 'Please verify your account to continue using our services.',
            time: '2:15 PM',
        },
    ]
    return (
        <div
            className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
        >
            <div className={`flex flex-col w-full max-w-7xl max-h-full h-full ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-11 mt-4 px-2`}>
                <div className="p-4">
                    <div className='flex justify-between'>
                      <h1 className="text-2xl font-bold mb-4">Inbox</h1>
                      <button className='bg-blue-800 p-2 text-white rounded mb-2' onClick={()=>navigate('/admin/send-mail')}>Send Mail</button>
                    </div>
                    <div className="border rounded-lg shadow-lg overflow-hidden">
                        {emails.map((email) => (
                            <div
                                key={email.id}
                                className="p-4 border-b hover:bg-gray-100 transition-all"
                            >
                                <div className="flex justify-between items-center">
                                    <h2 className="font-semibold text-lg">{email.subject}</h2>
                                    <span className="text-sm text-gray-500">{email.time}</span>
                                </div>
                                <p className="text-sm text-gray-700">{email.sender}</p>
                                <p className="text-sm text-gray-600 mt-1 truncate">{email.content}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox