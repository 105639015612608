import React from 'react'
import { useSelector } from 'react-redux'

function RefundPolicy() {
    const { isDark,fontSize } = useSelector((state) => state.displaySettingsState)
  return (
    <div className='min-h-screen flex flex-col'>
      <main className='flex-grow'>
        <div className='pt-16'>
          <div className={`w-full p-4  ${!isDark ? 'bg-blue-900 text-white' : 'bg-slate-500 text-white'}`}>
            <h1 className='text-2xl mt-2'>Refund policy</h1>
          </div>
          <section className={`flex flex-col w-full justify-between p-8 ${!isDark? 'bg-white':'dark-mode'}`}>
          <p className='' style={{fontSize:`${fontSize+6}px`}}>Thank you for your interest in our products. Please note that all sales are final, and we do not offer refunds or exchanges under any circumstances.</p>
          <p className='' style={{fontSize:`${fontSize+6}px`}}>If you have any questions or require further assistance, please feel free to contact us at the following email address: <b>compliance@inkqubee.com</b></p>
          <p className='' style={{fontSize:`${fontSize+6}px`}}>We appreciate your understanding and support!</p>
          </section>
        </div>
      </main>
    </div>
  )
}

export default RefundPolicy