import React from 'react'
import { useSelector } from 'react-redux'
import empty from '../../../assets/empty.png'


function Careers() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    return (
      <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
          <div className='flex flex-col w-full max-w-7xl justify-center items-center  h-full border '>
               {/* <img src={UnderConstruction} alt='under-construction' className='h-60 w-60'/>
               <p>Under construction..</p> */}
               <div className='flex flex-col justify-center items-center gap-2 w-full max-w-7xl  border-gray-600 h-96 px-6 py-2'>
                    <h1 className='text-gray-700 text-2xl'>Currently we don't have any openings</h1>
                    <img
                    src={empty}
                    alt='noJobs'
                    className='h-52 mt-2'
                    />
               </div>
          </div>
      </div>
  )
}

export default Careers