import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useCourceContactForm } from '../../../helper/public/publicFormHook'
import { message } from 'antd'
import { contactInkqubee } from '../../../helper/auth/authRequestCall'
import SuccessModal from '../../../utils/Modal/SuccessModal'

function Contact({ fontSize }) {
    const { name } = useParams()
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [email, setEmail] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false)
    const navigate=useNavigate()

    const handleSubmit = (values) => {
        const customerName = values.name
        const companyName = values.companyName
        const mobileNumber = values.mobileNumber
        const email = values.email
        const companyHeadquarter = values.companyHeadqarters
        const requirement = values.requirments

        contactInkqubee(customerName, companyName, mobileNumber, email, companyHeadquarter, requirement)
            .then((res) => {
                if (res.status === 200) {
                    setOpenSuccess(true)
                    formik.setFieldValue("name", "")
                    formik.setFieldValue("companyName", "")
                    formik.setFieldValue("mobileNumber", "")
                    formik.setFieldValue("email", "")
                    formik.setFieldValue("companyHeadqarters", "")
                    formik.setFieldValue("requirments", "")
                } else {
                    message.error("Failed to send your enquiry")
                }
            })
            .catch((err) => console.error("Error:", err))
    }

    const formik = useCourceContactForm(handleSubmit)
    return (
        <Fragment>
            <div className={`min-h-screen flex flex-col justify-center items-center p-8 ${!isDark ? 'bg-blue-950' : 'dark-mode'} bg-opacity-90`}>
                <div className={`w-full max-w-2xl justify-center ${!isDark ? 'bg-blue-100' : 'bg-slate-500 '} space-y-6 rounded-lg mt-16`}>
                    <h1 className='text-black text-center py-2' style={{ fontSize: `${fontSize + 9}px` }}>Enter the details below</h1>
                    <form
                        className='flex flex-col space-y-4 px-5 py-3'
                        onSubmit={formik.handleSubmit}
                    >
                        <div className='relative w-full mb-4'>
                            <input
                                type='text'
                                id='name'
                                placeholder='Enter your name'
                                className='w-full p-2 border border-gray-800 focus:outline-none focus:border-blue-900 placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            <label
                                htmlFor='name'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-blue-100'
                            >
                                Name <span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>

                        <div className='relative w-full '>
                            <input
                                type='tel'
                                id='mobileNumber'
                                maxLength={10}
                                className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                placeholder='Enter your mobile number'
                                onChange={formik.handleChange}
                                value={formik.values.mobileNumber}
                            />
                            <label
                                htmlFor='mobileNumber'
                                className='absolute font-semibold top-0 left-4 -mt-2 px-1 text-sm transition-all text-black bg-blue-100'
                            >
                                Mobile Number <span className='text-red-600 ml-1'>*</span>
                            </label>
                            {
                                formik.touched.mobileNumber && formik.errors.mobileNumber ?
                                    <div className='text-red-700 ml-2 '>
                                        {formik.errors.mobileNumber}
                                    </div> : null
                            }

                        </div>

                        <div className='relative w-full mb-4'>
                            <input
                                type='email'
                                id='email'
                                className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                placeholder='Example@mail.com'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            <label
                                htmlFor='email'
                                className='absolute font-semibold top-0 left-4 -mt-2 px-1 text-sm transition-all text-black bg-blue-100'
                            >
                                Email <span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>

                        <div className='relative w-full mb-4'>
                            <input
                                type='text'
                                id='companyName'
                                placeholder='Enter Your Company Name'
                                className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                onChange={formik.handleChange}
                                value={formik.values.companyName}
                            />
                            <label
                                htmlFor='companyName'
                                className='absolute font-semibold top-0 left-4 -mt-2 px-1 text-sm transition-all text-black bg-blue-100'
                            >
                                Company Name <span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>

                        <div className='relative w-full mb-4'>
                            <input
                                type='text'
                                id='companyHeadqarters'
                                placeholder='Enter Your Company Headquarters'
                                className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                onChange={formik.handleChange}
                                value={formik.values.companyHeadqarters}
                            />
                            <label
                                htmlFor='companyHeadqarters'
                                className='absolute font-semibold top-0 left-4 -mt-2 px-1 text-sm transition-all text-black bg-blue-100'
                            >
                                Company Headquarters <span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>

                        <div className='relative w-full mb-4'>
                            <textarea
                                id='requirments'
                                className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100'
                                placeholder='Enter your requirements here'
                                maxLength={500}
                                rows='5'
                                onChange={formik.handleChange}
                                value={formik.values.requirments}
                            />
                            <label
                                htmlFor='requirments'
                                className='absolute top-0 font-semibold left-4 -mt-2 px-1 text-sm transition-all text-black bg-blue-100'
                            >
                                Your requirments <span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>
                        <div className='flex justify-center items-center'>
                            <button
                                className='btn max-w-md w-52'
                                onClick={()=>navigate(-1)}
                            >Back</button>
                            <input
                                type='submit'
                                className='btn max-w-md w-52 ml-2'
                            />
                        </div>
                    </form>
                </div>
                <SuccessModal open={openSuccess} onClose={() => setOpenSuccess(false)} message={"Thanks for contact us!We will reach out you shortly"} path={'/'} buttonName="Go to Home" />
            </div>
        </Fragment>
    )
}

export default Contact
