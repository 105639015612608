import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types'
import { DialogContentText } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import qrImage from '../../assets/PaymentQr.jpg'
import { message } from 'antd';
import UpiPaymentModal from './UpiPaymentModal';
import OpenBank from './OpenBank';
import googlePay from '../../assets/googlePay.webp'
import allPay from '../../assets/UpiPay.jpg'

function PaymentMethod({ handleToClose, openMethod, basic_amount }) {

    const [openUpi, setOpenUpi] = useState(false)
    const [openBank, setOpenBank] = useState(false)
    const [amount, setAmount] = useState(0)

    const handleUpiChange = (value) => {
        setAmount(value)
        setOpenUpi(true)
    }

    const handleBankChange = (value) => {
        setAmount(value)
        setOpenBank(true)
    }

    return (
        <Dialog open={openMethod} onClose={handleToClose} >
            <div >
                <DialogTitle className='flex justify-end items-end'><IoMdClose onClick={handleToClose} className='mt-2 ml-5 cursor-pointer' /></DialogTitle>
                <DialogContent className='flex flex-col justify-center items-center'>
                    {/* <img src={refImage} alt={title}/> */}
                    <div className='flex flex-col justify-center items-center'>
                        <h1 className='mt-2 font-bold'>Choose Payment method</h1>
                        <div className='flex flex-col gap-4 mt-2'>
                            <div className='flex flex-col border w-72 p-2 border-gray-500 rounded'>
                                <div className=''>
                                    <input
                                        type="radio"
                                        name="plan"
                                        value={basic_amount}
                                        className="form-radio h-4 w-4 text-blue-600 "
                                        onChange={(e) => handleUpiChange(e.target.value)}
                                    /><label className='text-xl ml-6'>UPI</label>
                                </div>
                                <div>
                                <img src={allPay} className='h-14 w-48' />
                                </div>
                            </div>
                            <div className='border w-72 p-4 border-gray-500 rounded'>
                                <input
                                    type="radio"
                                    name="plan"
                                    value={basic_amount}
                                    className="form-radio h-4 w-4 text-blue-600"
                                    onChange={(e) => handleBankChange(e.target.value)}
                                /><label className='text-xl ml-6'>Bank</label>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
            <UpiPaymentModal open={openUpi} handleToClose={() => setOpenUpi(false)} amount={amount} setAmount={setAmount} />
            <OpenBank open={openBank} handleToClose={() => setOpenBank(false)} amount={amount} setAmount={setAmount} />
        </Dialog>
    )
}

export default PaymentMethod