export const servicesOptions = [
    "IT Consulting",
    "Custom Application Development",
    "Resource Augmentation",
    "Sales",
    "Marketing",
    "Software Maintenance",
    "Cloud Services",
    "Cybersecurity",
    "Data Analytics",
    "Quality Assurance",
    "UI/UX Design",
    "DevOps",
    "Training"
    // { value: 'other', label: 'other' },
];

export const service_filter_Options = [
    { value: '', label: 'Relevance' },
    { value: 'Most recent', label: 'Most recent' },
    { value: 'Rating:hign to low', label: 'Rating:hign to low' },
    { value: 'Rating:low to high', label: 'Rating:low to high' }
  ];

export const service_provider_categories = [
    { value: '', label: 'Select Your Service Category' },
    { value: 'Advertising & Marketing', label: 'Advertising & Marketing' },
    { value: 'Development', label: 'FemaDevelopmentle' },
    { value: 'Design & Production', label: 'Design & Production' },
    { value: 'IT Services', label: 'IT Services' },
    { value: 'Buisness Services', label: 'Buisness Services' },
    { value: 'other', label: 'Other' },
  ];

 export const employeeRanges = [
    { label: "1-10", value: "RANGE_1_10" },
    { label: "10-50", value: "RANGE_10_50" },
    { label: "50-100", value: "RANGE_50_100" },
    { label: "100-500", value: "RANGE_100_500" },
    { label: "500-1000", value: "RANGE_500_1000" },
    { label: "1000-2000", value: "RANGE_1000_5000" },
    { label: "5000-10000", value: "RANGE_5000_10000" },
    { label: "10000-Plus", value: "10000+" },
  ];

 export const countryCodes = [
    { code: "+1", country: "United States", flag: "https://flagcdn.com/us.svg" },
    { code: "+91", country: "India", flag: "https://flagcdn.com/in.svg" },
    { code: "+44", country: "United Kingdom", flag: "https://flagcdn.com/gb.svg" },
    { code: "+81", country: "Japan", flag: "https://flagcdn.com/jp.svg" },
    { code: "+61", country: "Australia", flag: "https://flagcdn.com/au.svg" },
    { code: "+86", country: "China", flag: "https://flagcdn.com/cn.svg" },
    { code: "+49", country: "Germany", flag: "https://flagcdn.com/de.svg" },
    { code: "+33", country: "France", flag: "https://flagcdn.com/fr.svg" },
    { code: "+39", country: "Italy", flag: "https://flagcdn.com/it.svg" },
    { code: "+55", country: "Brazil", flag: "https://flagcdn.com/br.svg" },
  ];