import React from 'react'

function LocationInfo({ formik, countries, province, city }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col mt-2 gap-2 p-4'>
                <div>
                    <div className='relative w-full mb-4'>
                        <input
                            type='text'
                            id='addressLine1'
                            name='addressLine1'
                            onChange={formik.handleChange}
                            value={formik.values.addressLine1}
                            placeholder='address line1'
                            className='w-full p-2 rounded border border-gray-400 focus:outline-none'
                        />
                        <label
                            htmlFor='addressLine1'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Address Line 1 <span className='text-red-600 ml-1'>*</span>
                        </label>
                    </div>

                    <div className='relative w-full mb-4'>
                        <input
                            type='text'
                            id='addressLine2'
                            name='addressLine2'
                            onChange={formik.handleChange}
                            value={formik.values.addressLine2}
                            placeholder='address line2'
                            className='w-full p-2 rounded border border-gray-400 focus:outline-none'
                        />
                        <label
                            htmlFor='addressLine2'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Address Line 2 <span className='text-red-600 ml-1'>*</span>
                        </label>
                    </div>


                    <div className='relative w-full '>
                        <input
                            type='text'
                            id='pinCode'
                            name='pinCode'
                            className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.pinCode && formik.errors.pinCode ? 'border-red-600' : 'border-gray-400'}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pinCode}
                            placeholder='Enter Pin Code'
                        />
                        {
                            formik.touched.pinCode && formik.errors.pinCode ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.pinCode}
                                </div> : null
                        }
                        <label
                            htmlFor='pinCode'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Pin Code<span className='text-red-600 ml-1'>*</span>
                        </label>
                    </div>
                </div>

                <div className='flex gap-2'>
                    <div className='relative w-full mb-2'>
                        <select
                            id='country'
                            name='country'
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                            onChange={formik.handleChange}
                            value={formik.values.country}
                        >
                            {countries && countries.map((country) => (
                                <option key={country.countryId} value={country.countryId} >
                                    {country.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor='country' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Country<span className='text-red-600 ml-1'>*</span></label>
                    </div>

                    <div className='relative w-full'>
                        <select
                            id='province'
                            name='province'
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                            onChange={formik.handleChange}
                            value={formik.values.province}
                        >
                            {province && province.map((state) => (
                                <option key={state.provinceId} value={state.provinceId} >
                                    {state.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor='province' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>State<span className='text-red-600 ml-1'>*</span></label>
                    </div>

                    <div className='relative w-full'>
                        <select name='city'
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        >
                            <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
                            {city && city.map((city) => (
                                <option key={city.cityId} value={city.cityId} >
                                    {city.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor='city' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>City<span className='text-red-600 ml-1'>*</span></label>
                     </div>
                    </div>
                </div>
                <div className='flex justify-center items-center'>
                    <button type='submit' className='btn'>Submit</button>
                </div>
        </form>
    )
}

export default LocationInfo