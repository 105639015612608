import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";

function RefundModal({handleClose,open}) {
    const { isDark,fontSize } = useSelector((state) => state.displaySettingsState)
    return (
        <Dialog
            fullScreen 
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className='flex justify-between'>
                Refund Policy
                <IoIosClose size={40} className='cursor-pointer' onClick={() => handleClose()} />
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    <div >                      
                        <section className={`flex flex-col w-full justify-between p-8 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
                            <p className='' style={{ fontSize: `${fontSize + 6}px` }}>Thank you for your interest in our products. Please note that all sales are final, and we do not offer refunds or exchanges under any circumstances.</p>
                            <p className='' style={{ fontSize: `${fontSize + 6}px` }}>If you have any questions or require further assistance, please feel free to contact us at the following email address: <b>compliance@inkqubee.com</b></p>
                            <p className='' style={{ fontSize: `${fontSize + 6}px` }}>We appreciate your understanding and support!</p>
                        </section>
                    </div>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RefundModal