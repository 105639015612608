import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ImFilter } from "react-icons/im";
import { service_filter_Options } from '../../../utils/tempJson/_optionsJson';
import { getCompanyUsers } from '../../../helper/auth/authRequestCall';
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import UserDetail from '../../../utils/Modal/UserDetails';

function CompanyUsers({id}) {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const[users,setUsers]=useState ([])
  const[user,setUser]=useState(false)
  const fetchUsers=()=>{
    getCompanyUsers(id)
    .then((res)=>{
      if(res.status===200){
        setUsers(res.data.data)
      }
    })
    .catch((err)=>{console.log("err",err)})
  }

  useEffect(()=>{
    fetchUsers()
  },[])
  return (
    <div className={`flex flex-col h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3`}>
      <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-2 rounded space-y-4`}>
      <div className='grid grid-cols-4 gap-5 w-full max-w-7xl bg-white  h-96 px-6 py-2'>
              {users.map((user)=>(
                  <div className='flex flex-col p-2 bg-blue-600  rounded w-full h-24 ml-8 shadow-lg cursor-pointer' onClick={()=>setUser(true)}>
                  
                  {/* <div className='flex flex-col mt-1 ml-2 cursor-pointer'> */}
                    <span className='font-semibold text-white'>{user.username}</span>
                    <span className='flex text-white'>{user.firstName}{user.active ? <MdVerified color='green' className='mt-1 ml-2'/>:<GoUnverified color='yellow' className='mt-1 ml-2'/> }</span>
                  {/* </div> */}
                </div>
                
              ))}
             </div>
      </div>
      
    </div>
  )
}

export default CompanyUsers