import { message } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { org_url } from '../../../../utils/constant';
import { useParams } from 'react-router-dom';
import axios, { Axios } from 'axios';
import { uploadBuisnessProof } from '../../../../helper/organization/orgRequestCall';

function UploadSupportingFile() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [files, setFiles] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const { org_id } = useParams()

    // Handle file input change
    const handleFileChange = (event) => {
        setFiles(event.target.files[0]);
    };

    // Upload files function
    const handleUpload = async (event) => {
        event.preventDefault();

        if (!files) {
            setUploadStatus('Please select files to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', files) 
        await uploadBuisnessProof(formData,org_id)
        .then((res)=>{
            if(res.status === 200){
                console.log("file uploaded successfully")
            }else{
                console.error("upload error")
            }
        })
        .catch((err)=>console.error("upload failed",err))
    };

    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
            <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
                <h2 className="text-xl font-bold mb-4 text-center">Upload Supporting File</h2>
                <form className="flex flex-col space-y-4">
                    <input
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={handleFileChange}
                        className="file-input file-input-bordered w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                        type="button"
                        onClick={handleUpload}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Upload
                    </button>
                </form>
                {uploadStatus && (
                    <p
                        className={`mt-4 text-center ${uploadStatus.includes('successfully')
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                    >
                        {uploadStatus}
                    </p>
                )}
            </div>

        </div>
    )
}

export default UploadSupportingFile