import React from 'react'
import { servicesOptions } from '../../../../utils/tempJson/_optionsJson'
import Select from 'react-select'

function FirstStep({ formik, employees, handleFileChange, docType, setDocType, docTypes }) {
    const companyTypes = [
        "Private Limited",
        "Public Limited",
        "One Person Company",
        "Limited Liability Partnership",
        "Partnership",
        "Sole Proprietorship",
        "Section 8",
        "Government",
        "Other"
    ]
    const currentYear = new Date().getFullYear();

    const docsMaped = docTypes.map((types) => ({
        value: types,
        label: types
    }))
    const years = Array.from({ length: currentYear - 1899 }, (_, i) => currentYear - i);
    return (
        <div className='flex flex-col'>
            <div className='relative w-full mb-4'>
                <select
                    name='companyType'
                    id='companyType'
                    className='shadow border rounded w-full py-2 mb-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                    onChange={formik.handleChange}
                    value={formik.values.companyType}
                    onBlur={formik.handleBlur}
                >
                    <option value=''>Select Company Type</option>
                    {companyTypes.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                    ))}
                </select>
                {formik.touched.companyType && formik.errors.companyType ?
                    <div className='text-red-600 ml-1 mb-4'>
                        {formik.errors.companyType}
                    </div> : null
                }
                <label htmlFor='companyType' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Company Type<span className='text-red-600 ml-1'>*</span></label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='CIN'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.CIN && formik.errors.CIN ? 'border-red-600' : ' border-gray-400 '}`}
                    placeholder='Enter CIN'
                    onChange={formik.handleChange}
                    value={formik.values.CIN}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.CIN && formik.errors.CIN ?
                    <div className='text-red-600 ml-1'>
                        {formik.errors.CIN}
                    </div> : null
                }
                <label
                    htmlFor='CIN'
                    className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Company Identification Number <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative w-full mb-3'>
                <input
                    type='text'
                    id='legalEntityName'
                    className={`p-2 w-full border focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.legalEntityName && formik.errors.legalEntityName ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='Enter Legal Entity Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.legalEntityName}
                />
                {formik.touched.legalEntityName && formik.errors.legalEntityName ?
                    <div className='text-red-600 ml-1'>
                        {formik.errors.legalEntityName}
                    </div> : null
                }
                <label
                    htmlFor='legalEntityName'
                    className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Legal Entity Name <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative w-full mb-5'>
                <input
                    type='text'
                    id='PAN'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 mt-2 ${formik.touched.PAN && formik.errors.PAN ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='Enter PAN'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.PAN}
                />
                <label
                    htmlFor='PAN'
                    className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    PAN
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='gstNumber'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                    placeholder='Enter GST Number'
                    onChange={formik.handleChange}
                    value={formik.values.gstNumber}
                    onBlur={formik.handleBlur}
                />
                <label
                    htmlFor='gstNumber'
                    className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    GST Number
                </label>
            </div>

            <div className='relative w-full mb-3'>
                <select
                    name='established'
                    className='shadow border rounded w-full py-2 mb-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.established}
                >
                    <option value=''>Select Company Established Year</option>
                    {years.map((Year, index) => (
                        <option key={index} value={Year}>
                            {Year}
                        </option>
                    ))}
                </select>
                {
                    formik.touched.established && formik.errors.established ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.established}
                        </div> : null
                }
                <label htmlFor='established' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Company Established Year: <span className='text-red-600 ml-1'>*</span></label>
            </div>

            <div className="relative w-full mb-4">
                <label
                    htmlFor="phoneNo"
                    className="absolute top-[-10px] left-4 bg-white px-1 text-sm font-semibold text-black"
                >
                    Phone Number <span className="text-red-600">*</span>
                </label>
                <div className="flex items-center border rounded-lg overflow-hidden">

                    <select
                        id='countryCode'
                        value={formik.countryCode}
                        onChange={formik.handleChange}
                        name="countryCode"
                        className=" border h-10 border-gray-400 p-2 text-gray-700 outline-none focus:ring "
                    >
                        <option value="+1">+1 (USA)</option>
                        <option value="+91">+91 (India)</option>
                        <option value="+44">+44 (UK)</option>
                        <option value="+61">+61 (Australia)</option>
                    </select>

                    <input
                        type="text"
                        id="phoneNo"
                        className={`p-2 flex-grow focus:outline-none border border-gray-400 placeholder-slate-600  text-black ${formik.touched.phoneNo && formik.errors.phoneNo
                            ? "border-red-600"
                            : "border-gray-400"
                            }`}
                        placeholder="Enter Phone Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNo}
                    />
                </div>

                {/* Validation Error */}
                {formik.touched.phoneNo && formik.errors.phoneNo && (
                    <div className="text-red-600 text-sm mt-1">
                        {formik.errors.phoneNo}
                    </div>
                )}
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='email'
                    id='officialMailId'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.officialMailId && formik.errors.officialMailId ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='example.company@domain.com'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.officialMailId}
                />
                {formik.touched.officialMailId && formik.errors.officialMailId ?
                    <div className='text-red-600 ml-1'>
                        {formik.errors.officialMailId}
                    </div> : null
                }
                <label
                    htmlFor='officialMailId'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Official MailId<span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='email'
                    id='verifyMailId'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.verifyMailId && formik.errors.verifyMailId ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='example.company@domain.com'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.verifyMailId}
                />

                {
                    formik.touched.verifyMailId && formik.errors.verifyMailId ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.verifyMailId}
                        </div> : null
                }
                <label
                    htmlFor='verifyMailId'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Confirm Mail Id <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='companyBrandName'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.companyBrandName && formik.errors.companyBrandName ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='Enter brand name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyBrandName}
                />
                {
                    formik.touched.companyBrandName && formik.errors.companyBrandName ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.companyBrandName}
                        </div> : null
                }
                <label
                    htmlFor='companyBrandName'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Brand name <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative w-full mb-3'>
                <select
                    name='noOfEmployees'
                    className='shadow border rounded w-full py-2 mb-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.noOfEmployees}
                >
                    <option value=''>Select Number Of Employees</option>
                    {employees.map((empCount, index) => (
                        <option key={index} value={empCount}>
                            {empCount}
                        </option>
                    ))}
                </select>
                {
                    formik.touched.noOfEmployees && formik.errors.noOfEmployees ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.noOfEmployees}
                        </div> : null
                }
                <label htmlFor='noOfEmployees' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Number of employees: <span className='text-red-600 ml-1'>*</span></label>
            </div>

            <div className='relative w-full mb-2'>
                <select id='Industries' name='Industries'
                    onChange={formik.handleChange}
                    value={formik.values.Industries}
                    onBlur={formik.handleBlur}
                    className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                >
                    <option value="">Select Industries </option>
                    {servicesOptions.map((option, index) => (
                        <option key={index} value={option} >
                            {option}
                        </option>
                    ))}
                </select>

                {
                    formik.touched.Industries && formik.errors.Industries ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.Industries}
                        </div> : null
                }
                <label htmlFor='Industries' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Industries: <span className='text-red-600 ml-1'>*</span></label>
            </div>

            <div className='relative mt-2 w-full mb-3'>
                <input
                    type='text'
                    id='companyUrl'
                    name='companyUrl'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg mt-2 ${formik.touched.companyUrl && formik.errors.companyUrl ? 'border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyUrl}
                    placeholder='Company Url'
                />

                {
                    formik.touched.companyUrl && formik.errors.companyUrl ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.companyUrl}
                        </div> : null
                }
                <label
                    htmlFor='companyUrl'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Company URL  <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>

            <div className='relative mt-4 w-full mb-2'>
                <textarea
                    id='description'
                    name='description'
                    className='p-3 w-full  border border-gray-600 focus:outline-none placeholder-slate-600 text-black rounded-lg'
                    placeholder='Write Something About Company'
                    rows='5'
                    onChange={formik.handleChange}
                    value={formik.values.description}
                />
                <label
                    htmlFor='description'
                    className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                >
                    Business Description <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.description && formik.errors.description ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.description}
                        </div> : null
                }
            </div>
            <div className='flex flex-col border p-2'>
                <div className='flex justify-center items-center'>
                    <h1 className='font-semibold'>Upload Busines Proof</h1>
                </div>
                <div className='flex p-2'>
                    <div className='relative w-[600px] mt-4'>
                        <Select
                            id='typesOfInvestment'
                            className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
                            options={docsMaped}
                            value={docsMaped.filter((option) =>
                                docType.includes(option.value)
                            )}
                            // onChange={(selected) => {
                            //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                            // }}
                            onChange={(selectedOption) => {
                                setDocType(selectedOption ? selectedOption.value : "");
                            }}
                            placeholder="Choose The Types"
                        // styles={customStyles}
                        />
                        <label
                            htmlFor='docType'
                            className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Choose Document Name<span className='text-red-600 ml-1'>*</span>
                        </label>
                        {/* {formik.touched.typesOfInvestment && formik.errors.typesOfInvestment ?
                        <div className='text-red-600 ml-3'>
                            {formik.errors.typesOfInvestment}
                        </div> : null
                    } */}
                    </div>
                    <div className="relative w-full mt-4">
                        <label className="absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white">
                            Upload Document<span className='text-red-600 ml-1'>*</span>
                        </label>
                        <input
                            type="file"
                            multiple
                            accept=".pdf"
                            onChange={handleFileChange}
                            className="file-input file-input-bordered w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FirstStep