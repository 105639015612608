import React from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { organizationRegister } from '../../../../helper/organization/orgRequestCall'

function OrgDetailsConfirm() {
    const { orgInfo } = useSelector((state) => state.orgRegiterState)
    const { isDark } = useSelector((state) => state.displaySettingsState)

    const handleRegister=()=>{
       organizationRegister(
            orgInfo.name,
            orgInfo.company_brandName,
            orgInfo.CIN_number,
            orgInfo.PAN_number ,
            orgInfo.org_location,
            orgInfo.official_mail,
            orgInfo.org_logo,
            orgInfo.No_of_employees,
            orgInfo.registered_document,
            orgInfo.services_and_products
        )
        
        .then((res)=> {
            if(res.status===201){
                message.success("Registered successfully please wait until verification done")
                //console.log(data)
                // fileRef.current.value=null
                // docRef.current.value=null
                console.log("status",res.status)
                console.log("status")
            }else{
                message.error("Error to process you request")
            }
            console.log("status",res.status)
                console.log("status")
        })
        .catch(err=>{
            console.log(err)
        })
    }
  return (
    <div  className={`flex flex-col h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3`}>
      <div className={`flex flex-col justify-center items-center ${isDark ? 'bg-slate-400' : 'bg-slate-100'} h-full w-full p-2 rounded space-y-4`}>
        <div className='bg-white p-20 rounded  min-h-36 w-full h-full '>
          <div className='flex flex-col border border-gray-300 p-2'>
            <div className='flex border-b border-gray-200 p-2'>
              <label className='font-bold'>CIN:</label>
              <span className='ml-8 text-gray-600'>{orgInfo.CIN_number}</span>
            </div>

           <div className='flex border-b border-gray-200 p-2'>
             <label className='font-bold'>PAN:</label>
             <span className='ml-8 text-gray-600'>{orgInfo.PAN_number}</span>
            </div>
   
            <div className='flex border-b border-gray-200 p-2'>
            <label className='font-bold' >Company / Brandname:</label>
            <span className='ml-8 text-gray-600'>{orgInfo.company_brandName}</span>
            </div>
            
             <div className='flex border-b border-gray-200 p-2'>
            <label className='font-bold'>Legal Entty name:</label>
            <span className='ml-8 text-gray-600'>{orgInfo.name}</span>
            </div>
            
            <div className='flex border-b border-gray-200 p-2'>
            <label className='font-bold'>location:</label>
            <span className='ml-8 text-gray-600'>{orgInfo.org_location}</span>
            </div>
            
            <div className='flex border-b border-gray-200 p-2'>
            <label className='font-bold'>Employees:</label>
            <span className='ml-8 text-gray-600'>{orgInfo.No_of_employees}</span>
            </div>

            <div className='flex border-b border-gray-200 p-2'>
            <label className='font-bold'>Official MailId:</label>
            <span className='ml-8 text-gray-600'>{orgInfo.official_mail}</span>
            </div>
             
             <div className='flex border-b border-gray-200 p-2'>
            <label  className='font-bold'>Logo:</label>
            <img src={orgInfo.org_logo}
              alt='logo'
              className='ml-8'
            />
            </div>

            <div  className='flex border-b border-gray-200 p-2'>
            <label className='font-bold'>
              Official document:
            </label>
            <span className='ml-8 text-gray-600'>{orgInfo.registered_document}</span>
            </div>
           <div className='flex justify-between'>
            <div></div>
            <button className='btn' onSubmit={handleRegister}>submit</button>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default OrgDetailsConfirm