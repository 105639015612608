import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CiLogout } from "react-icons/ci";
import PropTypes from 'prop-types'
import { FaRegUser } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { MdOutlineDashboard } from "react-icons/md";
import { jwtDecode } from 'jwt-decode';
import { FaExpeditedssl } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { userLogout } from '../../helper/auth/authRequestCall';
import { message } from 'antd';

function ProfileDropdown({ profileDropdown, handleOpenProfile }) {
    const navigate = useNavigate()
    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const location = useLocation()
    const dashboardHiddn = ['/']

    const displayDashboard = dashboardHiddn.includes(location.pathname)
    const handleLogout = () => {
        // dispatch(authenticated())
        userLogout(decoded.username)
        .then((res)=>{
            if(res.status===200){
                window.location.href='/'
                localStorage.removeItem('authToken')
                localStorage.removeItem('refresh')
                message.success("logout success..")
            }else{
                message.error("Logout failed")
            }
        })
        .catch((err)=>console.error("error occurd",err))
    }
    return (
        <div>
            {profileDropdown && (
                <ul className="absolute right-3 mt-2 bg-white rounded-md shadow-lg p-2 z-10 w-48">
                    {displayDashboard && 
                    <li>
                        <Link
                            to='/dashboard'
                            // onClick={handleOpenProfile}
                            className="w-full flex items-center mt-2 text-black p-2 hover:bg-blue-700 hover:text-white"
                        >
                            <MdDashboard className="me-1" />
                            Dashboard
                        </Link>
                    </li>}
                    <li>
                        <Link
                            to={`/user-profile/${userId}`}
                            // onClick={handleOpenProfile}
                            className="w-full flex items-center mt-2 text-black p-2 hover:bg-blue-700 hover:text-white"
                        >
                            <CgProfile className="me-1" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <button
                            onClick={() => navigate('/user/reset-password')}
                            className="w-full flex items-center mt-2 text-black p-2 hover:bg-blue-700 hover:text-white"
                        >
                            <FaExpeditedssl className="me-1" />
                            Reset Password
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => handleLogout()}
                            className="w-full flex items-center mt-2 text-black p-2 hover:bg-blue-700 hover:text-white"
                        >
                            <CiLogout className="me-1" />
                            Logout
                        </button>
                    </li>
                </ul>
            )}
        </div>
    )
}

ProfileDropdown.propTypes = {
    profileDropdown: PropTypes.bool,
    items: PropTypes.array
}

export default ProfileDropdown