import React, { useEffect, useState } from 'react'
import { countries } from '../../../../utils/tempJson/_tempItems'
import { getCities, getCountries, getProvince } from '../../../../helper/organization/orgRequestCall'

function SecondStep({ formik, countries, province, cities }) {

        // useEffect(() => {
        //         fetchCounty()
        // },[])

        return (
                <div className='flex flex-col '>
                        <div className='relative w-full mb-3'>
                                <input
                                        type='text'
                                        id='addressLine1'
                                        name='addressLine1'
                                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine1}
                                        placeholder='Enter addressline 1'
                                />
                                {
                                        formik.touched.addressLine1 && formik.errors.addressLine1 ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.addressLine1}
                                                </div> : null
                                }
                                <label
                                        htmlFor='addressLine1'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Address Line 1 <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>

                        <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='addressLine2'
                                        name='addressLine2'
                                        className={`p-2 mt-2 w-full border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 border-gray-400 ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine2}
                                        placeholder='Enter addressline 2'
                                />
                                {
                                        formik.touched.addressLine2 && formik.errors.addressLine2 ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.addressLine2}
                                                </div> : null
                                }
                                <label
                                        htmlFor='addressLine2'
                                        className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Address Line 2 <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>
                        <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='pinCode'
                                        name='pinCode'
                                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.pinCode && formik.errors.pinCode ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pinCode}
                                        placeholder='Enter Pin Code'
                                />
                                {
                                        formik.touched.pinCode && formik.errors.pinCode ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.pinCode}
                                                </div> : null
                                }
                                <label
                                        htmlFor='pinCode'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Pin Code<span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>
                        {/* <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='landmark'
                                        name='landmark'
                                        className={`p-2 mt-2 w-full border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 border-gray-400 ${formik.touched.landmark && formik.errors.landmark ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.landmark}
                                        placeholder='Enter the landmark'
                                />
                                 {
                                        formik.touched.landmark && formik.errors.landmark ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.landmark}
                                                </div> : null
                                }
                                <label
                                        htmlFor='landmark'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Landmark <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div> */}
                        {/* <div className='flex'>
                        <label className='flex ml-3 mt-4 me-1 font-bold'>country <span className='text-red-600 ml-1'>*</span></label> */}
                        <div className='flex justify-between gap-2'>
                                <select name='country'
                                        onChange={formik.handleChange}
                                        value={formik.values.country}
                                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                >
                                        <option value=''>Country <span className='text-red-600 ml-1'>*</span></option>
                                        {countries.map((country) => (
                                                <option key={country.countryId} value={country.countryId} >
                                                        {country.name}
                                                </option>
                                        ))}
                                </select>


                                {
                                        formik.touched.country && formik.errors.country ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.country}
                                                </div> : null
                                }
                                {/* </div> */}

                                {/* <div className='flex'>
                        <label className='flex me-1 ml-3 mt-4 font-bold'>State: <span className='text-red-600 ml-1'>*</span></label> */}
                                <select name='province'
                                        onChange={formik.handleChange}
                                        value={formik.values.province}
                                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                >
                                        <option value=''>Select state <span className='text-red-600 ml-1'>*</span></option>
                                        {province.map((state) => (
                                                <option key={state.provinceId} value={state.provinceId} >
                                                        {state.name}
                                                </option>
                                        ))}
                                </select>
                                {
                                        formik.touched.province && formik.errors.province ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.province}
                                                </div> : null
                                }
                                {/* </div> */}
                                <select name='city'
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                >
                                        <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
                                        {cities.map((city) => (
                                                <option key={city.cityId} value={city.cityId} >
                                                        {city.name}
                                                </option>
                                        ))}
                                </select>
                                {
                                        formik.touched.city && formik.errors.city ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.city}
                                                </div> : null
                                }
                        </div>
                </div>
        )
}

export default SecondStep