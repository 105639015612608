import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userOrganization } from '../../../../helper/organization/orgRequestCall'
import { useAddUser } from '../../../../helper/organization/orgFormHook'
import { createUser } from '../../../../helper/auth/authRequestCall'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'

function AddUsers() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [companies, setCompanies] = useState([])

    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const navigate=useNavigate()

    const hanndleBack=()=>{
        navigate(-1)
    }
    const fetchCompany = () => {
        userOrganization(userId)
            .then((res) => {
                if (res.status === 200) {
                    setCompanies(res.data.data)
                }
            })
            .catch((err) => { console.error("Error", err) })
    }

    useEffect(() => {
        fetchCompany()
    }, [])

    const handleSubmit = (values) => {
        console.log("values", values)
        const{
            userName,
            firstName,
            lastName,
            isSuperAdmin,
            companyId
        }=values
        createUser(userName,firstName,lastName,companyId,isSuperAdmin)
        .then((res)=>{
            if(res.status === 200){
                message.success("User created")
                formik.resetForm()
            }else{
                message.error("User creation failed")
            }
        })
        .catch((err)=>{console.log("err",err)})
        // formik.setFieldValue("userName","")
        // formik.setFieldValue("firstName","")
        // formik.setFieldValue("lastName","")
        // formik.setFieldValue("isSuperAdmin",false)
        // formik.setFieldValue("companyId","")
        
    }

    const formik = useAddUser(handleSubmit)
    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
            <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
                <h1 className='text-center text-gray-600'>Create users to your company</h1>
                {/* <ServiceProviderStep /> */}
                <form
                    onSubmit={formik.handleSubmit}
                    className='flex flex-col space-y-3 px-5 py-8'
                >
                    <div className='relative w-full mb-4'>
                        <input
                            type='email'
                            id='userName'
                            placeholder='example:xyz@mail.com'
                            className='w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600  focus:border-blue-900  text-black rounded-lg '
                            value={formik.values.userName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                                WebkitBoxShadow: '0 0 0px 1000px white inset',
                                // WebkitTextFillColor: '#000'
                              }}
                        />
                        <label
                            htmlFor='userName'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all  bg-white'
                        >
                            User Email
                        </label>
                        {
                            formik.touched.userName && formik.errors.userName ?
                                <div className='text-red-700 ml-2 '>
                                    {formik.errors.userName}
                                </div> : null
                        }
                    </div>
                    <div className='relative w-full mb-4'>
                        <input
                            type='text'
                            id='firstName'
                            placeholder='First name'
                            className='w-full p-2 border border-gray-800 focus:outline-none focus:border-blue-900 placeholder-slate-600 text-black rounded-lg '
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                        />
                        <label
                            htmlFor='firstName'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all  bg-white'
                        >
                            First Name
                        </label>
                    </div>
                    <div className='relative w-full mb-4'>
                        <input
                            type='text'
                            id='lastName'
                            placeholder='Last name'
                            className='w-full p-2 border border-gray-800 focus:outline-none focus:border-blue-900 placeholder-slate-600 text-black rounded-lg '
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                        />
                        <label
                            htmlFor='name'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all  bg-white'
                        >
                            Last Name
                        </label>
                    </div>
                
                    <div className='flex flex-col'>
                        <label className='font-semibold mt-3 me-1'>Superadmin:</label>
                        <select name='isSuperAdmin'
                            value={formik.values.isSuperAdmin}
                            onChange={(e) => {
                                // Convert the string to a boolean before passing it to formik
                                formik.setFieldValue('isSuperAdmin', e.target.value === 'true');
                              }}
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div className='flex flex-col'>
                    <label className='font-semibold mt-3 me-1'>Company:</label>
                        <select name='companyId'
                            value={formik.values.companyId}
                            onChange={(e) => {
                                formik.setFieldValue('companyId', parseInt(e.target.value, 10));
                            }}
                            className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        >
                            <option value="">Choose company</option>
                            {companies.map((option) => (
                                <option key={option.id} value={option.id} >
                                    {option.legalEntityName}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <input
            type='file'
            className='max-w-md w-52 text-white'
          /> */}
                    <div className='flex justify-center items-center'>
                        <button className='btn max-w-md w-52' onClick={()=>hanndleBack()}>
                            Back
                        </button>
                        <input
                            type='submit'
                            className='btn max-w-md w-52 ml-2'
                            value='submit'
                        />
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddUsers