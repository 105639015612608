import React from 'react'
import { useSelector } from 'react-redux'
import { MdVerified } from "react-icons/md";

function YourVerification({verified}) {
  const { isDark } = useSelector((state) => state.displaySettingsState)

  return (
    <div className='h-80'>
        <div className={`flex ${isDark ? 'bg-black' : 'bg-white' } h-full justify-center items-center`}>
            {!verified ?<h1 className='text-3xl text-gray-500'>Your organization is under verification.please wait until its done...🙂</h1>:<h1 className='flex text-3xl text-gray-500'>Verified by inkqubee <MdVerified className='mt-1 text-green-500'/> </h1>}
        </div>
    </div>
  )
}

export default YourVerification