import React, { useEffect } from 'react'
import InkqubeAcadamy from './components/public/InkqubeAcadamy'
import AboutProduct from './components/AboutProduct';
import LastSection from './components/public/LastSection';
import Hero from './components/public/Hero';
import { useSelector } from 'react-redux';
import FirstSection from './components/public/home/FirstSection';
import SecondSection from './components/public/home/SecondSection';
import ThirdSection from './components/public/home/ThirdSection';
import FourthSection from './components/public/home/FourthSection';
import FifthSection from './components/public/home/FifthSection';
import SixthSection from './components/public/home/SixthSection';
import { useNavigate } from 'react-router-dom';

function Home() {
  const { isDark, fontSize} = useSelector((state) => state.displaySettingsState)


  return (
    <div className='min-h-screen justify-center flex flex-col'>
      <FirstSection isDark={isDark}/>
      <SecondSection isDark={isDark}/>
      <ThirdSection isDark={isDark}/>
      <FourthSection isDark={isDark}/>
      <FifthSection isDark={isDark}/>
      <SixthSection isDark={isDark}/>
    </div>

  )
}

export default Home
