import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FirstStep from '../../../../components/private/investment/registerSteps/FirstStep'
import SecondStep from '../../../../components/private/investment/registerSteps/SecondStep'
import ThirdStep from '../../../../components/private/investment/registerSteps/ThirdStep'
import { MdNavigateNext } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { message } from 'antd'
import LastSteps from '../../../../components/private/investment/registerSteps/LastSteps'
import { useInvestmentForm } from '../../../../helper/investors/investmentFormHook'
import { useNavigate } from 'react-router-dom'
import SuccessModal from '../../../../utils/Modal/SuccessModal'
import { getAgrigatedCity, getCities, getCountries, getProvince } from '../../../../helper/organization/orgRequestCall'
import { getIndustry, getTypes, investorRegister } from '../../../../helper/investors/investorRequestCall'

function InvestmentRegister() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [registerdSteps, setRegisterdSteps] = useState(0)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [minimum, setMinimum] = useState("")
    const [maximum, setMaximum] = useState("")
    const [fileName, setFileName] = useState(null);
    const [countries, setCountries] = useState([])
    const [province, setProvinces] = useState([])
    const [locations, setAllLocation] = useState([])
    const [cities, setCities] = useState([])
    const [industries,setIndustries]=useState([])
    const [types,setTypes]=useState([])
    const [stateError, setStateError] = useState({})
    const [touchedMaximim,setTouchMaximum]=useState({
        maximum:false
    })
    const [investments, setInvestments] = useState([
        { company: '', amount: '', document: null }
    ])
    const navigate = useNavigate()

    const formTitles = ["Personal Info", "Investment Requirements", "Investment preference"]

    const fetchIndustreies=()=>{
        getIndustry()
        .then((res)=>{
            if(res.status===200){
                setIndustries(res.data.data)
            }
        })
        .catch((err)=>{
            console.error("err",err)
        })
    }
    const fetchTypes=()=>{
        getTypes()
        .then((res)=>{
            if(res.status===200){
               setTypes(res.data.data)
            }
        })
        .catch((err)=>console.error("error",err))
    }
    const getLocation = () => {
        getAgrigatedCity()
          .then((res) => {
            if (res.status === 200) {
              setAllLocation(res.data.data)
            }
          })
          .catch((err) => {
            console.error("err", err)
          })
      }
    useEffect(()=>{
           fetchIndustreies()
           fetchTypes()
           getLocation()
    },[])




   

    const validateAmount = () => {
        const newErrors = {};
        const minimumNumber = parseInt(minimum)
        const maximumNumber = parseInt(maximum)
        if (maximumNumber < minimumNumber) {
            newErrors.maximumError = "Maximum should less than minimum"
        }
        setStateError(newErrors)
    }

    const handleMaximumBlur = (e) => {
        const { name, value } = e.target;
        setTouchMaximum((prev) => ({
            ...prev,
            [name]: value,
        }));
        validateAmount()
    }
    const handleSubmit = (values) => {
        const phoneNumber = `${values.countryCode}${values.phone}`;
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            location: {
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                postalCode:values.pinCode,
                country: {
                  id: values.country
                },
                state: {
                  id: values.state
                },
                city: {
                  id: values.city
                }
              },
            phoneNumber: phoneNumber,
            panNumber: values.panNumber,
            // panDocument: values.panDocument,
            email: values.email,
            aadharNumber: values.idProofNumber,
            // idProofDocument: values.idProofDocument,
            // incomeProof: values.incomeProof,
            profileDescription: values.profile,
            investmentSizeRange: {
                minInvestment: parseInt(minimum),
                maxInvestment: parseInt(maximum)
              },
            // priorInvestments: investments,
            investmentType: values.typesOfInvestment,
            industryPreferences: values.industryPreference,
            preferredLocation: values.locationPreference
        }
        // console.log(payload)
        investorRegister(payload)
        .then((res)=>{
            if(res.status===200){
               setOpenSuccess(true)
            }else{
                message.error("Registration failed")
            }
        })
        .catch((err)=>{
            console.error("error",err)
        })
    }

    const onFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            formik.setFieldValue('incomeProof', uploadedFile)
            setFileName(uploadedFile.name);
        }
    };

    const onIdProofChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            formik.setFieldValue('idProofDocument', uploadedFile)
        }
    };

    const onPANProofChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            formik.setFieldValue('idProofDocument', uploadedFile)
        }
    };


    const formik = useInvestmentForm(handleSubmit)

    useEffect(() => {
        const fetchCountry = () => {
          getCountries()
            .then((res) => {
              if (res.status === 200) {
                setCountries(res.data)
              }
              // console.log("res",res.data)
            })
            .catch((err) => console.error("Error", err))
        }
        fetchCountry()
      }, [])
    
      useEffect(() => {
        const fetchProvince = () => {
          if (formik.values.country) {
            getProvince(formik.values.country)
              .then((res) => {
                if (res.status === 200) {
                  setProvinces(res.data)
                }
              })
              .catch((error) => console.error("Error fetching provinces:", error));
          }
        }
        fetchProvince()
      }, [formik.values.country])
    
      useEffect(() => {
        const fetchCity = () => {
          if (formik.values.state) {
            getCities(formik.values.state)
              .then((res) => {
                if (res.status === 200) {
                  setCities(res.data)
                }
              })
              .catch((error) => console.error("Error fetching cities:", error));
          }
        }
        fetchCity()
      }, [formik.values.state])
    // console.log(formik)

    const handleChange = (index, event) => {
        const values = [...investments];
        values[index][event.target.name] = event.target.value;
        setInvestments(values);
    };


    const handleFileChange = (index, event) => {
        const values = [...investments];
        values[index].document = event.target.files[0];
        setInvestments(values);
    };
    const pageDisplay = () => {
        if (registerdSteps === 0) {
            return <FirstStep formik={formik} onFileChange={onFileChange} onIdProofChange={onIdProofChange} onPANProofChange={onPANProofChange} fileName={fileName} countries={countries} provinces={province} cities={cities}/>
        } else if (registerdSteps === 1) {
            return <SecondStep formik={formik} investments={investments} minimum={minimum} touchedMaximim={touchedMaximim} maximum={maximum} setMaximum={setMaximum} setMinimum={setMinimum} setInvestments={setInvestments} handleChange={handleChange} handleFileChange={handleFileChange} handleMaximumBlur={handleMaximumBlur} stateError={stateError} />
        } else {
            return <LastSteps formik={formik} industries={industries} types={types} locations={locations}/>
        }
    }

    const handleNextStep = () => {
        const errors = formik.errors;
        console.log(errors)
        // console.log(Object.keys(errors).length)
        if (registerdSteps === 0 && formik.values.firstName && !errors.panNumber && !errors.adharCardNumber && !errors.email && !errors.lastName && !errors.address && !errors.phone) {
            setRegisterdSteps((prev) => prev + 1);
        } else if (registerdSteps === 1 && !errors.investmentCapacity && !stateError.maximumError) {
            setRegisterdSteps((prev) => prev + 1);
        } else {
            message.error("Please fill required fields....")
        }
    }

    const handleRegister = () => {
        if (registerdSteps === formTitles.length - 1) {
            formik.handleSubmit()
        } else {
            handleNextStep()
        }
    }
    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`w-full max-w-4xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-slate-500 '} space-y-10 rounded-lg h-full mt-11`}>
                <div className="relative w-full bg-gray-200 rounded-full h-2.5 my-4 ">
                    <div
                        className="absolute top-0 left-0 bg-blue-700 h-2.5 rounded-full"
                        style={{ width: `${((registerdSteps + 1) / formTitles.length) * 100}%` }}
                    ></div>
                    <div className="absolute w-full flex justify-between text-sm text-gray-600 mt-3">
                        {formTitles.map((title, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <span
                                    className={`flex items-center ${index < registerdSteps
                                        ? 'text-blue-800 font-semibold '
                                        : index === registerdSteps
                                            ? 'text-blue-700 font-bold '
                                            : 'text-gray-600'
                                        }`}
                                >
                                    {index < registerdSteps && (
                                        <svg
                                            className="w-4 h-4 mr-1  font-bold rounded-full"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    )}
                                    {title}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className='flex flex-col space-y-8 px-5 py-8 border shadow-md '
                >
                    <div className='flex flex-col justify-between'>
                        {pageDisplay()}
                    </div>

                    <div className='flex justify-between '>
                        {registerdSteps == 0 && <button className='flex btn bottom-0 right-0 ml-56'
                            onClick={() => navigate(-1)}
                        ><IoIosArrowBack className='mt-0.5 ' /> Back</button>}
                        {!registerdSteps == 0 && <button className='flex btn bottom-0 right-0 ml-56'
                            onClick={() => {
                                setRegisterdSteps((curSteps) => curSteps - 1)
                            }}
                        ><IoIosArrowBack className='mt-0.5 ' /> Back</button>}
                        <button type='submit' className='btn ml-auto me-56'
                            onClick={handleRegister}
                        >
                            {registerdSteps === formTitles.length - 1 ? "Submit" : <div className='flex'>Next <MdNavigateNext className='mt-1' /></div>}
                        </button>
                        <SuccessModal open={openSuccess} onClose={() => setOpenSuccess(false)} message={"Thanks for registering with us..your profile id under verification..."} path={'/dashboard'} buttonName="Go to dashboard" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestmentRegister