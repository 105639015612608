import React, { useState } from 'react';
import { ArrowRight, Users, Briefcase, PieChart, Building2, CheckCircle, ArrowUpRight, Star, Quote } from 'lucide-react';

const Testimonials = () => {
  const [selectedFeature, setSelectedFeature] = useState('projects');
  const [activeTestimonial, setActiveTestimonial] = useState(0);

  const features = {
    projects: {
      title: "Project Marketplace",
      description: "Post requirements or find relevant projects. Connect directly with verified businesses and professionals.",
      stats: ["1000+ Active Projects", "24hr Average Response", "98% Success Rate"]
    },
    investment: {
      title: "Investment Hub",
      description: "Connect with investors or find promising ventures. Streamlined due diligence and secure communication.",
      stats: ["₹100Cr+ Deal Value", "500+ Investors", "200+ Funded Startups"]
    },
    services: {
      title: "Business Services",
      description: "Access comprehensive business services from M&A to talent acquisition. All verified service providers.",
      stats: ["50+ Service Categories", "1000+ Providers", "4.8/5 Avg Rating"]
    }
  };

  const testimonials = [
    {
      name: "Rajesh Kumar",
      position: "CEO, TechStart Solutions",
      company: "Leading Tech Services Provider",
      image: "/api/placeholder/64/64",
      text: "Within just 3 months of joining the platform, we secured two major projects and found an angel investor. The verification system gives us confidence in every connection we make.",
      rating: 5
    },
    {
      name: "Priya Sharma",
      position: "Founder",
      company: "Innovation Labs India",
      image: "/api/placeholder/64/64",
      text: "As an investor, the platform has streamlined our deal flow significantly. The quality of startups and the detailed verification process saves us valuable time in due diligence.",
      rating: 5
    },
    {
      name: "Amit Patel",
      position: "Managing Director",
      company: "Growth Ventures",
      image: "/api/placeholder/64/64",
      text: "The platform's M&A marketplace helped us find the perfect acquisition target. The structured process and professional support made the entire transaction smooth.",
      rating: 5
    }
  ];

  // Previous sections remain the same until Stats Section
  
  {/* Add new Brands Section after Stats Section */}
  const BrandsSection = () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 border-t border-gray-100">
      <h2 className="text-2xl font-bold text-center mb-8">Trusted by Leading Indian Businesses</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center">
        {[1, 2, 3, 4].map((index) => (
          <div key={index} className="flex justify-center items-center p-8 bg-gray-50 rounded-lg">
            <div className="h-12 w-full bg-gray-200 rounded flex items-center justify-center">
              <span className="text-gray-500 font-semibold">Brand {index}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center">
        <p className="text-gray-600">And 1000+ more verified businesses across India</p>
      </div>
    </div>
  );

  {/* Add new Testimonials Section before How It Works */}
  const TestimonialsSection = () => (
    <div className="bg-blue-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">Success Stories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className={`bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:-translate-y-1`}
            >
              <div className="flex items-start mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full mr-4"
                />
                <div>
                  <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                  <p className="text-gray-600">{testimonial.position}</p>
                  <p className="text-blue-600 text-sm">{testimonial.company}</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
                ))}
              </div>
              <Quote className="h-8 w-8 text-blue-200 mb-2" />
              <p className="text-gray-700">{testimonial.text}</p>
            </div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <button className="text-blue-600 font-semibold flex items-center mx-auto hover:text-blue-700">
            View More Success Stories <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );

  {/* Add Industry Recognition Section */}
  const RecognitionSection = () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h2 className="text-3xl font-bold text-center mb-12">Industry Recognition</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-gray-50 p-6 rounded-lg text-center">
          <img src="/api/placeholder/64/64" alt="Award 1" className="mx-auto mb-4" />
          <h3 className="font-semibold text-lg mb-2">Best B2B Platform 2024</h3>
          <p className="text-gray-600">TechAwards India</p>
        </div>
        <div className="bg-gray-50 p-6 rounded-lg text-center">
          <img src="/api/placeholder/64/64" alt="Award 2" className="mx-auto mb-4" />
          <h3 className="font-semibold text-lg mb-2">Innovation in Business</h3>
          <p className="text-gray-600">StartupIndia Summit</p>
        </div>
        <div className="bg-gray-50 p-6 rounded-lg text-center">
          <img src="/api/placeholder/64/64" alt="Award 3" className="mx-auto mb-4" />
          <h3 className="font-semibold text-lg mb-2">Excellence in Business Networking</h3>
          <p className="text-gray-600">Digital India Awards</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Previous sections remain the same */}
      
      {/* Insert new sections in the right order */}
      <BrandsSection />
      <TestimonialsSection />
      <RecognitionSection />
      
      {/* Rest of the previous sections remain the same */}
    </div>
  );
};

export default Testimonials;
