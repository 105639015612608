import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_SITE_KEY } from '../../../utils/constant';
import { useSelector } from 'react-redux';
import { Alert, message, Spin } from 'antd';
import { useRegisterForm } from '../../../helper/auth/authFormHook';
import { signupUser } from '../../../helper/auth/authRequestCall';
import { TiTick } from "react-icons/ti";
import TermsAndConditionModal from '../../../utils/Modal/TermsAndConditionModal';
import PrivacyPolicyModal from '../../../utils/Modal/PrivacyPolicyModal';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { getCities, getCountries, getProvince } from '../../../helper/organization/orgRequestCall';
// import TermsAndConditionModal from '../../../utils/Modal/TermsAndConditionModal';


function Register() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [registered, setRegistered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [OpenTerms, setOpenTerms] = useState(false)
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
  const [countries, setCountries] = useState([])
  const [province, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const reCaptcha = useRef(null)

  const handleSubmit = (values) => {
    setLoading(true)
    const firstName = values.firstName
    const lastName = values.lastName
    const email = values.email
    const password = values.password

    const payload={
      emailId: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: values.phoneNumber,
      address: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode:values.postalCode,
        country: {
          id: values.country
        },
        state: {
          id: values.state
        },
        city: {
          id: values.city
        }
      }
    }
    // const captcha = values.captcha

    signupUser(payload)
      .then((data) => {
        if (data && data.status === 201) {
          formik.resetForm()
          if (reCaptcha.current) {
            reCaptcha.current.reset()
          }
          setRegistered(true)
        } else {
          message.error("registration failed")
        }
      })
      .catch((err) => {
        console.error("error", err)
      })
      .finally(() => {
        setLoading(false)
        // setRegistered(false)
      })

  }

  const formik = useRegisterForm(handleSubmit)
  useEffect(() => {
    const fetchCountry = () => {
      getCountries()
        .then((res) => {
          if (res.status === 200) {
            setCountries(res.data)
          }
          // console.log("res",res.data)
        })
        .catch((err) => console.error("Error", err))
    }
    fetchCountry()
  }, [])

  useEffect(() => {
    const fetchProvince = () => {
      if (formik.values.country) {
        getProvince(formik.values.country)
          .then((res) => {
            if (res.status === 200) {
              setProvinces(res.data)
            }
          })
          .catch((error) => console.error("Error fetching provinces:", error));
      }
    }
    fetchProvince()
  }, [formik.values.country])

  useEffect(() => {
    const fetchCity = () => {
      if (formik.values.state) {
        getCities(formik.values.state)
          .then((res) => {
            if (res.status === 200) {
              setCities(res.data)
            }
          })
          .catch((error) => console.error("Error fetching cities:", error));
      }
    }
    fetchCity()
  }, [formik.values.state])

  useEffect(() => {
    let timeout;
    if (reCaptcha.current) {
      timeout = setTimeout(() => {
        reCaptcha.current.reset()
        // console.log("recaptcha is expired please complete it again")
      }, 60000)
    }
    return () => clearTimeout(timeout)
  }, [])

  



  const onCaptchaChange = (value) => {
    formik.setFieldValue('captcha', value)
  };


  return (
    <div className={`flex flex-col justify-center items-center min-h-screen ${!isDark ? 'bg-blue-950' : 'dark-mode'} p-8 py-7`}>
      {
        registered &&
        <Alert
          message="Registered successfully"
          description="Please check your mail and activate your account.."
          className='mt-20'
          type='success'
          closable
        />
      }
      <div className={`w-full justify-center max-w-lg p-8 space-y-6 ${!isDark ? 'bg-blue-100' : 'bg-slate-300 '} rounded mt-14`}>
        <h1 className='text-black text-2xl font-bold text-center mb-4'>Register</h1>
        <form className='flex flex-col px-5' onSubmit={formik.handleSubmit}>
          <div className='flex gap-2'>
            <div className='relative w-full mb-4'>
              <input
                type='text'
                placeholder='Enter your first name'
                id='firstName'
                className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg  ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.firstName && formik.errors.firstName ? 'border-2 border-red-600' : ' border-gray-400'}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}

              />
              <label htmlFor='firstName' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
                First Name <span className='text-red-600 ml-1'>*</span>
              </label>
              {
                formik.touched.firstName && formik.errors.firstName ?
                  <div className='text-red-600 ml-1'>
                    {formik.errors.firstName}
                  </div> : null
              }
            </div>
            <div className='relative w-full mb-4'>
              <input
                type='text'
                placeholder='Enter your last name'
                id='lastName'
                className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.lastName && formik.errors.lastName ? 'border-2 border-red-600' : ' border-gray-400'}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              <label htmlFor='LastName' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
                Last Name <span className='text-red-600 ml-1'>*</span>
              </label>
              {
                formik.touched.lastName && formik.errors.lastName ?
                  <div className='text-red-600 ml-1'>
                    {formik.errors.lastName}
                  </div> : null
              }
            </div>
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='email'
              id='email'
              placeholder='Enter your email'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.email && formik.errors.email ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <label htmlFor='Email' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Email <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.email && formik.errors.email ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.email}
                </div> : null
            }
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='text'
              id='phoneNumber'
              placeholder='Enter Your Phone Number'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            <label htmlFor='Email' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Phone Number <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.phoneNumber && formik.errors.phoneNumber ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.phoneNumber}
                </div> : null
            }
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='text'
              id='addressLine1'
              placeholder='Enter Address Line 1'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine1}
            />
            <label htmlFor='addressLine1' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Address Line 1 <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.addressLine1 && formik.errors.addressLine1 ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.addressLine1}
                </div> : null
            }
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='text'
              id='addressLine2'
              placeholder='Enter Address Line 2'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine2}
            />
            <label htmlFor='addressLine2' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Address Line 2 <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.addressLine2 && formik.errors.addressLine2 ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.addressLine2}
                </div> : null
            }
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='text'
              id='postalCode'
              placeholder='Enter Pincode'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.postalCode && formik.errors.postalCode ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
            />
            <label htmlFor='postalCode' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Pin Code <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.postalCode && formik.errors.postalCode ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.postalCode}
                </div> : null
            }
          </div>

          <div className='flex justify-between gap-2 mb-4'>
            <select name='country'
              onChange={formik.handleChange}
              value={formik.values.country}
              className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
            >
              <option value=''>Country <span className='text-red-600 ml-1'>*</span></option>
              {countries.map((country) => (
                <option key={country.countryId} value={country.countryId} >
                  {country.name}
                </option>
              ))}
            </select>


            {
              formik.touched.country && formik.errors.country ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.country}
                </div> : null
            }
            {/* </div> */}

            {/* <div className='flex'>
                        <label className='flex me-1 ml-3 mt-4 font-bold'>State: <span className='text-red-600 ml-1'>*</span></label> */}
            <select name='state'
              onChange={formik.handleChange}
              value={formik.values.state}
              className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
            >
              <option value=''>Select state <span className='text-red-600 ml-1'>*</span></option>
              {province.map((state) => (
                <option key={state.provinceId} value={state.provinceId} >
                  {state.name}
                </option>
              ))}
            </select>
            {
              formik.touched.province && formik.errors.province ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.province}
                </div> : null
            }

            {/* </div> */}
            <select name='city'
              onChange={formik.handleChange}
              value={formik.values.city}
              className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
            >
              <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
              {cities.map((city) => (
                <option key={city.cityId} value={city.cityId} >
                  {city.name}
                </option>
              ))}
            </select>
            {
              formik.touched.city && formik.errors.city ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.city}
                </div> : null
            }
          </div>
          <div className='relative w-full mb-4'>
            <input
              type='password'
              id='password'
              placeholder='Enter your password'
              className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-blue-100' : 'bg-slate-300'} ${formik.touched.password && formik.errors.password ? 'border-2 border-red-600' : ' border-gray-400'}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}

              style={{
                WebkitTextSecurity: "disc", // Disables the native eye icon
                MozAppearance: "textfield", // For Firefox fallback
              }}
            />
            <label htmlFor='Password' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-blue-100' : 'bg-slate-300'}`}>
              Password <span className='text-red-600 ml-1'>*</span>
            </label>
            {
              formik.touched.password && formik.errors.password ?
                <div className='text-red-600 ml-1'>
                  {formik.errors.password}
                </div> : null
            }
          </div>
          <div className='justify-center mt-2 h-16 transform scale-90 origin-top-left'>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={reCaptcha}
              onChange={(value) => {
                onCaptchaChange(value)
              }}
            />

          </div>
          {
            formik.touched.captcha && formik.errors.captcha ?
              <div className='text-red-600 ml-1 mt-1'>
                {formik.errors.captcha}
              </div> : null
          }
          <div className='mt-2 '>
            <label className='flex font-semibold cursor-pointer'>
              <input
                type='checkbox'
                name='terms'
                className='hidden peer'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.terms}
              />
              <div className={`w-4 h-4 border-2 ${formik.touched.terms && formik.errors.terms ? 'border-red-600' : 'border-gray-700'}  mt-1 bg-white peer-checked:border-blue-700`}>
                {formik.values.terms && <TiTick className='h-3 w-3' color='black' />}
              </div>
              <span className='ml-2 text-black'>I agree to the <button type='button' className='text-blue-950 underline' onClick={() => setOpenTerms(true)}>Terms and Conditions</button> and <button type='button' className='text-blue-950 underline' onClick={() => setOpenPrivacyPolicy(true)}>Privacy Policy</button>.</span>
            </label>
            {
              formik.touched.terms && formik.errors.terms ?
                <div className='text-red-600 ml-1 mt-1'>
                  {formik.errors.terms}
                </div> : null
            }
          </div>

          <p className='px-2 mt-2 text-black'>Want to Sign In?<Link to='/login' className='text-black font-bold underline'> Sign In</Link></p>
          {
            !loading ?
              <input
                type='submit'
                value="Sign Up"
                className='btn mt-4'
              />
              :
              <button className='btn bg-black text-white mt-4'>
                <Spin className='custom-spinner' />
              </button>
          }
        </form>
      </div>
      <TermsAndConditionModal open={OpenTerms} handleClose={() => setOpenTerms(false)} />
      <PrivacyPolicyModal open={openPrivacyPolicy} handleClose={() => setOpenPrivacyPolicy(false)} />
    </div>
  )
}

export default Register
