import React from 'react'
import { useSelector } from 'react-redux'

function SearchResult() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
        <div>
            <h1>Search Result</h1>
        </div>
    </div>
  )
}

export default SearchResult