import { useFormik } from "formik"
import { portfolioCreateSchema, portfolioUpdateSchema } from "../../validation"


export const usePortfolioCreate=(handleSubmit)=>{
    return useFormik({
        initialValues:{
            name:"",
            description:"",
            service:""
        },
        validationSchema:portfolioCreateSchema,
        onSubmit:values=>{
            handleSubmit(values)
        }
    })
}

export const useUpdatePortfolio=(handleSubmit)=>{
    return useFormik({
        initialValues:{
            name:"",
            companyId:"",
            description:"",
            service:""
        },
        validationSchema:portfolioUpdateSchema,
        onSubmit:values=>{
            handleSubmit(values)
        }
    })
}