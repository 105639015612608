import React from 'react'

function FilterList({ selectedItems, setSelectedItems }) {
    return (
        <div className='flex gap-4'>
            {
                selectedItems && selectedItems.map((items, index) => (
                   <ul key={index} >
                    <li className='border-2 border-blue-600 p-1 rounded-md'>{items}</li>
                   </ul>
                ))
            }
        </div>
    )
}

export default FilterList