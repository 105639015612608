import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types'
import { DialogContentText } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import qrImage from '../../assets/PaymentQr.jpg'

function UpiPaymentModal({ amount, handleToClose, open, setAmount }) {
    const [couponCode, setCouponCode] = useState("")
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('');

    const coupons = {
        SAVE10: 10,
        SAVE20: 20,
        SAVE50: 50,
        SAVE100:100
    };

    const handleApplyCoupon = () => {
        if (coupons[coupon]) {
            const discountValue = (amount * coupons[coupon]) / 100;
            setDiscount(discountValue);
            setMessage(`Coupon applied! You saved ₹${discountValue}.`);
            setMessageColor('green')
        } else {
            setMessageColor('red')
            setMessage('Invalid coupon code!');
            setDiscount(0);
        }
    };
    return (
        <Dialog open={open} onClose={handleToClose} >
            <div className='p-2'>
                <DialogTitle className='flex justify-end items-end '><IoMdClose onClick={handleToClose} className='mt-2 ml-5 cursor-pointer' /></DialogTitle>
                <DialogContent className='flex flex-row justify-center'>
                    {/* <img src={refImage} alt={title}/> */}
                    <div>
                        <div className='flex flex-col mt-4 bg-white rounded-lg shadow-md p-3'>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold min-w-[150px]">Actual Amount:</label>
                                <span className="font-thin text-lg"><span className='me-1'>&#8377;</span>{amount}</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold min-w-[150px]">Discounted Amount:</label>
                                <span className="font-thin text-lg"><span className='me-1'>&#8377;</span>{discount}</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold min-w-[150px]">Total Amount:</label>
                                <span className="flex justify-between font-thin text-lg"><span className='me-1'>&#8377;</span>{amount-discount}</span>
                            </div>
                        </div>
                        <div className='flex p-2 mt-3'>
                            <input
                                type='text'
                                placeholder='Enter coupon code'
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                className='p-2  border border-gray-500 focus:outline-blue-400 '
                            />
                            <button className='bg-blue-600 p-2 text-white' onClick={handleApplyCoupon}>Apply</button>
                        </div>
                        {message && messageColor && <p className="mt-2  text-center " style={{ color: messageColor }}>{message}</p>}
                    </div>
                    <div className='ml-5 '>
                        <img
                            src={qrImage}
                            alt='payment qr'
                            className='h-80 w-full ml-6'
                        />
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    )
}

export default UpiPaymentModal