import { useFormik } from "formik"
import { courceContactSchema } from "../../validation"


export const useCourceContactForm=(handleSubmit)=>{
    return useFormik({
        initialValues:{
            name:"",
            mobileNumber:"",
            email:"",
            companyName:"",
            companyHeadqarters:"",
            requirments:""
        },
        validationSchema:courceContactSchema,
        onSubmit:values=>{
            // console.log(values)
            handleSubmit(values)
        }
    })
}
