import React from 'react'

function ThirdSection({ fontSize, isDark }) {
    return (
        <section className={`h-96 flex flex-col p-5 md:p-8 ${!isDark ? 'bg-white' : 'dark-mode'}`} id='inkqube'>
            <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                {[
                    {
                        step: "1",
                        title: "Register",
                        description: "Create your business profile"
                    },
                    {
                        step: "2",
                        title: "Verify",
                        description: "Complete business verification"
                    },
                    {
                        step: "3",
                        title: "Connect",
                        description: "Start exploring opportunities"
                    },
                    {
                        step: "4",
                        title: "Grow",
                        description: "Scale your business"
                    }
                ].map((item, index) => (
                    <div key={index} className="text-center">
                        <div className="bg-blue-100 text-blue-900 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                            {item.step}
                        </div>
                        <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                        <p className="text-gray-600">{item.description}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default ThirdSection