import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CiLocationOn } from "react-icons/ci";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { CiFlag1 } from "react-icons/ci";
import RatingStar from '../../../utils/RatingStar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SiWebmoney } from "react-icons/si";
import { ImProfile } from "react-icons/im";
import { service_providers } from '../../../utils/tempJson/_tempItems';
import FilterList from '../../../components/private/FilterList';
import UnderConstruction from '../../../assets/workstation.avif'
import { getAgrigatedCity, getAllCompanies, getCompanyLogo } from '../../../helper/organization/orgRequestCall';
import { CgOrganisation } from "react-icons/cg";
import { getServices } from '../../../helper/serviceProvider/serviceProviderRequestCall';
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import { MdVerified } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import { Tooltip } from 'antd';
import { FaFilter } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";



function ServiceProviderList({ selectedItems }) {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const locationState = useLocation();
  const navigate = useNavigate()
  const [services, setServices] = useState([])
  const [companies, setCompanies] = useState([])
  const [allLocation, setAllLocation] = useState({})
  const [companyName, setName] = useState("")
  const [service, setService] = useState("")
  const [serviceSelect, setServiceSelect] = useState(false)
  const [isVerified, setVerification] = useState(false)
  const [location, setLocation] = useState(locationState.state?.location || "")
  const [queryMade, setQueryMade] = useState(false);
  const [locationSelect, setLocationSelect] = useState(false)
  const [logoMap, setLogoMap] = useState({});



  const handleSelectService = (option) => {
    setService(option)
    setServiceSelect(false)
  }

  const handleSelectLocation = (option) => {
    setLocation(option)
    setLocationSelect(false)
  }

  const getAllServices = () => {
    getServices()
      .then((res) => {
        setServices(res.data.data)
      })
      .catch((err) => { console.error("Error", err) })
  }

  const getLocation = () => {
    getAgrigatedCity()
      .then((res) => {
        if (res.status === 200) {
          setAllLocation(res.data.data)
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
  }

  useEffect(() => {
    getAllServices()
    getLocation()

  }, [])
  const buildQuery = () => {
    const params = new URLSearchParams()
    if (companyName) {
      params.append('companyName', companyName)
    }
    if (location) {
      params.append('cityCode', location);
    }
    if (service) {
      console.log("service,", service)
      params.append('service', service)
    }
    return params.toString()
  }

  const fetchCompanies = () => {
    const queryString = buildQuery()
    setQueryMade(true)
    getAllCompanies(queryString)
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data.data)
        } 
      })
      .catch((err) => {
        console.error("error:", err)
        setCompanies([])
      })
  }

  const fetchAllCompanies = () => {
    setQueryMade(true);
    getAllCompanies("")
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data.data);
        }
      })
      .catch((err) => {
        console.error("error:", err);
        setCompanies([]);
      });
  };

  const fetchLogo = async (companyId) => {
    await getCompanyLogo(companyId)
      .then((res) => {
        if (res.status === 200) {
          const blob = res.data
          const imageUrl = URL.createObjectURL(blob)
          setLogoMap((prev) => ({ ...prev, [companyId]: imageUrl }));
        }
      })
      .catch((err) => {
        console.error("error:", err)
      })
  }

  useEffect(() => {
    if (companyName || location || service) {
      fetchCompanies()
    } else {
      fetchAllCompanies()
    }
  }, [companyName, location, service])

  useEffect(() => {
    companies.forEach((company) => {
      if (!logoMap[company.companyId]) {
        fetchLogo(company.companyId);
      }
    });
  }, [companies]);

  const handleNavigate = (companyId) => {
    // Pass the current search values in the state
    navigate(`/service-provider/detail/${companyId}`, {
      state: { companyName, service, isVerified, location },
    });
  }
  return (
    <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10 px-3`}>
      <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4`}>

        <div className='flex flex-col md:flex-row justify-between border p-3 shadow-md'>
          <h1 className='flex text-2xl font-bold mt-5 ml-2 text-gray-500'><FaFilter className='mt-0.5 me-2' />Filter</h1>
          <div className='flex p-2'>
            <div className="relative flex flex-col ">
              <input
                type='text'
                placeholder='Filter by name'
                className="p-2 focus:outline-none border border-gray-400 rounded mt-3 ml-2 w-64"
                value={companyName}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor='name' className='flex absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>
                Name
              </label>
            </div>

            <div className="relative flex flex-col mt-2">
              <input
                type="text"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value)
                  setLocationSelect(true)
                }}
                onFocus={() => setLocationSelect(true)}
                className="p-2 focus:outline-none border border-gray-400 rounded mt-1 ml-2 w-64"
                placeholder="Filter by location"
              />
              <label htmlFor='location' className='flex absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'>
                Location<span className='text-red-600 ml-1 '>*</span>
              </label>
              <ul
                className="max-h-40 overflow-y-auto rounded-lg shadow-lg z-10 bg-white absolute w-full mt-12"
              >
                {locationSelect && location && (
                  Object.entries(allLocation).filter(([key, value]) =>
                    value.toLowerCase().includes(location.toLowerCase())
                  )
                    .map(([key, value]) => (
                      <li
                        key={key}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelectLocation(key)}
                      >
                        {value}
                      </li>
                    ))
                )}
              </ul>
            </div>

            <div className="relative flex flex-col mt-2">

              <select name='service'
                value={service}
                onChange={(e) => setService(e.target.value)}
                // placeholder="Filter Service"
                className='shadow border rounded w-56 py-2 px-3 ml-2 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
              >
                <option value="" disabled>Filter service</option>
                {services?.map((option, index) => (
                  <option key={index} value={option} >
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>
        <div>
          {/* <span className='ml-2 text-gray-600'>{companies.length} Results</span> */}
        </div>
        {
          !queryMade ? (
            <h1 >Search Service providers</h1>
          ) :
            companies.length > 0 ? (
              companies?.map((company) => (
                <div className='h-52 w-full bg-white rounded-lg mt-4 border border-gray-400 shadow' key={company.id}>
                  <div className='p-3' >
                    <div className='flex justify-between'>
                      <div className='flex'>

                        <img
                          src={logoMap[company.companyId] || defaultLogo}
                          alt='logo'
                          className='h-12 w-12 rounded-full me-2'
                        />
                        <div className='flex justify-between'>
                          <Link to={`/service-provider/detail/${company.companyId}`} className='mt-2 text-xl font-bold cursor-pointer hover:underline'>{company.companyBrandName} </Link>
                        </div>
                        <div>
                              <Tooltip placement='top' title='verified'>
                                <MdVerified color='green' size={30} className='mt-2 ml-5' />
                              </Tooltip> 
                        </div>
                      </div>
                      <div className='flex mt-1 ml-5'>
                        <a href={company.companyUrl} target='_blank' className='flex bg-blue-600 p-2 text-white rounded border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 me-4  hover:shadow-lg'>Visit Website <SiWebmoney className='mt-1 ml-1' /></a>
                        <Link state={{ companyName, service, isVerified, location }} className='flex border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 p-2 me-4  rounded-lg text-black hover:shadow-lg' to={`/service-provider/detail/${company.companyId}`} >View Profile <ImProfile className='mt-1 ml-1' /></Link>
                      </div>
                    </div>
                    <div className='flex gap-12 px-4 mt-2 py-3'>
                      <div className='mt-2'>
                        {/* <li className='flex text-gray-500'><CiLocationOn className=' me-1' /> {company.location.state.name},{company.location.country.name}</li> */}
                        <li className='flex text-gray-500'><FaUsersBetweenLines className='mt-1 me-1' /> {company.noOfEmployees}</li>
                      </div>

                      <div className='p-2'>
                        <h1 className='text-blue-500'>Service</h1>
                        <ul>
                          <li>{company.services}</li>
                        </ul>
                      </div>

                      <div className='p-2'>
                        <h1 className='text-blue-500'>Founded</h1>
                        <ul>
                          <li>{company.establishmentYear}</li>
                        </ul>
                      </div>

                      <div className='p-2'>
                        <h1 className='text-blue-500'>About</h1>
                        <p>{company.businessDescription.length > 60
                          ? (
                            <>
                              {company.businessDescription.substring(0, 60)}....
                              <button  className='text-blue-500'>
                                View more
                              </button>
                            </>
                          )
                          : company.businessDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))) : (
              <div>
              </div>
            )}
      </div>
    </div>
  )
}

export default ServiceProviderList