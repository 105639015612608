import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import Header from '../../../components/public/Header'
import Footer from '../../../components/public/Footer'
import { RxDotFilled } from "react-icons/rx";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";


function TermsAndService({ fontSize,auth }) {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const navigate=useNavigate()
  return (
    <Fragment>
      <Header auth={auth}/>
      <div className={`hide-scrollbar flex flex-col min-h-screen ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
        <main className="flex-grow">
          <div className="pt-16">
            <div className={`w-full p-4 ${!isDark ? 'bg-blue-900 text-white' : 'bg-slate-500 text-white'}`}>
              <h1 className="flex text-2xl mt-2"><IoMdArrowRoundBack className='me-2 cursor-pointer' onClick={()=>navigate(-1)}/> Terms of Use Policy</h1>
            </div>
            <section className={`flex flex-col w-full justify-start p-8 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
            <div lang="en-IN" link="#0563c1" vlink="#954f72" dir="ltr">
                <p className="line-height: 100%; margin-bottom: 0in"><br />

                </p>
                <p className="line-height: 100%; margin-bottom: 0in"><a name="_GoBack"></a>
                  <b>Last updated:</b> 08 November 2024</p>
                <p className="line-height: 100%; margin-bottom: 0in"><br />

                </p>
               <h1 className='text-3xl border-b border-gray-200'>1.Introduction</h1>
               <p className='mt-2'>These Terms of Use ("Terms") constitute a legally binding agreement between PROPELX BYTENET PRIVATE LIMITED ("Company", "we", "us", or "our"), operating the website www.inkqubee.com ("Website" or "Platform"), and you ("User", "you", or "your"). By accessing or using our Website or services, you agree to be bound by these Terms and Conditions.</p>
               <p className='mt-5'>Our website has been designed to connect busniess/clients, vendors/service providers, buyers/investors and sellers of small and medium-sized businesses. Inkqubee serves as an identification, and sourcing tool for professional service providers ("Vendors") and clients seeking quality services ("Clients"). The use of this website allows you to contact individuals or companies for the service listed, and the communication should be restricted to only advertised content and should not contact for any other purpose.</p>
               <p className='mt-5'>Profiles on Inkqubee are for informational purposes only. While we strive to provide accurate information, errors may occur due to user-generated content or other factors. Users are encouraged to verify all information directly with Vendors before purchasing services.</p>
               <p className='mt-5'>We reserves the right, at its sole discretion, to deny access to any individual to the services we offer at any time and for any reason. This includes, but is not limited to, instances of violation of these Terms.</p>
               <p className='mt-5'>If you are dissatisfied with our Services or anything related to them, your sole remedy is to close your account and stop using the Services.</p>
               <h1 className='text-3xl border-b border-gray-200 mt-5'>2.Definitions</h1>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li >"Platform" refers to the website www.inkqubee.com, owned by PROPELX BYTENET PRIVATE LIMITED</li>
                <li >"Services" includes all features, functionalities, and services provided through the Platform both offline and online</li>
                <li >"User Content" refers to any information, data, text, or other materials posted by users</li>
                <li >"Business Connection Services" refers to services facilitating connections between businesses, investors, and service providers</li>
               </ul>
               <h1 className='text-3xl border-b border-gray-200 mt-5'>3.Eligibility</h1>
               <p className='mt-2'>3.1 You must be at least 18 years old to use our Services.</p>
               <p className='mt-2'>3.2. If you are acting on behalf of a business entity, you represent that you have the authority to bind such entity to these Terms.</p>


               <h1 className='text-3xl border-b border-gray-200 mt-5'>4. Account Registration and Security</h1>
               <p className='mt-2'>4.1.You agree to:</p>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li>Provide accurate, current, and complete information during registration</li>
                <li>Maintain and update your information to keep it accurate</li>
                <li>Keep your account credentials confidential</li>
                <li>Be responsible for all activities under your account</li>
                <li>Notify us immediately of any unauthorized access</li>
               </ul>
               <p className='mt-2'>4.2. We reserve the right to suspend or terminate accounts that provide false information</p>
               <p className='mt-2'>4.3. Profile Integrity Standards:</p>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Inkqubee does not allow the listing of multiple businesses within a single profile. Each business profile must provide information specific to that entity to ensure effective connections with the appropriate parties.</li>
                  <li>Once a profile is activated, users may update their profiles with new information; however, any material changes that alter the profile to represent a different business are not permitted.</li>
               </ul>

               <h1 className='text-3xl border-b border-gray-200 mt-5'>5. Platform Services</h1>
               <p className='mt-2'>5.1. Our Platform provides:</p>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li>Business connection opportunities</li>
                <li>Project posting capabilities</li>
                <li>Investment opportunities</li>
                <li>Business service listings</li>
                <li>Networking features</li>
               </ul>
               <p className='mt-2'>5.2. Disclaimer of Guarantees:</p>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li>We do not guarantee any business outcomes</li>
                <li>We do not guarantee the success of any projects or investments or leads</li>
                <li>We do not guarantee the accuracy of user-provided information</li>
                <li>Profile advertisement details and other materials posted on our sites are not intended to amount to any form of investment advice from Inkqubee. Inkqubee is not a broker or the agent of any user, and Inkqubee does not partake in any kind of negotiations or discussions between the users.</li>
                <li>Inkqubee does not endorse any specific Vendor and does not act as a representative or broker for any Vendors featured on the platform. Fees earned by Inkqubee are solely for maintaining and providing the Site and Services.</li>
               </ul>
               <p className='mt-2'>5.3. Regulatory Disclaimer: Inkqubee is not regulated by any governing body, and you are strictly prohibited from offering any form of public securities, shares, bonds, or similar financial instruments through this website.</p>
               <p className='mt-2'>5.4. Inkqubee reserves the right to determine the index order in which listings are displayed on the website.</p>
               <p className='mt-2'>5.5. Information Sharing and Privacy:</p>
               <ul  className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li>Information submitted to us in any form is subject to our <Link to={'/privacy-policy'} className='text-blue-600 underline'>Privacy Policy.</Link></li>
                <li>Users may share personal information as defined in the Privacy Policy, along with details about their needs. All users consent to share this information with featured service providers, investors, buyers and our affiliate network.</li>
                <li>By submitting information to Inkqubee, users agree to be contacted by Inkqubee, featured service providers, investors, buyers, and affiliates via phone, email, mail, or other reasonable means using the contact details provided in their project request form.</li>
               </ul>

               <h1 className='text-3xl border-b border-gray-200 mt-5'>6. User Obligations</h1>
               <p className='mt-2'>6.1. You agree to:</p>
               <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                <li>Conduct your own due diligence</li>
                <li>Verify all information independently</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Not misuse the Platform or Services</li>
                <li>Not engage in fraudulent activities</li>
                <li>Assume full responsibility and risk of loss resulting from the use of this website and its content</li>
               </ul>
                  <p className='mt-2'>6.2. Prohibited Activities:</p>
                  <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                    <li>Posting false or misleading information</li>
                    <li>Harassing other users</li>
                    <li>Violating intellectual property rights</li>
                    <li>Using automated systems or any other means to access, acquire, copy or monitor the Platform or any content, materials, documents or information.</li>
                    <li>Creating multiple accounts</li>
                    <li>Attempting to circumvent security measures</li>
                  </ul>

                <h1  className='text-3xl border-b border-gray-200 mt-5'>7. Content Ownership and Intellectual Property</h1>
                <p className='mt-2'>7.1. User Content:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>You retain ownership of your content</li>
                  <li>You grant us a worldwide, royalty-free license to use your content</li>
                  <li>You are responsible for your content's accuracy and legality</li>
                </ul>
                <p className='mt-2'>7.2. Non-Binding Nature of Advertisements and Profiles:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Advertisements/profiles on the website do not constitute a binding offer. Acceptance by the user does not create any obligation for the party advertising on the website.</li>
                </ul>
                <p className='mt-2'>7.3. User Conduct and Inkqubee's Rights</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Inkqubee reserves the right to:</li>
                  <ul className='list-[square] list-outside pl-5 space-y-2 ml-4'>
                    <li>Edit, delete, or suspend any advertised profile or user.</li>
                    <li>Take action if we believe the platform is being misused or used for unintended purposes.</li>
                    <li>Act if false or misleading information is provided to users or on profiles.</li>
                    <li>Respond to any offensive behavior directed towards Inkqubee or other users.</li>
                  </ul>
                  <li>Actions may be taken with or without prior notice.</li>
                  <li>In such circumstances:</li>
                  <ul className='list-[square] list-outside pl-5 space-y-2 ml-4'>
                    <li>Purchases made on Inkqubee will not be refundable.</li>
                    <li>You agree that Inkqubee shall not be liable to you or any third party for any modification, suspension, or discontinuation of services.</li>
                  </ul>
                </ul>
                <p className='mt-2'>7.4. Company's Intellectual Property:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                   <li>All Platform content is our property</li>
                   <li>You may not copy, modify, or distribute our content without permission</li>
                   <li>Our trademarks and logos are protected</li>
                   <li>If you are representing a company, we may use your company name and logo on our website, promotional materials, or other write-ups unless you request otherwise.</li>
                   <li>The programs, publications, designs, products, processes, software, technologies, information, know-how, and ideas presented on this website may be protected by various intellectual property rights owned by Inkqubee or other third parties. These rights are not granted to you through this website.</li>
                   <li>Any unauthorized transfer, sale, distribution, reproduction, copying, or modification of any materials found on this website—whether in whole or in part—is strictly prohibited.</li>
                </ul>

                <h1  className='text-3xl border-b border-gray-200 mt-5'>8. Privacy and Data Protection</h1>
                <p className='mt-2'>8.1. We collect and process data in accordance with our Privacy Policy and applicable Indian laws.</p>
                <p className='mt-2'>8.2. We comply with:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Information Technology Act, 2000</li>
                  <li>Information Technology Rules, 2011</li>
                </ul>

                <h1  className='text-3xl border-b border-gray-200 mt-5'>9. Limitation of Liability</h1>
                <p className='mt-2'>9.1. We shall not be liable for:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Any direct, indirect, incidental, consequential or any other damages whatsoever, whether in an action of contract, statute, tort (including, without limitation, negligence) or otherwise, relating to the use of this website.</li>
                  <li>Loss of profits or business opportunities</li>
                  <li>Damages arising from user interactions</li>
                  <li>Technical issues or service interruptions</li>
                  <li>Third-party actions or content</li>
                  <li>The deletion or failure to store any content maintained or transmitted by the website or the service.</li>
                  <li>Any infringement of intellectual property rights arising from materials posted on or transmitted through the site, or from items advertised on the site by end-users or any third parties.</li>
                </ul>
                <p className='mt-2'>9.2. Liability Disclaimer</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Inkqubee does not participate in any engagements between Vendors and Clients. Any engagement is conducted at your own risk.</li>
                  <li>Inkqubee has no formal relationship with any private contracts and assumes no liabilities, indemnifications, warranties, or other obligations.</li>
                  <li>By using the Service, both Vendors and Clients acknowledge that Inkqubee bears no responsibility for any issues arising from these engagements.</li>
                </ul>
                <p className='mt-4'>9.3. Maximum Liability: Our total liability shall be limited to the amount paid by you for our services in the preceding 12 months.</p>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>10. Disclaimer</h1>
                <p className='mt-2'>10.1. General Disclaimer:</p>
                <p className='mt-2'>To the fullest extent permitted by applicable law, we provide the Services and all information contained therein on an “as is” and “as available” basis, with all faults. Your use of the Services is at your own risk.</p>
                <p className='mt-3'>10.2. Third-Party Claims:</p>
                <p className='mt-2'>We make no claims regarding any third parties, including companies reviewed through our Services. We do not endorse any company, vendor, service, or product featured in the Services and do not advise users to select any particular company based on its reviews or ratings. Reviews are based on the opinions of reviewers and should not be interpreted as factual statements. We do not provide any express warranties regarding the quality, accuracy, or reliability of user-generated content. Your decision to purchase or use products or services offered by third parties reviewed in our Services is made at your own discretion and risk.</p>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>11. Indemnification</h1>
                <p className='mt-2'>You agree to indemnify and hold Inkqubee harmless from any and all claims, demands, actions, and proceedings, including but not limited to:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>All direct and indirect damages, losses, costs, and expenses incurred by Inkqubee.</li>
                  <li>Legal fees and professional advisory fees.</li>
                  <li>Economic losses, loss of profits, future revenue, reputation, goodwill, and anticipated savings.</li>
                  <li>Any consequential losses arising from claims related to:</li>
                  <ul className='list-[square] list-outside pl-5 space-y-2 ml-4'>
                    <li>The content of any material uploaded or posted on this website.</li>
                    <li>Infringement of copyright, trademark, or other intellectual property rights of any third party.</li>
                  </ul>
                  <li>Damages (actual and consequential) of every kind and nature, known and unknown, arising from your use of the Platform or in any way connected with such disputes.</li>
                  <li>Violations of these Terms</li>
                  <li>Your content or submissions</li>
                  <li>Your interactions with other users</li>
                  <li>Any breach of applicable laws</li>
                  <li>Any loss of property / damages / loss of life etc</li>
                </ul>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>12. Dispute Resolution</h1>
                <p className='mt-3'>You agree that any disputes and/or differences if any shall be governed by the Laws of India and the exclusive venue for all actions related to or arising out of this engagement shall be the Courts of Bengaluru, India.</p>
                
                <h1 className='text-3xl border-b border-gray-200 mt-5'>13. Termination</h1>
                <p className='mt-3'>13.1. We may terminate or suspend your account without notice for reasons, but not limited to :</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>For Terms violations</li>
                  <li>For illegal activities</li>
                  <li>For providing false information</li>
                </ul>
                <p className='mt-3'>13.2. Effect of Termination:</p>
                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                  <li>Immediate cessation of access</li>
                  <li>No refund of any fees</li>
                  <li>Survival of relevant Terms</li>
                </ul>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>14. Modifications</h1>
                <p className='mt-3'>14.1. We reserve the right to modify these Terms at any time.</p>
                <p className='mt-2'>14.2. Changes may be made without prior notice to users and will be effective upon posting on the Platform.</p>
                <p className='mt-2'>14.3. Continued use constitutes acceptance of modifications.</p>
                <p className='mt-2'>14.4. Users are encouraged to return to this page regularly to review the most current version of the Terms.</p>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>15. Contact Information</h1>
                <p className='mt-3'>For any queries regarding these Terms: Email: <span className='text-blue-800'>compliance@inkqubee.com</span> </p>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>16. Severability</h1>
                <p className='mt-3'>If any provision of these Terms is found invalid or unenforceable, the remaining provisions shall remain in effect.</p>

                <h1 className='text-3xl border-b border-gray-200 mt-5'>17. Entire Agreement</h1>
                <p className='mt-3'>These Terms constitute the entire agreement between you and us regarding the Platform use.</p>
                <p className='mt-3'>By using our Platform, you hereby, unconditionally and irrevocably confirm that you have read, understood, and agree to be bound by these Terms.</p>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </div>

    </Fragment>
  )
}

export default TermsAndService
