import { ArrowRight } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function FifthSection({isDark}) {
    const navigate=useNavigate()
    return (
        <div className={`bg-blue-900 text-white py-16 ${isDark ? 'dark-mode':'light-mode'}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                <h2 className="text-3xl font-bold mb-6 text-white">Ready to Grow Your Business?</h2>
                <p className="text-xl text-blue-100 mb-8">Join thousands of successful businesses on our platform</p>
                <button onClick={()=>navigate('/register')} className="bg-white text-blue-900 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition flex items-center mx-auto">
                    Register Now <ArrowRight className="ml-2 h-5 w-5" />
                </button>
            </div>
        </div>
    )
}

export default FifthSection