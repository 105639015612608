import { jwtDecode } from 'jwt-decode'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCompanyLogo, userOrganization } from '../../../helper/organization/orgRequestCall'
import { Tooltip } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { HiEye } from 'react-icons/hi2'
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import { service_providers } from '../../../utils/tempJson/_tempItems'

function VerifiedCompanies({ companyLogos, logoMap ,handleServiceProvider,handleProject,handleSellCompany,openProfile}) {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    return (
        <Fragment>
            <div className={`flex flex-col h-full company-scrollable-container hide-scrollbar overflow-auto  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 shadow-lg px-3 space-y-2`}>
                <div className={`w-full mt-1 max-w-5xl space-y-6 rounded-lg px-6 p-2`} >
                    <div className="flex flex-wrap gap-2 p-2">
                        {companyLogos && companyLogos.map((item) => (
                            <div className='flex w-full border p-2'>
                                <div className='flex flex-col gap-2 p-2' key={item.companyId}>

                                    <img
                                        src={logoMap[item.companyId] || defaultLogo}
                                        // src={item.logo}
                                        alt={item.legalEntityName}
                                        // onClick={handleCompanyDetail}
                                        onClick={()=>openProfile(item.companyId)}
                                        className={` rounded-full h-14 w-14 bg-white cursor-pointer`}
                                    />
                                   
                                    <h1 className='text-center text-gray-500'>{item.legalEntityName}</h1>
                                </div>
                                <div className='flex flex-col justify-center items-center  ml-52'>
                                    <button onClick={() => handleServiceProvider(item.companyId,item.isVerified)} className='bg-blue-600 p-2 w-48 text-white rounded-full text-sm hover:bg-white border hover:border-blue-600 hover:text-black'>List as a Service Provider</button>
                                    <button onClick={() => handleProject(item.companyId,item.isVerified)} className='bg-blue-600 p-2 w-48 text-center text-sm  mt-2 text-white rounded-full hover:bg-white border hover:border-blue-600 hover:text-black'>Post Project</button>
                                    <button onClick={() => handleSellCompany(item.isVerified)} className='bg-blue-600 p-2 w-48 text-center text-sm mt-2 text-white rounded-full hover:bg-white border hover:border-blue-600 hover:text-black'>Sell Your Company</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VerifiedCompanies