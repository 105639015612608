import React, { useState } from 'react'
import { ArrowRight, Users, Briefcase, PieChart, Building2, CheckCircle, ArrowUpRight } from 'lucide-react';

function SecondSection({fontSize,isDark}) {
    const [selectedFeature, setSelectedFeature] = useState('projects');
    const features = {
        projects: {
          title: "Project Marketplace",
          description: "Post requirements or find relevant projects. Connect directly with verified businesses and professionals.",
          stats: ["1000+ Active Projects", "24hr Average Response", "98% Success Rate"]
        },
        investment: {
          title: "Investment Hub",
          description: "Connect with investors or find promising ventures. Streamlined due diligence and secure communication.",
          stats: ["₹100Cr+ Deal Value", "500+ Investors", "200+ Funded Startups"]
        },
        services: {
          title: "Business Services",
          description: "Access comprehensive business services from M&A to talent acquisition. All verified service providers.",
          stats: ["50+ Service Categories", "1000+ Providers", "4.8/5 Avg Rating"]
        }
      };
  return (
    <section className={`min-h-screen flex flex-col p-5 md:p-8 ${!isDark?'bg-gradient-to-r bg-gray-100':'dark-mode bg-black'}`} id='inkqube'>
       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Platform Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <button 
              onClick={() => setSelectedFeature('projects')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'projects' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <Briefcase className="h-8 w-8 mb-4" />
              <h3 className={`text-xl font-semibold mb-2 ${selectedFeature === 'projects' ? 'text-blue-100' : 'text-black'}`}>Project Marketplace</h3>
              <p className={selectedFeature === 'projects' ? 'text-blue-100' : 'text-gray-600'}>
                Find opportunities or post requirements
              </p>
            </button>
            <button 
              onClick={() => setSelectedFeature('investment')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'investment' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <PieChart className="h-8 w-8 mb-4" />
              <h3 className={`text-xl font-semibold mb-2 ${selectedFeature === 'investment' ? 'text-blue-100' : 'text-black'}`}>Investment Hub</h3>
              <p className={selectedFeature === 'investment' ? 'text-blue-100' : 'text-gray-600'}>
                Connect with investors or find ventures
              </p>
            </button>
            <button 
              onClick={() => setSelectedFeature('services')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'services' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <Building2 className="h-8 w-8 mb-4" />
              <h3 className={`text-xl font-semibold mb-2 ${selectedFeature === 'services' ? 'text-blue-100' : 'text-black'}`}>Business Services</h3>
              <p className={selectedFeature === 'services' ? 'text-blue-100' : 'text-gray-600'}>
                Access comprehensive business solutions
              </p>
            </button>
          </div>
          <div className="bg-white p-8 rounded-lg">
            <h3 className="text-2xl font-bold mb-4">{features[selectedFeature].title}</h3>
            <p className="text-gray-600 mb-6">{features[selectedFeature].description}</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {features[selectedFeature].stats.map((stat, index) => (
                <div key={index} className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  <span>{stat}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
    </section>
  )
}

export default SecondSection