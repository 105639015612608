import React from 'react';
import qrImage from '../../../assets/PaymentQr.jpg'


const PaymentPage = () => {
  return (
    <div className="payment-page flex flex-col min-h-screen overflow-auto justify-center items-center w-full bg-blue-300 py-2">



      <div className='heading mt-28'>
        <h3>PAYMENT DETAILS</h3>
      </div>

      <div className='flex '>
        <div className="payment-details">
          <div className="payment-details-content">

            <h2 className="india-heading">India</h2>


            <table className="payment-table">
              <tbody>
                <tr>
                  <td><strong>Account Name</strong></td>
                  <td><strong>PROPELX BYTENET PVT LTD</strong></td>
                </tr>

                <tr>
                  <td><strong>Account Number</strong></td>
                  <td><strong>7848084643</strong></td>
                </tr>
                <tr>
                  <td><strong>Bank Name</strong></td>
                  <td><strong>Kotak Mahindra Bank Limited</strong></td>
                </tr>


                <tr>
                  <td><strong>Bank IFSC Code</strong></td>
                  <td><strong>KKBK0008042</strong></td>
                </tr>
                <tr>
                  <td><strong>Branch Adderss</strong></td>
                  <td><strong>Bangalore_Whitefield</strong></td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div className='ml-5 mt-2'>
          <img
            src={qrImage}
            alt='payment qr'
            className='h-96 w-full'
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
