import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types'
import { DialogContentText } from '@mui/material';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

function PortfolioDetail({openDetail,handleToClose,description,service,name}) {
    return (
        <Dialog open={openDetail} onClose={handleToClose}>
            <DialogTitle className='flex justify-between'>{name}<IoMdClose onClick={handleToClose} className='mt-2 ml-5 cursor-pointer'/></DialogTitle>
            <DialogContent>
                {/* <img src={refImage} alt={title}/> */}
                <DialogContentText className='font-bold text-black'>
                  <b>Service:</b>  {service}
                </DialogContentText>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

PortfolioDetail.propTypes={
    openDetail:PropTypes.bool,
    handleToClose:PropTypes.func,
    title:PropTypes.string,
    description:PropTypes.string
}

export default PortfolioDetail