import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd';
import { useOrgForm } from '../../../../helper/organization/orgFormHook';
import { addUserToOrg, getCities, getCountries, getDocTypes, getEmployeeRange, getProvince, organizationRegister, uploadBuisnessProof } from '../../../../helper/organization/orgRequestCall';
import { MdNavigateNext } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { jwtDecode } from 'jwt-decode';
import SuccessModal from '../../../../utils/Modal/SuccessModal';
import { useNavigate } from 'react-router-dom';
import { FirstStep, LastStep, SecondStep, ThirdSteps } from '../../../../components/private/organization/registerStepComp';
import { Business } from '@mui/icons-material';


function RegisterDetail({ fontSize }) {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const step0Data = JSON.parse(localStorage.getItem("formStep0")) || {};
    const step1Data = JSON.parse(localStorage.getItem("formStep1")) || {};
    const step2Data = JSON.parse(localStorage.getItem("formStep2")) || {};
    const billingDetails = JSON.parse(localStorage.getItem("billingAddress")) || {};
    const [registerdSteps, setRegisterdSteps] = useState(0)
    const [countries, setCountries] = useState([])
    const [province, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [countriesBilling, setCountriesBilling] = useState([])
    const [provinceBilling, setProvincesBilling] = useState([])
    const [citiesBilling, setCitiesBilling] = useState([])
    const [employees, setEmployees] = useState([])
    const [openSuccess, setOpenSuccess] = useState(false)
    const [billingName, setBillingName] = useState(step1Data.legalEntityName)
    const [billingAddress, setAddresss] = useState(step1Data.addressLine1)
    const [billingSecondAddress, setSecondAddresss] = useState(step1Data.addressLine2)
    const [billingPincode, setPincode] = useState(step1Data.pinCode)
    const [billingCountry, setBillingCountry] = useState(step1Data.country)
    const [billingProvince, setBillingProvince] = useState(step1Data.province)
    const [billingCity, setBillingCity] = useState(step1Data.city)
    const [billingGst, setGst] = useState(step0Data.gstNumber)
    const [billingPhoneNumber, setPhoneNumber] = useState(step0Data.phoneNo)
    const [billingEmail, setEmail] = useState(step0Data.officialMailId)
    const [billingName2, setBillingName2] = useState(step1Data.legalEntityName)
    const [billingAddress2, setAddresss2] = useState(step1Data.addressLine1)
    const [billingSecondAddress2, setSecondAddresss2] = useState(step1Data.addressLine2)
    const [billingPincode2, setPincode2] = useState(step1Data.pinCode)
    const [billingCountry2, setBillingCountry2] = useState(step1Data.country)
    const [billingProvince2, setBillingProvince2] = useState(step1Data.province)
    const [billingCity2, setBillingCity2] = useState(step1Data.city)
    const [billingGst2, setGst2] = useState(step0Data.gstNumber)
    const [phoneNumber2, setPhoneNumber2] = useState(step0Data.phoneNo)
    const [email2, setEmail2] = useState(step0Data.officialMailId)
    const [openMethod, setOpenMethod] = useState(false)
    const [openPremiumMethod, setOpenPremiumMethod] = useState(false)
    const [files, setFiles] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [plan, selectedPlan] = useState("")
    const [docTypes,setDocTypes]=useState([])
    const [docType,setDocType]=useState("")
    const navigate = useNavigate()
    // const[companyId,setCompanyId]=useState(null)

    console.log("plan", plan)


    const formTitles = ["Company Information", "Location", "Social Media Links", "Plans and Payment"]

    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId

    const handleFileChange = (event) => {
        setFiles(event.target.files[0]);
    };

    const employeeRange = () => {
        getEmployeeRange()
            .then((res) => {
                if (res.status === 200) {
                    setEmployees(res.data.data)
                }
            })
    }

    const getAllDocTypes=()=>{
        getDocTypes()
        .then((res)=>{
            if(res.status===200){
                setDocTypes(res.data.data)
            }
        })
        .catch((err)=>console.error("Error",err))
    }

    useEffect(() => {
        employeeRange()
        getAllDocTypes()
    }, [])

    useEffect(() => {
        const fetchCountry = () => {
            getCountries()
                .then((res) => {
                    if (res.status === 200) {
                        setCountries(res.data)
                    }
                    // console.log("res",res.data)
                })
                .catch((err) => console.error("Error", err))
        }
        fetchCountry()
    }, [])

    useEffect(() => {
        const fetchCountryBilling = () => {
            getCountries()
                .then((res) => {
                    if (res.status === 200) {
                        setCountriesBilling(res.data)
                    }
                    // console.log("res",res.data)
                })
                .catch((err) => console.error("Error", err))
        }
        fetchCountryBilling()
    }, [])

    const handleSubmitBill = (e) => {
        e.preventDefault()
        const selectedCountry = countriesBilling.find(c => c.countryId == billingCountry);
        const selectedProvince = provinceBilling.find(p => p.provinceId == billingProvince);
        const selectedCity = citiesBilling.find(c => c.cityId == billingCity);
        const countryId = parseInt(billingCountry)
        const provinceId = parseInt(billingProvince)
        const cityId = parseInt(billingCity)
        // const details = {
        //     billingName: billingName,
        //     address1: billingAddress,
        //     address2: billingSecondAddress,
        //     pinCode: billingPincode,
        //     billingGst: billingGst,
        //     billingPhone: billingPhoneNumber,
        //     billingEmail: billingEmail
        // }

        const details = {
            addressLine1: billingAddress,
            addressLine2: billingSecondAddress,
            postalCode: billingPincode,
            country: { id:countryId},
            state: { id:provinceId},
            city: { id:cityId }
        }

        localStorage.setItem('billingAddress', JSON.stringify(details));
        message.success("Billing address submited")
        setOpenMethod(true)
    }

    const handleSubmitPremiumBill = (e) => {
        e.preventDefault()
        const countryId = parseInt(billingCountry2)
        const provinceId = parseInt(billingProvince2)
        const cityId = parseInt(billingCity2)
        // const details = {
        //     billingName: billingName2,
        //     address1: billingAddress2,
        //     address2: billingSecondAddress2,
        //     pinCode: billingPincode2,
        //     billingGst: billingGst2,
        //     billingPhone: phoneNumber2,
        //     billingEmail: email2
        // }
        const details = {
            addressLine1: billingAddress2,
            addressLine1: billingSecondAddress2,
            postalCode: billingPincode2,
            country: { id:countryId},
            state: { id:provinceId},
            city: { id:cityId }
        }
        localStorage.setItem('billingAddress', JSON.stringify(details));
        message.success("Billing address submited")
        setOpenMethod(true)
    }

    const handleSubmit =async (values) => {
        try {
        // Step 1: Prepare the location and payload
        const selectedCountry = countries.find(c => c.countryId == values.country);
        const selectedProvince = province.find(p => p.provinceId == values.province);
        const selectedCity = cities.find(c => c.cityId == values.city);
        const phoneNumber = `${values.countryCode}${values.phoneNo}`;
        const countryId = parseInt(values.country)
        const provinceId = parseInt(values.province)
        const cityId = parseInt(values.country)
        const location = {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            postalCode: values.pinCode,
            country: { id:countryId },
            state: { id:provinceId },
            city: { id:cityId }
        };

        const payLoad = {
            companyType: values.companyType,
            cin: values.CIN,
            pan: values.PAN,
            gstin: values.gstNumber,
            legalEntityName: values.legalEntityName,
            officialMailId: values.officialMailId,
            companyBrandName: values.companyBrandName,
            businessDescription: values.description,
            phoneNumber: phoneNumber,
            communicationAddress: location,
            billingAddress: billingDetails,
            noOfEmployees: values.noOfEmployees,
            companyUrl: values.companyUrl,
            establishmentYear: values.established,
            userId: userId,
            services: values.Industries,
            linkedInUrl: values.LinkedIn,
            facebookUrl: values.faceBook,
            instagramUrl: values.instagram,
            youtubeUrl:values.youtube,
            selectedPlanId:0,
            twitterUrl: values.xMedia,
            paymentReferenceId: values.paymentRefrence
        };

        // Clear local storage
        console.log("payload", payLoad);
        console.log("buisness proof file", files)

        // Call organizationRegister API
            const orgRes = await organizationRegister(payLoad);
            if (orgRes.status !== 200) {
                throw new Error('Failed to register organization');
            }

            const companyId = orgRes.data.data.companyId;

            // Add user to organization
            const userRes = await addUserToOrg(companyId, userId);
            if (userRes.status !== 200) {
                throw new Error('Failed to add user to organization');
            }

            setOpenSuccess(true);
            localStorage.removeItem("formStep0");
            localStorage.removeItem("formStep1");
            localStorage.removeItem("formStep2");
            localStorage.removeItem("billingAddress");

            // Check if files are selected before uploading
            if (!files) {
                setUploadStatus('Please select files to upload.');
                return;
            }

        //     //Upload business proof
            const formData = new FormData();
            formData.append('file', files);
            const uploadRes = await uploadBuisnessProof(formData, companyId,docType);
            if (uploadRes.status !== 200) {
                setUploadStatus("File Upload failed. Please upload again.");
                console.error("File upload error");
            } else {
                console.log("File uploaded successfully");
            }
        } catch (error) {
            // error handling
            console.error("Error:", error.message);
            message.error(`An error occurred: ${error.message}`);
        }
    }



    const formik = useOrgForm(handleSubmit)

    useEffect(() => {
        const fetchProvince = () => {
            if (formik.values.country) {
                getProvince(formik.values.country)
                    .then((res) => {
                        if (res.status === 200) {
                            setProvinces(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching provinces:", error));
            }
        }
        fetchProvince()
    }, [formik.values.country])

    useEffect(() => {
        const fetchProvinceBilling = () => {
            if (billingCountry) {
                getProvince(billingCountry)
                    .then((res) => {
                        if (res.status === 200) {
                            setProvincesBilling(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching provinces:", error));
            }
        }
        fetchProvinceBilling()
    }, [billingCountry])

    useEffect(() => {
        const fetchCity = () => {
            if (formik.values.province) {
                getCities(formik.values.province)
                    .then((res) => {
                        if (res.status === 200) {
                            setCities(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching cities:", error));
            }
        }
        fetchCity()
    }, [formik.values.province])

    useEffect(() => {
        const fetchCityBilling = () => {
            if (billingProvince) {
                getCities(billingProvince)
                    .then((res) => {
                        if (res.status === 200) {
                            setCitiesBilling(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching cities:", error));
            }
        }
        fetchCityBilling()
    }, [billingProvince])


    const pageDisplay = () => {
        if (registerdSteps === 0) {
            return <FirstStep formik={formik} employees={employees} handleFileChange={handleFileChange} docType={docType} setDocType={setDocType} docTypes={docTypes}/>
        } else if (registerdSteps === 1) {
            return <SecondStep
                formik={formik}
                countries={countries}
                province={province}
                cities={cities}
            />
        } else if (registerdSteps === 2) {
            return <ThirdSteps
                formik={formik}
            />
        } else {
            return <LastStep
                formik={formik}
                billingName={billingName}
                setBillingName={setBillingName}
                billingAddress={billingAddress}
                setAddresss={setAddresss}
                billingGst={billingGst}
                setGst={setGst}
                countries={countriesBilling}
                province={provinceBilling}
                cities={citiesBilling}
                billingPhoneNumber={billingPhoneNumber}
                setPhoneNumber={setPhoneNumber}
                billingSecondAddress={billingSecondAddress}
                setSecondAddresss={setSecondAddresss}
                billingPincode={billingPincode}
                setPincode={setPincode}
                billingCountry={billingCountry}
                billingProvince={billingProvince}
                billingCity={billingCity}
                setBillingCountry={setBillingCountry}
                setBillingProvince={setBillingProvince}
                setBillingCity={setBillingCity}
                billingCountry2={billingCountry2}
                billingProvince2={billingProvince2}
                billingCity2={billingCity2}
                setBillingCountry2={setBillingCountry2}
                setBillingProvince2={setBillingProvince2}
                setBillingCity2={setBillingCity2}
                billingEmail={billingEmail}
                setEmail={setEmail}
                billingName2={billingName2}
                setBillingName2={setBillingName2}
                billingAddress2={billingAddress2}
                setAddresss2={setAddresss2}
                billingGst2={billingGst2}
                setGst2={setGst2}
                phoneNumber2={phoneNumber2}
                setPhoneNumber2={setPhoneNumber2}
                billingSecondAddress2={billingSecondAddress2}
                setSecondAddresss2={setSecondAddresss2}
                billingPincode2={billingPincode2}
                setPincode2={setPincode2}
                email2={email2}
                setEmail2={setEmail2}
                openMethod={openMethod}
                openPremiumMethod={openPremiumMethod}
                handleSubmitBill={handleSubmitBill}
                handleSubmitPremiumBill={handleSubmitPremiumBill}
                setOpenMethod={setOpenMethod}
                setOpenPremiumMethod={setOpenPremiumMethod}
                selectedPlan={selectedPlan}
                plan={plan}
            />
        }
    }

    const handleNextStep = (values) => {
        const errors = formik.errors;
        // console.log("values are", values)
        // console.log(errors)
        // console.log(Object.keys(errors).length)
        if (registerdSteps === 0 && formik.values.CIN && !errors.Industries && !errors.established && !errors.companyUrl && !errors.description && !errors.noOfEmployees && !errors.companyType && !errors.phoneNo && !errors.CIN && !errors.legalEntityName && !errors.companyBrandName && !errors.officialMailId && !errors.verifyMailId && files && docType) {
            localStorage.setItem(`formStep${registerdSteps}`, JSON.stringify(values));
            setRegisterdSteps((prev) => prev + 1);
        } else if (registerdSteps === 1 && !errors.pinCode && !errors.city && !errors.province && !errors.country) {
            localStorage.setItem(`formStep${registerdSteps}`, JSON.stringify(values));
            setRegisterdSteps((prev) => prev + 1);
        } else if (registerdSteps === 2 && !errors.LinkedIn && !errors.faceBook && !errors.instagram && !errors.xMedia) {
            localStorage.setItem(`formStep${registerdSteps}`, JSON.stringify(values));
            setRegisterdSteps((prev) => prev + 1);
        } else {
            message.error("Please fill in the required fields.");
        }
    }

    const handleRegister = () => {
        if (registerdSteps === formTitles.length - 1) {
            formik.handleSubmit()
        } else {
            handleNextStep(formik.values)
        }
    }

    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`w-full max-w-4xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-slate-500 '} space-y-6 rounded-lg h-full mt-11`}>
                <div className="relative w-full bg-gray-200 rounded-full h-2.5 my-4 mb-3">
                    <div
                        className="absolute top-0 left-0 bg-blue-700 h-2.5 rounded-full"
                        style={{ width: `${((registerdSteps + 1) / formTitles.length) * 100}%` }}
                    ></div>
                    <div className="absolute w-full flex justify-between text-sm text-gray-600 mt-3">
                        {formTitles.map((title, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <span
                                    className={`flex items-center ${index < registerdSteps
                                        ? 'text-blue-800 font-semibold '
                                        : index === registerdSteps
                                            ? 'text-blue-700 font-bold '
                                            : 'text-gray-600'
                                        }`}
                                >
                                    {index < registerdSteps && (
                                        <svg
                                            className="w-4 h-4 mr-1  font-bold rounded-full"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    )}
                                    {title}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className='flex flex-col space-y-8 px-5 py-8 border mt-2 shadow-md'
                >
                    <div className='flex flex-col justify-between'>
                        {pageDisplay()}
                    </div>

                    <div className='flex justify-between'>
                        {registerdSteps == 0 && <button className='flex btn bottom-0 right-0 '
                            onClick={() => navigate(-1)}
                        ><IoIosArrowBack className='mt-0.5 ' /> Back</button>}
                        {!registerdSteps == 0 && <button className='flex btn bottom-0 right-0'
                            onClick={() => {
                                setRegisterdSteps((curSteps) => curSteps - 1)
                            }}
                        ><IoIosArrowBack className='mt-0.5 me-1' /> Back</button>}
                        <button type='submit' className='btn ml-auto'
                            onClick={handleRegister}>
                            {registerdSteps === formTitles.length - 1 ? "Submit" : <div className='flex'>Next <MdNavigateNext className='mt-1' /></div>}
                        </button>
                        <SuccessModal open={openSuccess} onClose={() => setOpenSuccess(false)} message={"Thanks for registering with us..your profile id under verification..."} path={'/dashboard'} buttonName="Go to dashboard" uploadStatus={uploadStatus} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterDetail