import {  investment_url } from "../../utils/constant";
import Axios from "axios"

const token=localStorage.getItem('authToken')

export const getTimeline = ()=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/project/timeline`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getAllProjects = ()=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/project`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const projectRegister = (
    payload,
)=>{
   
    return Axios({
        method:"POST",
        url:`${investment_url}/project`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

