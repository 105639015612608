export const CAPTCHA_SITE_KEY = "6LdtM1wqAAAAAPbBGLzj8iHCspwBGncolKlwd8Uk"
export const CAPTCHA_SECRET_KEY="6LdtM1wqAAAAAEnIT8OrnXh6iYdNFxAGMrNZlRdu"

//plans
export const basic_plan="9999"
export const premium_plan="19999"
//API urls
export const Base_url="http://127.0.0.1:8000"
// export const auth_url="http://143.110.247.113:8990"
export const auth_url="https://inkqubee.com/api"
// export const org_url="http://143.110.247.113:8990"
export const org_url="https://inkqubee.com/api"
// export const portfolio_url="http://143.110.247.113:8990"
export const portfolio_url="https://inkqubee.com/api"
export const investment_url="https://inkqubee.com/api"
// export const investment_url="http://143.110.247.113:8990"
// export const portfolio_url="https://inkqubee.com/api"
