import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { servicesOptions } from '../../../../utils/tempJson/_optionsJson'
import { useUpdatePortfolio } from '../../../../helper/serviceProvider/portfolioFormHook'
import { useNavigate, useParams } from 'react-router-dom'
import { getPortfolio, updatePortfolio } from '../../../../helper/serviceProvider/serviceProviderRequestCall'
import { message } from 'antd'
import { jwtDecode } from 'jwt-decode'
import { getCountries, userOrganization } from '../../../../helper/organization/orgRequestCall'

function UpdatePortfolio() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [companies, setCompanies] = useState([])
    const[countries,setCountries]=useState([])
    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const { id } = useParams()
    const navigate=useNavigate()

    const filterVerify=companies&&companies.filter(company => company.isVerified)

   

    const handleBack = () => {
        console.log("test back button")
        navigate(-1)
        
    }
    const fetchCompany = () => {
        userOrganization(userId)
            .then((res) => {
                if (res.status === 200) {
                    setCompanies(res.data.data)
                }
            })
            .catch((err) => { console.error("Error", err) })
    }

    const getSinglePortfolio = () => {
        getPortfolio(id)
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res",res)
                    formik.setFieldValue('name', res.data.data.name)
                    formik.setFieldValue('companyId', res.data.data.companyId)
                    formik.setFieldValue('service', res.data.data.services)
                    formik.setFieldValue('description', res.data.data.description)
                }
            })
            .catch((err) => console.error("Error", err))
    }

    useEffect(() => {
        getSinglePortfolio()
        fetchCompany()
    }, [])

    const handleProductEdit = (values) => {
        const { name, companyId, service, description } = values
        updatePortfolio(userId, name, companyId, service, description)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Updated successfully")
                } else {
                    message.error("Updation failed")
                }
            })
            .catch((err) => console.error("Error:", err))
    }

    const formik = useUpdatePortfolio(handleProductEdit)

    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
            <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
                <h1 className='text-center text-gray-600'>Update Portfolio</h1>
                {/* <ServiceProviderStep /> */}
                <form
                    className='flex flex-col space-y-3 px-5 py-8'
                    onSubmit={formik.handleSubmit}
                >
                    <input
                        type='text'
                        id='name'
                        className={`p-2 border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    // onBlur={formik.handleBlur}
                    />
                    <select
                        id='companyId'
                        name='companyId'
                        onChange={formik.handleChange}
                        value={formik.values.companyId}
                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                    >
                        {/* <option value="">Choose company</option> */}
                        {companies.map((option) => (
                            <option key={option.companyId} value={option.companyId} >
                                {option.legalEntityName}
                            </option>
                        ))}
                    </select>

                    <select
                        id='service'
                        name='service'
                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        onChange={formik.handleChange}
                        value={formik.values.service}
                    >
                        {servicesOptions.map((option, index) => (
                            <option key={index} value={option} >
                                {option}
                            </option>
                        ))}
                    </select>
                    <textarea
                        id='description'
                        className='p-3 border border-gray-600 focus:outline-none placeholder-slate-600 text-black rounded-lg'
                        placeholder='Description'
                        rows='5'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                    <div className='flex justify-center items-center'>
                        <button
                            onClick={()=>handleBack()}
                            type='button'
                            className='btn max-w-md w-52'
                        >Back</button>
                        <button
                            type='submit'
                            className='btn max-w-md w-52 ml-2'
                        >Submit</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default UpdatePortfolio