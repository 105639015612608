import React from 'react'
import { basic_plans } from '../../../../utils/tempJson/_tempItems'
import { TiTick } from "react-icons/ti";

function BasicPlan() {
    return (
        <div>
            {basic_plans.map((plans, index) => (
                <ul key={index}>
                   <li className='flex text-gray-600'><TiTick className='mt-1' color='green'/>{plans}</li>
                </ul>
            ))}
        </div>
    )
}

export default BasicPlan