import React, { useState } from 'react'
import { useSelector } from 'react-redux';

function SendMessage() {
  const { isDark} = useSelector((state) => state.displaySettingsState)
    const [messages, setMessages] = useState([
        { text: "Hello! How can I help you?", type: "received" },
        { text: "Hi! I need some assistance.", type: "sent" },
    ]);
    const [newMessage, setNewMessage] = useState("");

    const sendMessage = () => {
        if (newMessage.trim()) {
            setMessages([...messages, { text: newMessage, type: "sent" }]);
            setNewMessage("")
        }
    };

    return (
        <div className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            <div className='flex'>
                <div className='flex flex-col w-full'>
                    <div className="bg-blue-500 w-full  text-white p-4 font-bold text-lg sticky top-0 z-10">
                        Chat Screen
                    </div>
                    <div className="flex-grow p-4 overflow-y-auto scrollable-container hide-scrollbar space-y-4 bg-gray-50">
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`flex ${message.type === "sent" ? "justify-end" : "justify-start"
                                    }`}
                            >
                                <div
                                    className={`max-w-xs px-4 py-2 rounded-lg ${message.type === "sent"
                                        ? "bg-blue-500 text-white"
                                        : "bg-gray-300 text-black"
                                        }`}
                                >
                                    {message.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="p-4 bg-white border-t sticky bottom-0 flex items-center">
                        <input
                            type="text"
                            placeholder="Type a message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                        />
                        <button
                            onClick={sendMessage}
                            className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        >
                            Send
                        </button>
                    </div>
                </div>
                <div className='flex flex-col max-w-md'>
                    other chats
                </div>
            </div>
        </div>
    )
}

export default SendMessage