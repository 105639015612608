import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userAvatar from "../../../assets/userAvatar3.jpg"
import { getUserProfile } from '../../../helper/auth/authRequestCall'
import { setUserDetails } from '../../../store/slices/authSlice'
import { jwtDecode } from 'jwt-decode'
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom'
import { MdEdit } from "react-icons/md";

function UserProfile() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const { user } = useSelector((state) => state.authenticationState)
    const user_id = user && user.id
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleGoToId = (path,id) => {
        navigate('/user-profile/update', { state: { user_Id: id } })
    }
    const getUser = () => {
        const token = localStorage.getItem('authToken')
        const decoded = jwtDecode(token)
        const userId = decoded.userId
        getUserProfile(userId)
            .then(data => {
                const userProfile = data.data.data
                dispatch(setUserDetails(userProfile))
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div
            className={`flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'
                } py-10`}
        >
            <div className="w-full max-w-lg relative space-y-6 rounded-lg px-6 py-6">
                <div>
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                        <img
                            src={userAvatar}
                            alt="User Avatar"
                            className="w-24 h-24 rounded-full border-4 border-white shadow-md"
                        />
                    </div>
                </div>
                <div>
                    <div className="flex flex-col justify-start items-start bg-blue-800 p-6 rounded-lg shadow-md text-white space-y-4">
                        <div className="flex justify-between w-full">
                            <label className="font-semibold">First Name:</label>
                            <span className="ml-4">{user && user.firstName}</span>
                        </div>
                        <div className="flex justify-between w-full">
                            <label className="font-semibold">Last Name:</label>
                            <span className="ml-4">{user && user.lastName}</span>
                        </div>
                        <div className="flex justify-between w-full">
                            <label className="font-semibold">Username:</label>
                            <span className="ml-4">{user && user.username}</span>
                        </div>
                        {/* <div className="flex justify-between w-full">
                            <label className="font-semibold">Location:</label>
                            <span className="ml-4">Bengaluru</span>
                        </div> */}
                        <div className="flex justify-between w-full">
                            <label className="font-semibold">Phone Number:</label>
                            <span className="ml-4">{user&&user.phoneNumber}</span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <button className='flex btn' onClick={()=>navigate(-1)}><IoIosArrowBack className='me-1 mt-1' />Back</button>
                    {/* <button className='flex btn' onClick={()=>handleGoToId('/user-profile/update',user&&user.id)}><MdEdit className='me-1 mt-1' />Update Profile</button> */}
                </div>
            </div>
        </div>
    )
}

export default UserProfile