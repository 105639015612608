import React from 'react'
import { brand_image } from '../../../utils/tempJson/_tempItems'

function FourthSection({isDark}) {
  return (
    <div className={`w-full mx-auto px-4 sm:px-6 lg:px-8 py-16 border-t border-gray-100 ${isDark ? 'dark-mode':'bg-slate-100'}`}>
      <h2 className="text-2xl font-bold text-center mb-8">Trusted by Leading Indian Businesses</h2>
      <div className="grid grid-cols-2 justify-center md:grid-cols-3 gap-8 items-center">
        {brand_image.map((logo,index) => (
          <div key={index} className="flex justify-center items-center p-8 bg-gray-50 rounded-lg">
            <div className="flex flex-col h-12 w-full rounded items-center justify-center">
              <img
              src={logo.logo}
              alt='brands'
              className='h-20'
              />
              <span className='text-gray-600'>{logo.name}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center">
        {/* <p className="text-gray-600">And 1000+ more verified businesses across India</p> */}
      </div>
    </div>
  )
}

export default FourthSection