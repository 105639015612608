import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import UnderConstruction from '../../../assets/workstation.avif'
import { Link, useNavigate } from 'react-router-dom'
import { CiLocationOn } from 'react-icons/ci';
import { ImProfile } from 'react-icons/im';
import { GiTakeMyMoney } from "react-icons/gi";
import { temp_investors } from '../../../utils/tempJson/_tempItems';
import { getAllProjects } from '../../../helper/project/projectRequestCalls';
import { MdDateRange } from "react-icons/md";
import { IoTimerSharp } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";


function OtherProjects() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const navigate = useNavigate()
  const [projects, setProjects] = useState([])

  const fetchProjects = () => {
    getAllProjects()
      .then((res) => {
        if (res.status === 200) {
          setProjects(res.data.data)
        }
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const handleOpenDetail = (id) => {
    navigate(`/investor/${id}`)
  }
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4 mt-6`}>
        {/* <div className='flex flex-col  md:flex-row justify-between border p-3 shadow-md'>
          <h1 className='flex text-2xl font-bold mt-5 ml-2 text-gray-500'><FaFilter className='mt-0.5 me-2' />Filter</h1>
          <div className='flex p-2'>
            <div className="relative flex flex-col ">
              <input
                type='text'
                placeholder='Filter by name'
                className="p-2 focus:outline-none border border-gray-400 rounded mt-3 ml-2 w-64"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor='name' className='flex absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>
                Name
              </label>
            </div>

            <div className="relative flex flex-col mt-2">
              <input
                type="text"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                className="p-2 focus:outline-none border border-gray-400 rounded mt-1 ml-2 w-64"
                placeholder="Filter by Industry"
              />
              <label htmlFor='ty' className='flex absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'>
                Industry<span className='text-red-600 ml-1 '>*</span>
              </label>
            </div>

            <div className="relative flex flex-col mt-2">

              <select name='type'
                value={type}
                onChange={(e) => setType(e.target.value)}
                // placeholder="Filter Service"
                className='shadow border rounded w-56 py-2 px-3 ml-2 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
              >
                <option value="" disabled>Filter Type</option>
              </select>
            </div>

          </div>

        </div> */}
        {/* <div className='flex flex-col gap-2 justify-center items-center'>
          {
            projects.length > 0 ? (
              projects?.map((project) => (
                <div className='h-auto w-full  bg-white rounded-lg mt-4 border border-gray-400 shadow' key={project.projectId}>
                  <div className='p-3' >
                    <div className='flex justify-between'>
                      <div>
                        <div className='flex justify-between'>
                          <Link className='mt-2 text-xl font-bold cursor-pointer hover:underline'>{project.projectTitle} </Link>
                        </div>
                      </div>
                      <div className='flex'>
                        <div className=' ml-5'>
                          <Link className='flex border border-blue-800 bg-blue-800 hover:bg-blue-900 text-white ml-2 p-2 me-1 mt-2 rounded-lg  hover:shadow-lg' >Add To Bookmark <ImProfile className='mt-1 ml-1' /></Link>
                        </div>
                        <div className=''>
                          <Link className='flex border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 p-2 me-4 mt-2 rounded-lg text-black hover:shadow-lg' >View Profile <ImProfile className='mt-1 ml-1' /></Link>
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-12 px-4 p-9 '>
                      <div>
                        <li className='flex  text-gray-500'><IoTimerSharp className='mt-1 me-1' /> {project.projectTimeLine}</li>
                        <li className='flex  text-gray-500'><FaFileAlt/>{project.preferredSubmissionFormat}</li>
                        
                      </div>
                      <div>

                      </div>
                      <div className='flex '>
                      <div className='ml-8'>
                          <h1 className='text-blue-500'>Budget Range</h1>
                          <ul>
                            <li>
                            {project.budgetRange}
                            </li>
                          </ul>
                        </div>
                      <div className='ml-8'>
                          <h1 className='text-blue-500'>Number Of Resources</h1>
                          <ul>
                            <li>
                            {project.numberOfResources}
                            </li>
                          </ul>
                        </div>
                        <div className='ml-8'>
                          <h1 className='text-blue-500'>Deadline</h1>
                          <ul>
                            <li>
                              {project.deadline}
                            </li>
                          </ul>
                        </div>
                        <div className='ml-8'>
                          <h1 className='text-blue-500'>Description</h1>
                          <ul>
                            <li>
                              {project.projectDescription.length > 60
                                ? (
                                  <>
                                    {project.projectDescription.substring(0, 60)}....
                                    <button onClick={() => handleOpenDetail(project.id)} className='text-blue-500'>
                                      View more
                                    </button>
                                  </>
                                )
                                : project.projectDescription}
                            </li>
                          </ul>
                        </div>

                        <div className='ml-8'>
                          <h1 className='text-blue-500'>Objectives & Goals</h1>
                          <ul>
                            <li>
                              {project.objectiveAndGoals.length > 60
                                ? (
                                  <>
                                    {project.objectiveAndGoals.substring(0, 60)}....
                                    <button onClick={() => handleOpenDetail(project.id)} className='text-blue-500'>
                                      View more
                                    </button>
                                  </>
                                )
                                : project.objectiveAndGoals}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))) : (
              <div>
              </div>
            )}
        </div> */}
      </div>
    </div>
  )
}

export default OtherProjects