import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MdVerified } from "react-icons/md";
import { Tooltip } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import YourVerification from '../../../../components/private/organization/YourVerification';
import Portfolio from '../../../../components/private/organization/Portfolio';
import { GoUnverified } from "react-icons/go";
import { companyProfile, getCompanyLogo } from '../../../../helper/organization/orgRequestCall';
import CompanyOverview from '../../../../components/private/organization/CompanyOverview';
import { FaUsers } from "react-icons/fa";
import CompanyUsers from '../../../../components/private/organization/CompanyUsers'
import defaultLogo from '../../../../assets/logo/defaultCompanyLogo.png'
import { FaUpload } from "react-icons/fa6";
import { jwtDecode } from 'jwt-decode';

function CompanyProfile() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const token = localStorage.getItem('authToken')
    const navigate = useNavigate()
    const [openUsers, setOpenUsers] = useState(false)
    const [openVerification, setOpenVerification] = useState(false)
    const [openPortfolio, setOpenPortfolio] = useState(false)
    const [verified, setVerified] = useState(false)
    const [comapanyProfile, setProfile] = useState(null)
    const [logo, setLogo] = useState(null)
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const location = useLocation();
    const orgId = location.state?.org_id;

    console.log("ord",orgId)

    const handleGoToId = (path,id) => {
        navigate(path, { state: { org_id: id } })
    }


    const getOrgLogo = () => {
        getCompanyLogo(orgId)
            .then((res) => {
                const url = URL.createObjectURL(res.data);
                setLogo(url)
                console.log("data", url)
            })
            .catch((err) => {
                console.error("errpr", err)
            })
    }
    const getCompanyProfile = () => {
        companyProfile(orgId, userId)
            .then((res) => {
                if (res.status === 200) {
                    setProfile(res.data.data)
                    // setVerified(res.data.verified)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getCompanyProfile()
        // console.log("profile",profile)
        getOrgLogo()
    }, [])
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            <div className={`flex  ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4`}>
            {comapanyProfile && (
                <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
                    <div className='flex justify-between w-full mt-1'>
                        <div></div>
                        <div className='flex'>
                            {/* <Link
                            to={`/organization/update-profile/${profile.id}`}
                            className='flex bg-blue-700 hover:bg-blue-500 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                        >
                            Update profile <MdModeEdit className='mt-1 ml-2' color={'white'} />
                        </Link> */}
                            <button
                                onClick={()=>handleGoToId('/organization/update-profile',comapanyProfile.companyId)}
                                className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                            >Update Organization</button>
                            <button
                                onClick={()=>handleGoToId('/organization/upload-logo',comapanyProfile.companyId)}
                                className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                            >Upload logo <FaUpload className='ml-1' /></button>
                            {/* <button
                                to={`/organization/upload-file/${comapanyProfile.id}`}
                                className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                            >Upload File <FaUpload className='ml-1' /></Link> */}
                            <Link
                                to={'/organization/add-users'}
                                className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                            >
                                Add Users <FaUsers className='mt-1 ml-2' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex justify-between max-w-7xl  p-2 relative'>
                        {
                            <img
                                src={logo ? logo : defaultLogo}
                                alt='logo'
                                className='relative h-32 w-44 rounded-xl shadow-lg'
                            />
                        }
                        {
                            comapanyProfile.isVerified ?
                                <Tooltip placement='top' title='verified'>
                                    <MdVerified color='green' size={30} className='absolute top-0 left-44 cursor-pointer' />
                                </Tooltip> :
                                <Tooltip placement='top' title='under verification'>
                                    <GoUnverified color='yellow' size={30} className='absolute top-0 left-44 cursor-pointer' />
                                </Tooltip>
                        }
                        {/* <div className='mt-6 bg-red-400 p-2'> */}
                        {/* <span className='mt-6 bg-red-400 p-2'>Your profile is currently being reviewed by our team and will be published soon.</span> */}
                        {/* </div> */}
                        <div className='flex flex-row gap-3'>
                            {/* <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                            <h1 className='text-black text-xl'>Total Reviews</h1>
                            <span className='mt-2 text-blue-600'>100</span>
                        </div>

                        <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                            <h1 className='text-black text-xl'>Most Common Project Size </h1>
                            <span className='mt-2 text-blue-600'>$XX,XXX – $XXX,XXX</span>
                        </div> */}
                        </div>
                    </div>

                    <div className='flex flex-col gap-7 p-2 h-full '>
                        <div className={`flex flex-row gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full h-auto shadow-lg rounded-lg p-5`}>
                            {/* <div className='p-2'>
                            <h1 className='flex text-2xl font-bold'><FcAbout className='mt-2 me-2' />about</h1>
                            <p className={`${isDark ? 'text-white' : 'text-gray-500'}`}>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div className='border-l p-2 px-6 max-w-96'>
                            <span>{profile.name}</span>
                            <Tooltip placement='top' title="Location" >
                                <span className='flex text-gray-600 mt-1'>
                                    <CiLocationOn className='me-1 mt-1' />
                                    {profile.location}
                                </span>
                            </Tooltip>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><CiMail className='me-1 mt-1' /> {profile.official_mail}</span>

                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}><span className='text-black font-semibold'> CIN: </span>{profile.CIN}</span>
                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}> <span className='text-black font-semibold'> PAN:</span>{profile.PAN}</span>
                        </div> */}
                            <div className='w-full'>
                                <CompanyOverview profile={comapanyProfile} />
                            </div>
                        </div>

                        <div className={`${isDark ? 'bg-slate-700' : 'bg-white'} h-auto rounded-lg w-full shadow-lg`}>

                            <div className='p-2'>
                                {/* <h1 className='text-2xl font-bold'>Portfolio details</h1> */}
                            </div>
                            <ul className='p-3 space-y-4'>
                                <li
                                    onClick={() => setOpenUsers(!openUsers)}
                                    className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'>
                                    Users
                                    {openUsers ?
                                        <FaChevronUp
                                            className='cursor-pointer'
                                            onClick={() => setOpenUsers(!openUsers)}
                                        />
                                        :
                                        <FaChevronDown
                                            className='cursor-pointer'
                                            onClick={() => setOpenUsers(!openUsers)}
                                        />
                                    }
                                </li>
                                {openUsers && <div>
                                    <CompanyUsers id={orgId} />
                                </div>}

                                {/* <li
                                onClick={() => setOpenVerification(!openVerification)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                            >
                                Verification
                                {openVerification ?
                                    <FaChevronUp
                                        className='cursor-pointer'
                                        onClick={() => setOpenVerification(!openVerification)}
                                    />
                                    :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenVerification(!openVerification)}
                                    />
                                }
                            </li> */}
                                {openVerification && <div>
                                    <YourVerification verified={verified} />
                                </div>}

                                <li
                                    onClick={() => setOpenPortfolio(!openPortfolio)}
                                    className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                                >
                                    Portfolio
                                    {openPortfolio ?
                                        <FaChevronUp className='cursor-pointer'
                                            onClick={() => setOpenPortfolio(!openPortfolio)}
                                        /> :
                                        <FaChevronDown
                                            className='cursor-pointer'
                                            onClick={() => setOpenPortfolio(!openPortfolio)}
                                        />}
                                </li>
                                {openPortfolio && <div>
                                    <Portfolio org_id={orgId} />
                                </div>}

                                {/* <li
                                onClick={() => setOpenContact(!openContact)}
                                className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                            >
                                Contact Information
                                {openContact ?
                                    <FaChevronUp
                                        className='cursor-pointer'
                                        onClick={() => setOpenContact(!openContact)}
                                    /> :
                                    <FaChevronDown
                                        className='cursor-pointer'
                                        onClick={() => setOpenContact(!openContact)}
                                    />}
                            </li>
                            {openContact && <div>
                                <ContactInfo />
                            </div>} */}

                            </ul>
                        </div>
                    </div>
                    

                </div>)}
                </div>
        </div>
    )
}

export default CompanyProfile