import Home from "./Home";
import TermsAndService from "../src/views/pages/public/TermsAndService";
import Login from "../src/views/pages/auth/Login";
import Register from "../src/views/pages/auth/Register";
import Contact from "./views/pages/public/Contact";
import SetupOdc from "../src/views/pages/public/SetupOdc";
import AdminDashboard from '../src/views/pages/private/admin/AdminDashboard'
import PortfolioList from "../src/views/pages/private/serviceProvider/PortfolioList";
import Reviews from "../src/views/pages/private/serviceProvider/Reviews";
import RegisterDetail from "./views/pages/private/organization/RegisterDetail";
import ServicEProviderRegister from "../src/views/pages/private/serviceProvider/ServicEProviderRegister";
import ServiceProviderOption from "./views/pages/private/serviceProvider/ServiceProviderOption";
import CompanyProfile from "./views/pages/private/organization/CompanyProfile";
import SearchResult from "./views/pages/SearchResult";
import ServiceProviderList from "./views/pages/private/ServiceProviderList";
import OrgDetailsConfirm from "./views/pages/private/organization/OrgDetailsConfirm";
import React from "react";
import Dashboard from "./views/pages/private/Dashboard";
import ForgotPassword from "./views/pages/auth/ForgotPassword";
import OtherProjects from "./views/pages/private/OtherProjects";
import ServiceProviderDetail from "./views/pages/private/ServiceProviderDetail";
import UpdateOrganization from "./views/pages/private/organization/UpdateOrganization";
import Myportfolios from "./views/pages/private/serviceProvider/Myportfolios";
import UpdatePortfolio from "./views/pages/private/serviceProvider/UpdatePortfolio";
import PrivacyPolicy from "./views/pages/public/PrivacyPolicy";
import RefundPolicy from "./views/pages/public/RefundPolicy";
import InvestorList from "./views/pages/private/investment/InvestorList";
import PackageTable from "./views/pages/public/PackageTable";
import MarketingWebsite from "./views/pages/public/MarketingWebsite";
import Testimonials from "./views/pages/public/Testimonials";
import AddUsers from "./views/pages/private/organization/AddUsers";
import Careers from "./views/pages/public/Careers";
import UploadLogo from "./views/pages/private/organization/UploadLogo";
import PostRequirments from "./views/pages/private/PostRequirments";
import PaymentPage from "./views/pages/public/PaymentPage";
import UploadSupportingFile from "./views/pages/private/organization/UploadSupportingFile";
import UserProfile from "./views/pages/auth/UserProfile";
// import UserUpdate from "./views/pages/auth/UserUpdate";
import InvestmentRegister from "./views/pages/private/investment/InvestmentRegister";
import InvestorDetail from "./views/pages/private/investment/InvestorDetail";
import Events from "./views/pages/private/Events";
import ClientRegistration from "./views/pages/private/clients/ClientRegistration";
import AllOrganizations from "./views/pages/private/admin/AllOrganizations";
import Inbox from "./views/pages/private/admin/Inbox";
import SendMail from "./views/pages/private/admin/SendMail";
import SendMessage from "./views/pages/private/organization/SendMessage";
import InvestmentOpertunities from "./views/pages/private/investment/InvestmentOpertunities";
import NotificationsPage from "./views/pages/private/NotificationsPage";
import CompanyDetail from "./views/pages/private/admin/CompanyDetail";
import AllInvestors from "./views/pages/private/admin/AllInvestors";
import SingleInvestor from "./views/pages/private/admin/SingleInvestor";
import InvestorProfile from "./views/pages/private/investment/InvestorProfile";



// const Dashboard=React.lazy(()=>import('../src/views/pages/private/Dashboard'))
export const publicRoutes = [
    {path:'/',name:"home",component:Home,exact:true},
    {path:'/login',name:"login",component:Login,exact:true},
    {path:'/register',name:"register",component:Register,exact:true},
    // {path:'/terms-and-service',name:"terms",component:TermsAndService,exact:true},
    // {path:'/privacy-policy',name:"terms",component:PrivacyPolicy,exact:true},
    {path:'/refund-policy',name:"terms",component:RefundPolicy,exact:true},
    {path:'/contact',name:"contact",component:Contact,exact:true},
    {path:'/setup-odc-india',name:"ODC india",component:SetupOdc,exact:true},
    {path:'/search/result',name:"Search result",component:SearchResult,exact:true},
    {path:'/login/forgot-password',name:"forgot password",component:ForgotPassword,exact:true},
    {path:'/market-page',name:"market",component:MarketingWebsite,exact:true},
    {path:'/testimonials',name:"testimonials",component:Testimonials,exact:true},
    {path:'/careers',name:"Careers",component:Careers,exact:true},
    {path:'/package-details',name:"Package Table",component:PackageTable,exact:true},
    {path:'/Payment-Page',name:"Payment details",component:PaymentPage,exact:true},
]

export const private_routes = [
    {path:'/dashboard',name:"Dashboard",component:Dashboard,exact:true},
    {path:'/user-profile/:id',name:"User Profile",component:UserProfile,exact:true},
    {path:'/send-message',name:"User Profile",component:SendMessage,exact:true},
    {path:'/investor/profile',name:"User Profile",component:InvestorProfile,exact:true},
    // {path:'/user-profile/update',name:"User udate",component:UserUpdate,exact:true},
    {path:'/service-provider/portfolio-list',name:"Service Provider portfolio",component:PortfolioList,exact:true},
    {path:'/service-provider/user-reviews',name:"Service Provider reviews",component:Reviews,exact:true},
    {path:'/service-provider/option',name:"Service Provider Register",component:ServicEProviderRegister,exact:true,verified: true},
    // {path:'/service-provider/option/:id',name:"Service Provider Registration",component:ServiceProviderOption,exact:true},
    {path:'/register-company/company-detail',name:"Create Company Profile",component:RegisterDetail,exact:true},
    {path:'/register-company/details-confirm',name:"confirm details",component:OrgDetailsConfirm,exact:true},
    {path:'/organization/profile',name:"Company Profile",component:CompanyProfile,exact:true},
    {path:'/service-provider/list',name:"All Service Providers",component:ServiceProviderList,exact:true},
    {path:'/other-projects/list',name:"Projects",component:OtherProjects,exact:true},
    {path:'/service-provider/detail/:org_id',name:"Service Provider Detail",component:ServiceProviderDetail,exact:true},
    {path:'/organization/update-profile',name:"Organization Update",component:UpdateOrganization,exact:true},
    {path:'/service-provider/all-portfolios',name:"All Portfolios",component:Myportfolios,exact:true},
    {path:'/service-provider/update-portfolio/:id',name:"Update Portfolio",component:UpdatePortfolio,exact:true},
    {path:'/investors/list',name:"Investor List",component:InvestorList,exact:true},
    {path:'/organization/add-users',name:"Add Users",component:AddUsers,exact:true},
    {path:'/organization/upload-logo',name:"Upload logo",component:UploadLogo,exact:true},
    {path:'/organization/upload-file/:org_id',name:"Upload File",component:UploadSupportingFile,exact:true},
    {path:'/organization/requirments',name:"Project Requirment",component:PostRequirments,exact:true},
    {path:'/investment/register',name:"Investor register",component:InvestmentRegister,exact:true},
    {path:'/investor/:id',name:"Investor Detail",component:InvestorDetail,exact:true},
    {path:'/investment',name:"Investment Opertunities",component:InvestmentOpertunities,exact:true},
    {path:'/events',name:"Investor Detail",component:Events,exact:true},
    {path:'/clients/post-project',name:"project registration",component:ClientRegistration,exact:true},
    {path:'/notifications',name:"Notifications",component:NotificationsPage,exact:true}

]

export const admin_routes = [
    {path:'/admin/dashboard',name:"Admin Dashboard",component:AdminDashboard,exact:true},
    {path:'/admin/all-organizations',name:"Admin organizations",component:AllOrganizations,exact:true},
    {path:'/admin/all-investors',name:"Admin organizations",component:AllInvestors,exact:true},
    {path:'/admin/inbox',name:"Admin Inbox",component:Inbox,exact:true},
    {path:'/admin/send-mail',name:"Send Mail",component:SendMail,exact:true},
    {path:'/admin/companydetail',name:"Company details admin",component:CompanyDetail,exact:true},
    {path:'/admin/investor-details',name:"Investor details admin",component:SingleInvestor,exact:true},
]
export const policy_page = [
    {path:'/terms-and-service',name:"terms",component:TermsAndService,exact:true},
    {path:'/privacy-policy',name:"Privacy policy",component:PrivacyPolicy,exact:true},
]
