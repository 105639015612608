import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
import { RxDotFilled } from "react-icons/rx";


function PrivacyPolicyModal({ open, handleClose }) {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    return (
        <Dialog
            fullScreen // Makes the dialog fullscreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle className='flex justify-between'>
                Privacy Policy
                <IoIosClose size={40} className='cursor-pointer' onClick={() => handleClose()} />
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">

                    <div className="">
                        <section className={`flex flex-col w-full justify-start p-8 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
                            <div lang="en-IN" link="#0563c1" vlink="#954f72" dir="ltr">
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in"><a name="_GoBack"></a>
                                    <b>Last updated:</b> 08 November 2024</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="">This Privacy Policy
                                    outlines how we collect, use, and disclose information from users and
                                    visitors through both online and offline interactions, including our
                                    website, https://inkqubee.com, and our associated research and review
                                    services (collectively referred to as the “Services”). By
                                    accessing the Services or agreeing to this Privacy Policy, you
                                    consent to the collection, use, disclosure, and management of your
                                    information as detailed below.
                                </p>
                                <p className=""><br />

                                </p>
                                <p className="">If you have any
                                    concerns about sharing your information with us or its potential
                                    display or use within the Services as permitted by this Privacy
                                    Policy, we advise against using the Services.</p>
                                <p className=""><br />

                                </p>
                                <p className="">This Privacy Policy
                                    details our practices regarding the collection, use, and disclosure
                                    of your information when you utilize the Services. It also informs
                                    you of your privacy rights and how the law safeguards your
                                    information.</p>
                                <p className=""><br />

                                </p>
                                <p className="">We use Your Personal
                                    data to provide and improve the Service. By using the Service, You
                                    agree to the collection and use of information in accordance with
                                    this Privacy Policy.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-3xl border-b border-gray-200">Interpretation and Definitions</h2>
                                <h3 className="text-blue-600 mt-2">Interpretation</h3>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">The words of which
                                    the initial letter is capitalized have meanings defined under the
                                    following conditions. The following definitions shall have the same
                                    meaning regardless of whether they appear in singular or in plural.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h3 className="text-blue-600 mt-2">Definitions</h3>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="font-semibold">For the purposes of
                                    this Privacy Policy:</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                                    <li><b>Account</b>
                                        means a unique account created for You to access our Service or
                                        parts of our Service.</li>
                                    <li><b>Company</b>
                                        (referred to as either 'the Company', 'We', 'Us' or 'Our' in this
                                        Agreement) refers to PROPELX BYTENET PRIVATE LIMITED</li>

                                    <li><b>Cookies</b>
                                        are small files that are placed on Your computer, mobile device or
                                        any other device by a website, containing the details of Your
                                        browsing history on that website among its many uses.</li>

                                    <li><b>Country</b>
                                        refers to: Karnataka, India</li>

                                    <li><b>Device</b>
                                        means any device that can access the Service such as a computer, a
                                        cellphone or a digital tablet.</li>

                                    <li><b>Personal
                                        Data</b> is any information that relates to an identified or
                                        identifiable individual.</li>
                                    <li><b>Service</b>
                                        refers to the Website.</li>
                                    <li><b>Service
                                        Provider</b> means any natural or legal person who processes the
                                        data on behalf of the Company. It refers to third-party companies or
                                        individuals employed by the Company to facilitate the Service, to
                                        provide the Service on behalf of the Company, to perform services
                                        related to the Service or to assist the Company in analyzing how the
                                        Service is used.</li>
                                    <li><b>Third-party
                                        Social Media</b> Service refers to any website or any social network
                                        website through which a User can log in or create an account to use
                                        the Service.</li>
                                    <li><b>Usage Data</b>
                                        refers to data collected automatically, either generated by the use
                                        of the Service or from the Service infrastructure itself (for
                                        example, the duration of a page visit).</li>
                                    <li><b>Website</b>
                                        refers to PROPELX BYTENET PRIVATE LIMITED, accessible from
                                        https://inkqubee.com</li>
                                    <li><b>You</b>
                                        means the individual accessing or using the Service, or the company,
                                        or other legal entity on behalf of which such individual is
                                        accessing or using the Service, as applicable.</li>
                                </ul>

                                <h2 className="text-3xl border-b border-gray-200 mt-4">Collecting and Using Your Personal Data</h2>
                                <h3 className="text-blue-600 font-semibold mt-2">Types of Data Collected</h3>
                                <h4 className="text-blue-600">Data Submitted by you</h4>
                                <p className="mt-3">When you use our
                                    services, we collect various types of information to enhance your
                                    experience and ensure the functionality of our platform. Below is a
                                    detailed overview of the data we collect:</p>
                                <h5 className="text-blue-600">Personal Information:</h5>
                                <ul className='list-disc list-inside pl-4 space-y-2 mt-2'>
                                    <li >Directly
                                        Provided Information: When you engage with our service, We collect
                                        and store confidential information including, but not limited to
                                        your name, email address, telephone<font color="#4b5563"><font face="Arial, sans-serif"><span className="background: #ffffff">
                                            numbers, </span></font></font>your company information, financial
                                        information, transactional information based on your activities on
                                        the website. This can be done directly or through your LinkedIn
                                        account.</li>

                                    <li >Payment
                                        Information: If you purchase specific services, we may request your
                                        credit card or banking details to process the transaction.</li>
                                    <li >Profile
                                        Enhancement: You have the option to add more information to your
                                        profile, including your location, industry, biography, profile
                                        picture, and phone number.</li>
                                </ul>
                                <p className="line-height: 100%; margin-bottom: 0in">If you believe
                                    someone has created an unauthorized account using your likeness,
                                    please contact us at&nbsp;compliance<font color="#0563c1"><u><a href="mailto:contact@inkqubee.com">@inkqubee.com</a></u></font>&nbsp;for
                                    removal.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h5 className="text-blue-600">Review Submission Data</h5>
                                <ul className='list-disc list-inside pl-6 space-y-2'>
                                    <li >Information
                                        Collected: During the review submission process, we collect your
                                        name, company name, phone number, and email address for internal
                                        records. This information will remain confidential and will not be
                                        publicly accessible.</li>
                                </ul>
                                <h5 className="text-blue-600 mt-2">Posted Reviews</h5>
                                <ul className='list-disc list-inside pl-6 space-y-2 '>
                                    <li >Anonymity
                                        Options: When posting reviews, you can choose to remain anonymous.
                                        In this case, only general information such as your job title,
                                        company description, industry, company size, and location will be
                                        displayed.</li>
                                    <li >Attribution: If
                                        you prefer to attribute your review with personal details (e.g.,
                                        your name, company name, project specifics, and photo), you consent
                                        to us publicly displaying this information in connection with your
                                        review.</li>
                                </ul>
                                <h5 className="text-blue-600 mt-2">Survey Responses</h5>
                                <ul className='list-disc list-inside pl-6 space-y-2 '>
                                    <li >Anonymity
                                        Assurance: Responses collected from surveys are anonymous. We will
                                        present the results alongside generic information such as job title,
                                        department, industry, company size, and location without revealing
                                        individual identities.</li>
                                </ul>
                                <h5 className="text-blue-600 mt-2">Project Request and Consultation Forms</h5>
                                <ul className='list-disc list-inside pl-6 space-y-2 '>
                                    <li >Required
                                        Information: When submitting a project request or consultation form,
                                        please provide accurate and relevant details. Essential information
                                        includes your name, company name, project specifics, email address,
                                        and preferred contact method.</li>
                                    <li >Information
                                        Sharing: Your email address and other submitted details may be
                                        shared with service providers and partner vendors who can best meet
                                        your project needs.</li>
                                </ul>
                                <h5 className="text-blue-600 mt-2">Information About Others</h5>
                                <ul className='list-disc list-inside pl-6 space-y-2 '>
                                    <li >Third-Party
                                        Information: You may provide information about other individuals
                                        (e.g., clients) such as their names, emails, phone numbers, and
                                        companies to assist in our reference check and review process. It is
                                        essential that you obtain consent from these individuals before
                                        sharing their information with us. The data provided will solely be
                                        used for reference checks and reviews.</li>
                                </ul>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h4 className="text-blue-600">Third-Party Data Collection</h4>
                                <ul className='list-disc list-inside pl-6 space-y-2 '>
                                    <li >Social Media
                                        Integration: If you sign in using a social media account (like
                                        LinkedIn/ google/..), we may collect your user ID and any public
                                        information linked to that account. By connecting your social media
                                        account to our services, you consent to our collection and use of
                                        the information available through that interface.</li>
                                    <li >User-Generated
                                        Content: We may also gather information about you from
                                        user-generated content posted by others on our services. For
                                        instance, another user might include your details in their review.</li>
                                </ul>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h3 className="text-blue-600">Usage Data</h3>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Usage Data is
                                    collected automatically when using the Service.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Usage Data may
                                    include information such as Your Device's Internet Protocol address
                                    (e.g. IP address), browser type, browser version, the pages of our
                                    Service that You visit, the time and date of Your visit, the time
                                    spent on those pages, unique device identifiers and other diagnostic
                                    data.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">When You access the
                                    Service by or through a mobile device, We may collect certain
                                    information automatically, including, but not limited to, the type of
                                    mobile device You use, Your mobile device unique ID, the IP address
                                    of Your mobile device, Your mobile operating system, the type of
                                    mobile Internet browser You use, unique device identifiers and other
                                    diagnostic data.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">We may also collect
                                    information that Your browser sends whenever You visit our Service or
                                    when You access the Service by or through a mobile device.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h3 className="text-blue-600">Tracking Technologies and Cookies</h3>

                                <p className="mt-2">We use Cookies and
                                    similar tracking technologies to track the activity on Our Service
                                    and store certain information. Tracking technologies used are
                                    beacons, tags, and scripts to collect and track information and to
                                    improve and analyze Our Service. The technologies We use may include:</p>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li><b>Cookies or
                                        Browser Cookies.</b> A cookie is a small file placed on Your Device.
                                        You can instruct Your browser to refuse all Cookies or to indicate
                                        when a Cookie is being sent. However, if You do not accept Cookies,
                                        You may not be able to use some parts of our Service. Unless you
                                        have adjusted Your browser setting so that it will refuse Cookies,
                                        our Service may use Cookies.</li>
                                    <li><b>Flash
                                        Cookies.</b> Certain features of our Service may use local stored
                                        objects (or Flash Cookies) to collect and store information about
                                        Your preferences or Your activity on our Service. Flash Cookies are
                                        not managed by the same browser settings as those used for Browser
                                        Cookies. For more information on how You can delete Flash Cookies,
                                        please read 'Where can I change the settings for disabling, or
                                        deleting local shared objects?' available at
                                        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</li>
                                    <li><b>Web Beacons.</b>
                                        Certain sections of our Service and our emails may contain small
                                        electronic files known as web beacons (also referred to as clear
                                        gifs, pixel tags, and single-pixel gifs) that permit the Company,
                                        for example, to count users who have visited those pages or opened
                                        an email and for other related website statistics (for example,
                                        recording the popularity of a certain section and verifying system
                                        and server integrity).</li>
                                </ul>

                                <p className="mt-2">Cookies can be
                                    'Persistent' or 'Session' Cookies. Persistent Cookies remain on Your
                                    personal computer or mobile device when You go offline, while Session
                                    Cookies are deleted as soon as You close Your web browser.</p>

                                <p className="mt-2">We use both Session
                                    and Persistent Cookies for the purposes set out below:</p>
                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >Necessary /
                                        Essential Cookies</li>
                                </ul>

                                <p className="mt-2 ml-16">Type:
                                    Session Cookies</p>

                                <p className="mt-2 ml-16">Administered
                                    by: Us</p>
                                <p className="mt-2 ml-16">Purpose:
                                    These Cookies are essential to provide You with services available
                                    through the Website and to enable You to use some of its features.
                                    They help to authenticate users and prevent fraudulent use of user
                                    accounts. Without these Cookies, the services that You have asked for
                                    cannot be provided, and We only use these Cookies to provide You with
                                    those services.</p>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >Cookies Policy
                                        / Notice Acceptance Cookies</li>
                                </ul>

                                <p className="mt-2 ml-16">Type:
                                    Persistent Cookies</p>
                                <p className="mt-2 ml-16">Administered
                                    by: Us</p>
                                <p className="mt-2 ml-16">Purpose:
                                    These Cookies identify if users have accepted the use of cookies on
                                    the Website.</p>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >Functionality
                                        Cookies</li>
                                </ul>

                                <p className="mt-2 ml-16">Type:
                                    Persistent Cookies</p>

                                <p className="mt-2 ml-16">Administered
                                    by: Us</p>

                                <p className="mt-2 ml-16">Purpose:
                                    These Cookies allow us to remember choices You make when You use the
                                    Website, such as remembering your login details or language
                                    preference. The purpose of these Cookies is to provide You with a
                                    more personal experience and to avoid You having to re-enter your
                                    preferences every time You use the Website.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">For more information
                                    about the cookies we use and your choices regarding cookies, please
                                    visit our Cookies Policy or the Cookies section of our Privacy
                                    Policy.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-500">Use of Your Information Collected</h2>

                                <p className="mt-2">The Company may use
                                    Personal Data for the following purposes:</p>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >To provide and
                                        maintain our Service, including to monitor the usage of our Service.</li>

                                    <li >To manage Your
                                        Account: to manage Your registration as a user of the Service. The
                                        Personal Data You provide can give You access to different
                                        functionalities of the Service that are available to You as a
                                        registered user.</li>
                                </ul>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >For the
                                        performance of a contract: the development, compliance and
                                        undertaking of the purchase contract for the products, items or
                                        services You have purchased or of any other contract with Us through
                                        the Service.</li>

                                    <li >To contact You:
                                        To contact You by email, telephone calls, SMS, or other equivalent
                                        forms of electronic communication, such as a mobile application's
                                        push notifications regarding updates or informative communications
                                        related to the functionalities, products or contracted services,
                                        including the security updates, when necessary or reasonable for
                                        their implementation.</li>

                                    <li >To provide You
                                        with news, special offers and general information about other goods,
                                        services and events which we offer that are similar to those that
                                        you have already purchased or enquired about unless You have opted
                                        not to receive such information.</li>

                                    <li >To manage Your
                                        requests: To attend and manage Your requests to Us.</li>

                                    <li >For business
                                        transfers: We may use Your information to evaluate or conduct a
                                        merger, divestiture, restructuring, reorganization, dissolution, or
                                        other sale or transfer of some or all of Our assets, whether as a
                                        going concern or as part of bankruptcy, liquidation, or similar
                                        proceeding, in which Personal Data held by Us about our Service
                                        users is among the assets transferred.</li>

                                    <li >For other
                                        purposes: We may use Your information for other purposes, such as
                                        data analysis, identifying usage trends, determining the
                                        effectiveness of our promotional campaigns and to evaluate and
                                        improve our Service, products, services, marketing and your
                                        experience.</li>
                                </ul>

                                <p className="underline mt-4">We may share Your
                                    personal information in the following situations:</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >With Service
                                        Providers: We may share Your personal information with Service
                                        Providers to monitor and analyze the use of our Service, to contact
                                        You.</li>
                                    <li >For business
                                        transfers: We may share or transfer Your personal information in
                                        connection with, or during negotiations of, any merger, sale of
                                        Company assets, financing, or acquisition of all or a portion of Our
                                        business to another company.</li>
                                    <li >With
                                        Affiliates: We may share Your information with Our affiliates, in
                                        which case we will require those affiliates to honor this Privacy
                                        Policy. Affiliates include Our parent company and any other
                                        subsidiaries, joint venture partners or other companies that We
                                        control or that are under common control with Us.</li>
                                    <li >With business
                                        partners: We may share Your information with Our business partners
                                        to offer You certain products, services or promotions.</li>
                                    <li >With other
                                        users: when You share personal information or otherwise interact in
                                        the public areas with other users, such information may be viewed by
                                        all users and may be publicly distributed outside. If You interact
                                        with other users or register through a Third-Party Social Media
                                        Service, Your contacts on the Third-Party Social Media Service may
                                        see Your name, profile, pictures and description of Your activity.
                                        Similarly, other users will be able to view descriptions of Your
                                        activity, communicate with You and view Your profile.</li>
                                    <li >With Your
                                        consent: We may disclose Your personal information for any other
                                        purpose with Your consent.</li>
                                </ul>


                                <h2 className="text-blue-600 mt-4">Retention of Your Personal Data</h2>

                                <p className="mt-2">The Company will
                                    retain Your Personal Data only for as long as is necessary for the
                                    purposes set out in this Privacy Policy. We will retain and use Your
                                    Personal Data to the extent necessary to comply with our legal
                                    obligations (for example, if we are required to retain your data to
                                    comply with applicable laws), resolve disputes, and enforce our legal
                                    agreements and policies.</p>

                                <p className="mt-2">The Company will
                                    also retain Usage Data for internal analysis purposes. Usage Data is
                                    generally retained for a shorter period of time, except when this
                                    data is used to strengthen the security or to improve the
                                    functionality of Our Service, or We are legally obligated to retain
                                    this data for longer time periods.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Transfer of Your Personal Data</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Your information,
                                    including Personal Data, is processed at the Company's operating
                                    offices and in any other places where the parties involved in the
                                    processing are located. It means that this information may be
                                    transferred to — and maintained on — computers located outside of
                                    Your state, province, country or other governmental jurisdiction
                                    where the data protection laws may differ than those from Your
                                    jurisdiction.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Your consent to this
                                    Privacy Policy followed by Your submission of such information
                                    represents Your agreement to that transfer.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">The Company will
                                    take all steps reasonably necessary to ensure that Your data is
                                    treated securely and in accordance with this Privacy Policy and no
                                    transfer of Your Personal Data will take place to an organization or
                                    a country unless there are adequate controls in place including the
                                    security of Your data and other personal information.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Disclosure of Your Personal Data</h2>
                                <h3 className="text-blue-600">Business Transactions</h3>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">If the Company is
                                    involved in a merger, acquisition or asset sale, Your Personal Data
                                    may be transferred. We will provide notice before Your Personal Data
                                    is transferred and becomes subject to a different Privacy Policy.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h3 className="text-blue-600">Law enforcement</h3>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Under certain
                                    circumstances, the Company may be required to disclose Your Personal
                                    Data if required to do so by law or in response to valid requests by
                                    public authorities (e.g. a court or a government agency).</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h3 className="text-blue-600">Other legal requirements</h3>

                                <p className="mt-2">The Company may
                                    disclose Your Personal Data in the good faith belief that such action
                                    is necessary to:</p>

                                <ul className='list-disc list-inside pl-6 space-y-2 mt-2'>
                                    <li >Comply with a
                                        legal obligation</li>
                                    <li >Protect and
                                        defend the rights or property of the Company</li>
                                    <li >Prevent or
                                        investigate possible wrongdoing in connection with the Service</li>
                                    <li >Protect the
                                        personal safety of Users of the Service or the public</li>
                                    <li >Protect against
                                        legal liability</li>
                                </ul>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Security of Your Personal Data</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">The security of Your
                                    Personal Data is important to Us, but remember that no method of
                                    transmission over the Internet, or method of electronic storage is
                                    100% secure. While We strive to use commercially acceptable means to
                                    protect Your Personal Data, We cannot guarantee its absolute
                                    security.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Children's Privacy</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Our Service does not
                                    address anyone under the age of 13. We do not knowingly collect
                                    personally identifiable information from anyone under the age of 13.
                                    If You are a parent or guardian and You are aware that Your child has
                                    provided Us with Personal Data, please contact Us. If We become aware
                                    that We have collected Personal Data from anyone under the age of 13
                                    without verification of parental consent, We take steps to remove
                                    that information from Our servers.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">If We need to rely
                                    on consent as a legal basis for processing Your information and Your
                                    country requires consent from a parent, We may require Your parent's
                                    consent before We collect and use that information.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Links to Other Websites</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">Our Service may
                                    contain links to other websites that are not operated by Us. If You
                                    click on a third party link, You will be directed to that third
                                    party's site. We strongly advise You to review the Privacy Policy of
                                    every site You visit.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">We have no control
                                    over and assume no responsibility for the content, privacy policies
                                    or practices of any third party sites or services.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Changes to this Privacy Policy</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">We may update Our
                                    Privacy Policy from time to time. We will notify You of any changes
                                    by posting the new Privacy Policy on this page.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">We will let You know
                                    via email and/or a prominent notice on Our Service, prior to the
                                    change becoming effective and update the 'Last updated' date at the
                                    top of this Privacy Policy.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">You are advised to
                                    review this Privacy Policy periodically for any changes. Changes to
                                    this Privacy Policy are effective when they are posted on this page.</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <h2 className="text-blue-600">Contact Us</h2>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">If you have any
                                    questions about this Privacy Policy, You can contact us:</p>
                                <p className="line-height: 100%; margin-bottom: 0in"><br />

                                </p>
                                <p className="line-height: 100%; margin-bottom: 0in">By email:
                                    compliance@inkqubee.com</p>

                            </div>
                        </section>
                    </div>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PrivacyPolicyModal