import { message } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function SendMail() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const navigate=useNavigate()
    const [email,setEmail]=useState("")
    const [title,setTitle]=useState("")
    const [body,setBody]=useState("")

    const handleSend=(e)=>{
        e.preventDefault()
        console.log("content",email,title,body)
        message.success("Mail sent successfully")
    }
    return (
        <div
            className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
        >
            <div className={`flex flex-col justify-center items-center w-full max-w-7xl max-h-full h-full ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-11 mt-4 px-2`}>
                <form onSubmit={handleSend} className='flex flex-col justify-center items-center p-7 border w-1/2'>
                <h1 className='font-bold'>Send Your Mail</h1>
                    <div className='relative w-full mb-5'>
                        <input
                            type='email'
                            id='emailAddress'
                            name='emailAddress'
                            className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 mt-2  border-gray-400`}
                            placeholder='Enter Receiver Mail ID'
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                        <label
                            htmlFor='email'
                            className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            To
                        </label>
                    </div>
                    <div className='relative w-full mb-5'>
                        <input
                            type='text'
                            id='title'
                            name='title'
                            className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 mt-2  border-gray-400`}
                            placeholder='Enter Title'
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                        />
                        <label
                            htmlFor='title'
                            className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Title
                        </label>
                    </div>

                    <div className='relative w-full mb-5'>
                        <textarea
                            type='text'
                            id='body'
                            name='body'
                            className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 mt-2  border-gray-400`}
                            placeholder='Enter Your Mail' 
                            rows='5'
                            value={body}
                            onChange={(e)=>setBody(e.target.value)}
                        />
                        <label
                            htmlFor='body'
                            className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            Body
                        </label>
                    </div>
                    <div className='flex gap-4'>
                        <button type='button' className='btn' onClick={()=>navigate(-1)}>Back</button>
                        <button type='submit' className='btn' >Send</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SendMail