import { investment_url } from "../../utils/constant";
import Axios from "axios"

const token=localStorage.getItem('authToken')

export const investorRegister = (
    payload,
)=>{
//    console.log("testing invest")
    return Axios({
        method:"POST",
        url:`${investment_url}/investor`,
        body:JSON.stringify(),
        headers:{
        //    Accept:'multipart/form-data',
        //    'Content-Type': 'multipart/form-data',
        Authorization:`Bearer ${token}`
        },
        data:payload
        
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getAllInvestors = (query)=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/investor/filter?${query}`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getIndustry = ()=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/investor/industry-preferences`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getTypes = ()=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/investor/investment-types`,
        // headers:{
        //     Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}


export const getAllInvestor = ()=>{
    return Axios({
        method:"GET",
        url:`${investment_url}/investor`,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const investorProfile = (investor)=>{
    console.log("profile called")
    return Axios({
        method:"GET",
        url:`${investment_url}/investor/${investor}`,
        headers:{
            //    Accept:'multipart/form-data',
            //    'Content-Type': 'multipart/form-data',
            Authorization:`Bearer ${token}`
            },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}