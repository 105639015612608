import React from 'react'
import star from '../../assets/footerimg.svg'
import contact_home from '../../assets/contacthome.svg'

function LastSection({ fontSize,isDark }) {
  return (
    <>
      <div className={`flex flex-col md:flex-row w-full justify-between p-6 h-screen ${!isDark ? 'bg-blue-950' : 'dark-mode'}`}>
        <div className='justify-center items-start mt-5'>
          <h1 className='md:ml-5 text-white' style={{ fontSize: `${fontSize + 20}px` }}>We are a culture-first <br /> ​organisation</h1>
          <div className='ml-1 md:ml-6 mt-5'>
            <p className='text-white font-semibold' style={{ fontSize: `${fontSize + 12}px` }}>At our core, we prioritize our culture. We are committed to ​nurturing our team, fostering strong internal and customer ​relationships, and consistently delivering work that reflects our ​pride.</p>
          </div>
        </div>
        <div>
          <img src={star} alt='organization' />
        </div>
      </div>
      <div className={`flex flex-col md:flex-row w-full justify-between p-8 h-screen ${!isDark ? 'bg-blue-950' : 'dark-mode'}`}>
        <div className='flex flex-col'>
          <h1 className='text-6xl ml-5 text-white'>Start the conversation</h1>
          <p className='text-white font-semibold ml-6 mt-5' style={{ fontSize: `${fontSize + 10}px` }}>Send us a message and let's talk about it.</p>
          <div className='flex flex-col '>
          <p className='text-white text-xl ml-5 mt-3'>Contact Us</p>
          <div className='flex flex-col gap-9 px-6 mt-3 md:mt-2'>
            <div className='bg-gradient-to-r max-w-96 from-purple-500 to-blue-400 p-3 rounded-lg'>
              <h1 className='text-xl font-semibold'>Phone</h1>
              <p className='text-xl mt-2'>+91 83100 00320</p>
            </div>
            <div className='bg-gradient-to-r max-w-96 from-blue-500 to-purple-700 p-3 rounded-lg '>
              <h1 className='text-xl font-semibold'>Email</h1>
              <p className='text-xl mt-2'>connect@inkqubee.com</p>
            </div>
          </div>
        </div>
        </div>
        <div>
          <img src={contact_home} alt='contact' />
        </div>
      </div>
      
    </>
  )
}

export default LastSection
