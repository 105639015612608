import { useFormik } from "formik"
import { investmentRegister } from "../../validation";


export const useInvestmentForm = (handleSubmit) => {
    return useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            addressLine1: "",
            addressLine2: "",
            pinCode: "",
            country:"",
            state:"",
            city:"",
            countryCode:"+1",
            phone: "",
            panNumber: "",
            panDocument:null,
            email: "",
            idProofNumber:"",
            idProofDocument:null,
            incomeProof: null,
            profile:"",
            description:"",
            investmentCapacity: "",
            locationPreference:[],
            typesOfInvestment: "",
            industryPreference: []
        },
        validationSchema: investmentRegister,
        onSubmit: values => {
            // console.log("Formik Values Before Submit:", values);
            handleSubmit(values)
        },
    })
}