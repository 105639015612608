import React from 'react'
import { IoIosBookmark } from "react-icons/io";
import { FiActivity } from "react-icons/fi";
import { AiFillMessage } from "react-icons/ai";

function DshboardActions({handleActivity,handleBookMark,openActivity,openBookmarks}) {
    return (
        <div className='flex gap-1 w-full justify-between mt-2'>
            <button onClick={() => handleBookMark(true)} className={`flex ${openBookmarks ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'} justify-center items-center p-1 w-full text-lg text-center`}><IoIosBookmark className='mt-2' size={25} /><span className='mt-2'>Book marks</span></button>
            <button onClick={() => handleActivity(true)} className={`flex ${openActivity ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'} justify-center items-center p-1 w-full text-lg text-center`}><AiFillMessage className='mt-2 me-1' size={25}  /><span className='mt-2'>Connected Profiles</span></button>
        </div>
    )
}

export default DshboardActions