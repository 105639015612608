import React from 'react'
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { getRefreshToken, userLogout } from '../../helper/auth/authRequestCall';
import { jwtDecode } from 'jwt-decode';

function TokenRefresh({onClose,open,message,path}) {
    const token = localStorage.getItem('authToken') 
    const decoded =token && jwtDecode(token)
    const handleRefresh=()=>{
        getRefreshToken()
        .then((res)=>{
            if(res.status===200){
                onclose()
            }
        })
        .catch((err)=>console.error("error",err))
    }
    const handleLogout = () => {
        // dispatch(authenticated())
        userLogout(decoded.username)
        .then((res)=>{
            if(res.status===200){
                window.location.href='/'
                localStorage.removeItem('authToken')
                message.success("logout success..")
            }else{
                message.error("Logout failed")
            }
        })
        .catch((err)=>console.error("error occurd",err))
    }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogContent style={{ textAlign: 'center', padding: '2rem' }}>
     
      <Typography variant="h6" style={{ marginTop: '1rem' }}>
        Session Expired
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center', paddingBottom: '1rem' }}>
    <Button onClick={handleLogout}   style={{color:'red'}}>
        Logout
      </Button>
      <Button  onClick={handleRefresh} variant="contained" color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default TokenRefresh