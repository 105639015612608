import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import userAvatar from "../../../../assets/userAvatar3.jpg"
import { MdArrowOutward } from "react-icons/md";

function SingleInvestor() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [openVerify, setOpenVerify] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)

    const [comments,setComments]=useState("")
    const [selectedValue, setSelectedValue] = useState("");

    const handleCheckboxChange = (value) => {
        setSelectedValue((prevValue) => (prevValue === value ? "" : value));
    }

    const handleOpenVerify = () => {
        setOpenVerify(true)
        setOpenBlock(false)
    }

    const handleOpenBlock = () => {
        setOpenBlock(true)
        setOpenVerify(false)
    }

    const handleBlock = (e) => {
        e.preventDefault()
        console.log("blocked")
    }
    const handleVerify = (e) => {
        e.preventDefault()
        console.log("verified")
    }
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} items-center rounded-lg py-2 mt-14 px-2`}>
                <div className="w-full  relative space-y-6 rounded-lg px-6 py-6">
                    <div>
                        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                            <img
                                src={userAvatar}
                                alt="User Avatar"
                                className="w-24 h-24 rounded-full border-4 border-white shadow-md"
                            />
                        </div>
                    </div>
                    <div>

                        <div className="flex flex-col justify-start items-start bg-blue-50 p-6 rounded-lg shadow-md text-gray-700 space-y-4">
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">First Name:</label>
                                <span className="ml-4">Jhon</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Last Name:</label>
                                <span className="ml-4">maths</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Legal Entity Name:</label>
                                <span className="ml-4">abc limited</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Brand Name:</label>
                                <span className="ml-4">My brand</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Official MailID:</label>
                                <span className="ml-4">mail@mail.com</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Phone Number:</label>
                                <span className="ml-4">9098989990</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Industries:</label>
                                <span className="ml-4">IT Consulting</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Address Line 1:</label>
                                <span className="ml-4">addressLine1</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Address Line 2:</label>
                                <span className="ml-4">addressLine2</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Pincode:</label>
                                <span className="ml-4">642005</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">ID Proof:</label>
                                <span className="ml-4">90998AHb38</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">PAN:</label>
                                <span className="ml-4">12347667</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">State and City:</label>
                                <span className="ml-4">Bengaluru,Karnataka</span>
                            </div>
                        </div>

                        <div className="flex flex-col mt-3 justify-center items-center bg-blue-50 p-6 rounded-lg shadow-md text-gray-700 space-y-4">
                            <h1 className='font-bold text-blue-700 text-center'>Profile</h1>
                            <p>Hi, I’m Alex! A passionate software developer with a knack for building robust and user-friendly applications. With expertise in MERN stack development, I craft seamless web solutions that are not just functional but also visually appealing.

                                I’m always eager to learn and stay updated with the latest technologies. From developing scalable backend APIs to designing intuitive frontend interfaces, I aim to deliver value in every project I undertake.</p>
                        </div>

                        <div className="flex flex-col mt-3 justify-center items-center bg-blue-50 p-6 rounded-lg shadow-md text-gray-700 space-y-4">
                            <h1 className='font-bold text-blue-700 text-center'>Prior Investments</h1>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Company:</label>
                                <span className="ml-4">Abc privte Limited</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Amount:</label>
                                <span className="ml-4">1000-5000</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Documet:</label>
                                <span className="ml-4">null</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex justify-between ml-6 me-5'>
                    <button type='button' className='bg-red-600 text-white p-2 rounded' onClick={() => handleOpenBlock()}>Block</button>
                    <button type='button' className='bg-blue-600 text-white p-2 rounded' onClick={() => handleOpenVerify()}>Verify and Publish</button>
                </div>

                {openBlock && <div className='flex  justify-center items-center '>
                    <form className='flex flex-col w-full max-w-lg border p-5' onSubmit={() => handleBlock()}>
                        <h1 className='text-center font-bold p-2'>Block</h1>
                        <textarea
                            placeholder='Add Comment'
                            className='border border-gray-400 focus: outline-none p-2'
                        />
                        <div className='flex justify-center items-center gap-2 p-2'>
                            <button type='button' onClick={() => setOpenBlock(false)} className='text-red-600 font-semibold'>Cancel</button>
                            <button type='submit' className='bg-blue-500 text-white p-1 rounded'>Confirm</button>
                        </div>
                    </form>
                </div>}
                {openVerify && <div className='flex  justify-center items-center '>
                    <form className='flex flex-col w-full max-w-3xl border p-5 mt-3' onSubmit={() => handleVerify()}>
                        <h1 className='text-center font-bold p-2'>Verify</h1>
                        <textarea
                            placeholder='Add Comment'
                            value={comments}
                            onChange={(e)=>setComments(e.target.value)}
                            className='border border-gray-400 focus: outline-none p-2'
                        />
                        <div className='flex gap-2 p-3 justify-center items-center'>
                            <div className="flex items-center space-x-2">
                                <label className="flex items-center space-x-2 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedValue==="Trust"}
                                        onChange={() => handleCheckboxChange("Trust")}
                                        className="hidden"
                                    />
                                    <div
                                        className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm ${selectedValue === "Trust" 
                                            ? "bg-blue-500 border-blue-500"
                                            : "bg-white border-gray-400"
                                            }`}
                                    >
                                        {selectedValue === "Trust" && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-white"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <span className="text-gray-700">Trust Shield</span>
                                </label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <label className="flex items-center space-x-2 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedValue==="Credibility"}
                                        onChange={() => handleCheckboxChange("Credibility")}
                                        className="hidden"
                                    />
                                    <div
                                        className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm ${selectedValue==="Credibility"
                                            ? "bg-blue-500 border-blue-500"
                                            : "bg-white border-gray-400"
                                            }`}
                                    >
                                        {selectedValue==="Credibility" && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-white"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <span className="text-gray-700">Credibility Badge</span>
                                </label>
                            </div>

                            <div className="flex items-center space-x-2">
                                <label className="flex items-center space-x-2 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedValue==="Gold"}
                                        onChange={() => handleCheckboxChange("Gold")}
                                        className="hidden"
                                    />
                                    <div
                                        className={`w-5 h-5 flex items-center justify-center border-2 rounded-sm ${selectedValue==="Gold"
                                                ? "bg-blue-500 border-blue-500"
                                                : "bg-white border-gray-400"
                                            }`}
                                    >
                                        {selectedValue==="Gold" && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-white"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <span className="text-gray-700">Gold Investor</span>
                                </label>
                            </div>
                        </div>
                        <div className='flex justify-center items-center gap-2 p-2'>
                            <button type='button' onClick={() => setOpenVerify(false)} className='text-red-600 font-semibold'>Cancel</button>
                            <button type='submit' className='bg-blue-500 text-white p-1 rounded'>Confirm</button>
                        </div>
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default SingleInvestor