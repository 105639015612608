import React from 'react'
import { TiTick } from "react-icons/ti";

function FreePlan() {
    return (
        <div>
                <ul >
                    <li className='flex text-gray-600'><TiTick className='mt-1' color='green' />Post Projects</li>
                </ul>
        </div>
    )
}

export default FreePlan