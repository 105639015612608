import React from 'react'
import { useSelector } from 'react-redux'

function Reviews() {
    const {isDark}=useSelector((state)=>state.displaySettingsState)
  return (
    <div className={`flex flex-col justify-center items-center min-h-screen w-full ${!isDark? 'bg-white':'dark-mode'}`}>
        <h1>Reviews</h1>
    </div>
  )
}

export default Reviews