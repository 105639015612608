import React from 'react'

function FilterProfiles({handleOrgOpen,handleInvestorOpen,handleProjectOpen,openInvestors,openProject,openOrg}) {
  return (
    <div className='flex w-full p-2'>
        <ul className='flex p-2 w-full'>
           <button onClick={()=>handleOrgOpen()} className={`${openOrg ? 'bg-blue-600 text-white':'bg-white text-black border'} p-2 rounded-full w-full text-center  `}>Organization</button> 
           <button onClick={()=>handleInvestorOpen()} className={`${openInvestors ? 'bg-blue-600 text-white':'bg-white text-black border'} p-2 rounded-full w-full text-center  ml-1`}>Investors</button> 
           <button onClick={()=>handleProjectOpen()} className={`${openProject ? 'bg-blue-600 text-white':'bg-white text-black border'} p-2 rounded-full w-full text-center  ml-1`}>Projects</button> 
        </ul>
    </div>
  )
}

export default FilterProfiles