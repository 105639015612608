import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RiDeleteBinFill } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { Link } from 'react-router-dom';
import { getPortfolio, getUsersPortfolio } from '../../../../helper/serviceProvider/serviceProviderRequestCall';
import { GoUnverified } from "react-icons/go";
import PortfolioDetail from '../../../../utils/Modal/PortfolioDetail';
import { jwtDecode } from 'jwt-decode';


function Myportfolios() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [portfolios, setPortfolios] = useState([])
    const [portfolioDetail, setPortfolioDetail] = useState(null)
    const [openDetail, setOpenDetail] = useState(false)
    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId

    const fetchPortfolios = () => {
        getUsersPortfolio(userId)
            .then((res) => {
                // console.log("data",res)
                if (res.status === 200) {
                    setPortfolios(res.data)
                }
            })
            .catch((err) => {
                console.error("error", err)
            })
    }

    const handleOpenDeatail = (id) => {
        getPortfolio(id)
            .then((res) => {
                if (res.status === 200) {
                    setPortfolioDetail(res.data)
                    setOpenDetail(true)
                }
            })
            .catch((err) => console.error("error occurd:", err))
    }

    useEffect(() => {
        fetchPortfolios()
    }, [])
    console.log("portfolios", portfolios.length)
    return (
        <div className={`flex flex-col min-h-screen w-full ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full py-11 px-7 rounded space-y-4`}>
                {portfolios.length === 0 && <h1 className='text-center mt-6 text-2xl text-gray-500'>Portfolio is empty</h1>}
                {portfolios?.map((items, index) => (
                    <div key={index} className={` ${isDark ? 'bg-black' : 'bg-white'} border h-80 border-gray-300 shadow-xl w-full rounded-lg `}>
                        <div className='flex justify-between p-2 border-b border-gray-200'>
                            <span className='flex'>
                                <h1 className='text-xl font-semibold'>{items.name}</h1>
                                {
                                    items.active ?
                                        <Tooltip placement='top' title='Active'>
                                            <span className='cursor-pointer'><MdVerified className='ml-2' size={30} color='green' /></span>
                                        </Tooltip> :
                                        <Tooltip placement='top' title='Not active'>
                                            <span className='cursor-pointer'><GoUnverified color='yellow' size={30} className='ml-2' /></span>
                                        </Tooltip>
                                }
                            </span>
                            {/* <h1 className={` ${isDark ? 'text-white' : 'text-black'} text-xl font-bold`}></h1> */}
                            <div className='flex mt-1'>

                                <Tooltip placement='top' title="View details">
                                    <button className='cursor-pointer me-2' onClick={() => handleOpenDeatail(items.id)}><FaEye size={35} className='hover:text-yellow-300' /></button>
                                </Tooltip>

                                <Tooltip placement='top' title="Update">
                                    <Link to={`/service-provider/update-portfolio/${items.id}`} className='cursor-pointer me-2'><FaRegEdit size={35} className='hover:text-blue-600' /></Link>
                                </Tooltip>

                                {/* <Tooltip placement='top' title="Delete">
                                    <span className='cursor-pointer '><RiDeleteBinFill size={35} className='hover:text-red-600' /></span>
                                </Tooltip> */}
                            </div>
                        </div>

                        <div className='flex justify-between p-3'>
                            <div>
                                <span className='text-lg font-bold'>{items.services}</span>
                                <p className='text-gray-500'>
                                    {/* {items.description} */}
                                    {items.description && items.description.length > 150
                                        ? (
                                            <>
                                                {items.description.substring(0, 150)}....
                                                <button onClick={() => handleOpenDeatail(items.id)} className='text-blue-500'>
                                                    View more
                                                </button>
                                            </>
                                        )
                                        : items.description}
                                </p>
                            </div>
                            {/* <div className='rounded-md'>
                             <img src='https://img.freepik.com/premium-vector/free-vector-company-logo-set-design-ideas_490098-118.jpg?w=1380' alt='portfolio-images' className='h-60 w-60'/>
                         </div> */}
                        </div>
                        {openDetail && portfolioDetail && (<PortfolioDetail
                            openDetail={openDetail}
                            handleToClose={() => setOpenDetail(!openDetail)}
                            description={portfolioDetail.description}
                            service={portfolioDetail.services}
                            name={portfolioDetail.name}
                            active={portfolioDetail.active}
                        />)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Myportfolios