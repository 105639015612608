import React from 'react'
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

function SuccessModal({onClose,open,message,path,buttonName}) {
    const navigate=useNavigate()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogContent style={{ textAlign: 'center', padding: '2rem' }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={80}
        height={80}
        borderRadius="50%"
        bgcolor="#4caf50" // Success color
        color="white"
        margin="auto"
      >
        <CheckCircleOutlineIcon style={{ fontSize: 48 }} />
      </Box>
      <Typography variant="h6" style={{ marginTop: '1rem' }}>
        Success
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center', paddingBottom: '1rem' }}>
      <Button  onClick={()=>navigate(path)} variant="contained" color="primary">
        {buttonName}
      </Button>
      {/* <Button onClick={onClose}  style={{color:'red'}}>
        close
      </Button> */}
    </DialogActions>
  </Dialog>
  )
}

export default SuccessModal