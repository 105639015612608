import  Axios  from "axios";
import { auth_url } from "../../utils/constant";

const token=localStorage.getItem('authToken')
const refreshToken=localStorage.getItem('refresh')

export const contactInkqubee=(customerName,companyName,mobileNumber,email,companyHeadquarter,requirement)=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/api/communication`,
        body:JSON.stringify(),
        data:{
            customerName,
            companyName,
            mobileNumber,
            email,
            companyHeadquarter,
            requirement
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const signinUser=(username,password)=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/auth/login`,
        body:JSON.stringify(),
        data:{
            username,
            password
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const signupUser=(payLoad)=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/auth/register`,
        body:JSON.stringify(),
        data:payLoad
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const forgotPassword=(emailId,newPassword)=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/auth/reset-password`,
        body:JSON.stringify(),
        data:{
            emailId,
            newPassword
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getUserProfile=(id)=>{
    return Axios({
        method:'GET',
        url:`${auth_url}/user/${id}`,
        // headers:{
        //     Accept:'application/json',o
        //     "Content-Type":"application/json",
        //     // Authorization:`Bearer ${token}`
        // }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err
    })
}

export const userLogout = (
    userName
)=>{
   
    return Axios({
        method:"PUT",
        url:`${auth_url}/auth/logout?username=${userName}`,
        // body:formData,
        headers:{
           Authorization:`Bearer ${token}`
        },
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getCompanyUsers=(id)=>{
    return Axios({
        method:'GET',
        url:`${auth_url}/user/company/${id}`,
        headers:{
            Accept:'application/json',
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err
    })
}

export const createUser=(username,firstName,lastName,companyId,isSuperAdmin)=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/user`,
        body:JSON.stringify(),
        data:{
            username,
            firstName,
            lastName,
            companyId,
            isSuperAdmin
        }
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}

export const getRefreshToken=()=>{
    return Axios({
        method:"POST",
        url:`${auth_url}/auth/refresh?refreshToken=${refreshToken}`,
    })
    .then((res)=>{
        return res;
    })
    .catch((err)=>{
        return err;
    })
}