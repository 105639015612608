import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { publicRoutes, private_routes, policy_page, admin_routes } from '../routes';
import NotFoundPage from '../views/pages/NotFoundPage';
//import { useLocation } from 'react-router-dom';
import PublicLayout from '../views/layout/PublicLayout';
import PrivateLayout from '../views/layout/PrivateLayout';
import AdminLayout from '../views/layout/AdminLayout';
import { userOrganization } from '../helper/organization/orgRequestCall';
import { jwtDecode } from 'jwt-decode';
import PrivateRoute from '../PrivateRoute';
import { service_providers } from '../utils/tempJson/_tempItems';
import TokenRefresh from '../utils/Modal/TokenRefresh';

function AppView() {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const [selectedItems, setSelectedItems] = useState([]);
  const [auth, setAuth] = useState(false)
  const [companies, setCompanies] = useState([])
  const token = localStorage.getItem('authToken') || ''
  const decoded = token && jwtDecode(token)
  const [openRefresh,setOpenRefresh]=useState(false)
  const userId = decoded.userId

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken"); // Replace with your storage logic

    if (!token) {
      return;
    }

    try {
      // Decode the JWT (you can use a library like jwt-decode for better handling)
      const tokenPayload = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      if (expirationTime < currentTime) {
        // setOpenRefresh(true)
      } else {
        // Set timeout for token expiration
        setTimeout(() => {
          setOpenRefresh(true)
          // alert("Your session has expired. Logging out...");
          // handleLogout();
        }, expirationTime - currentTime);
      }
    } catch (error) {
      console.error("Invalid token:", error);
      alert("Invalid session. Please log in again.");
      // handleLogout();
    }
  };

  useEffect(()=>{
     checkTokenExpiration()
  },[])

  const fetchCompany = () => {
    userOrganization(userId)
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data)
        }
      })
      .catch((err) => { console.error("Error", err) })
  }

  useEffect(() => {
    fetchCompany()
  }, [])
  const fetchToken = () => {
    const token = localStorage.getItem('authToken')
    if (token) {
      setAuth(true)
      // console.log("decoded token:",jwtDecode(token))
    } else {
      setAuth(false)
    }
  }

  useEffect(() => {
    fetchToken()
  }, [])
  return (
    <div>
      {/* <Suspense fallback={<h1 className='text-center'>loading...</h1>}></Suspense> */}
      <Routes>
        {publicRoutes.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              <PublicLayout auth={auth}>
                <routes.component />
              </PublicLayout>
            }
          />
        ))}

        {policy_page.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              <routes.component auth={auth} />
            }
          />
        ))}

        {auth && private_routes.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              // routes.verified ? (
              //   <PrivateRoute
              //     organizations={service_providers}
              //   >
              //     <PrivateLayout
              //       auth={auth}
              //       active={active}
              //       setActive={setActive}
              //       setSelectedItems={setSelectedItems}
              //       selectedItems={selectedItems}>
              //       <routes.component setSelectedItems={setSelectedItems}
              //         selectedItems={selectedItems} />
              //     </PrivateLayout>
              //   </PrivateRoute>
              // ) : (
                <PrivateLayout
                  auth={auth}
                  active={active}
                  setActive={setActive}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}>
                  <routes.component setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems} />
                </PrivateLayout>
              // )
            }
          />
        ))}

        {admin_routes.map((routes, index) => (
          <Route
            key={index}
            path={routes.path}
            exact={routes.exact}
            element={
              <AdminLayout
                auth={auth}
                active={active}
                setActive={setActive}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}>
                <routes.component setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems} />
              </AdminLayout>
            }
          />
        ))}
        <Route path="*" element={
          <PublicLayout>
            <NotFoundPage />
          </PublicLayout>
        } />
      </Routes>
      <TokenRefresh open={openRefresh} onClose={()=>setOpenRefresh(false)} message={"Your login session expired! Do you want to extend?"}/>
    </div>
  );
}
export default AppView;
