import React from 'react'
import { useSelector } from 'react-redux'

function AdminDashboard() {
  const { isDark } = useSelector((state) => state.displaySettingsState)

  return (
    <div
      className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
    >
      <div className={`flex flex-col w-full max-w-7xl max-h-full h-full ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-11 mt-4 px-2`}>
        <div className='flex p-4 gap-3'>
          <div className='flex flex-col text-white justify-center items-center bg-blue-800 p-4 rounded-md w-96 h-24'>
            <h1>Organizations</h1>
            <text className='text-center'>10</text>
          </div>
          <div className='flex flex-col text-white justify-center items-center bg-blue-800 p-4 rounded-md w-96 h-24'>
            <h1>Inbox</h1>
            <text className='text-center'>10</text>
          </div>
          <div className='flex flex-col text-white justify-center items-center bg-blue-800 p-4 rounded-md w-96 h-24'>
            <h1>Projects</h1>
            <text className='text-center'>10</text>
          </div>
        </div>
        <div className='h-96 bg-white border'>
          
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
