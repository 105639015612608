import React from 'react'
import avatar from '../../../../assets/userAvatar.jpg'
import { TiMessages } from "react-icons/ti";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosRemoveCircle } from "react-icons/io";

function Investor({ investors }) {
    return (
        <div>
            {investors?.map((items, index) => (
                <div key={index} className='flex gap-5 mt-2 border p-3 '>
                    <img
                        src={avatar}
                        alt='investor'
                        className='h-14 w-14 rounded-full'
                    />
                    <div className='flex flex-col mt-2'>
                        <span className='text-gray-700'>{items.firstName}</span>
                        <span className='text-gray-400'>{items.lastName}</span>
                    </div>
                    <div className='flex mt-6  justify-between ml-auto'>
                        <button className='flex bg-blue-800 text-white px-4 py-1 rounded mb-2 me-2 h-8'>Message<TiMessages className='mt-1 ml-1' /></button>
                        <button className='flex bg-blue-800 text-white px-4 py-1 rounded h-8'>Remove<IoIosRemoveCircle className='mt-1 ml-1' /></button>
                    </div>

                </div>
            ))}
        </div>
    )
}

export default Investor