import React from 'react'
import { ArrowRight, Users, Briefcase, PieChart, Building2, CheckCircle, ArrowUpRight, Star, Quote } from 'lucide-react';


function SixthSection({isDark}) {
    const testimonials = [
        {
          name: "Sunitha N",
          position: "CEO ",
          company: "CSDKart.co",
          // image: "/api/placeholder/64/64",
          text: "Within just 3 months of joining the platform, we secured two major projects and found an angel investor. The verification system gives us confidence in every connection we make.",
          rating: 5
        },
        {
          name: "Jeevan H",
          position: "Founder",
          company: " CS4learn.in",
          // image: "/api/placeholder/64/64",
          text: "As an investor, the platform has streamlined our deal flow significantly. The quality of startups and the detailed verification process saves us valuable time in due diligence.",
          rating: 5
        },
        {
          name: "Amit Patel",
          position: "Managing Director",
          company: "Growth Ventures",
          image: "/api/placeholder/64/64",
          text: "The platform's M&A marketplace helped us find the perfect acquisition target. The structured process and professional support made the entire transaction smooth.",
          rating: 5
        }
      ];
  return (
    <div className={`${isDark ? 'dark-mode':'bg-gray-200'} py-16`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">Success Stories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className={`bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:-translate-y-1`}
            >
              <div className="flex items-start mb-4">
                {/* <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full mr-4"
                /> */}
                <div>
                  <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                  <p className="text-gray-600">{testimonial.position}</p>
                  <p className="text-blue-600 text-sm">{testimonial.company}</p>
                </div>
              </div>
              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
                ))}
              </div>
              <Quote className="h-8 w-8 text-blue-200 mb-2" />
              <p className="text-gray-700">{testimonial.text}</p>
            </div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <button className="text-blue-600 font-semibold flex items-center mx-auto hover:text-blue-700">
            View More Success Stories <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SixthSection