import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdAssignmentAdd } from "react-icons/md";
import ActivityChart from '../../../components/private/ActivityChart';
import verificationImg from '../../../assets/verification.png'
import { message, Tooltip } from 'antd'
import { HiEye } from "react-icons/hi2";
import { Base_url } from '../../../utils/constant';
import { Link } from 'react-router-dom';
import { getCompanyLogo, unverifiedCompanies, userOrganization } from '../../../helper/organization/orgRequestCall';
import { jwtDecode } from 'jwt-decode'
import { setUserDetails } from '../../../store/slices/authSlice';
import Slider from 'react-slick';
import { CgOrganisation } from "react-icons/cg";
import orgLogo from '../../../assets/logo/defaultCompanyLogo.png'
import VerifiedCompanies from '../../../components/private/organization/VerifiedCompanies';
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import NotVerifiedCompanies from '../../../components/private/organization/NotVerifiedCompanies';
import { IoIosBookmark } from "react-icons/io";
import { FiActivity } from "react-icons/fi";
import DshboardActions from '../../../components/private/dashboard/DshboardActions';
import BookMarkedProfiles from '../../../components/private/dashboard/BookMarkedProfiles';
import ResponseModal from '../../../utils/Modal/ResponseModal';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import FilterProfiles from '../../../components/private/dashboard/FilterProfiles';
import Organization from '../../../components/private/dashboard/profileTypes/Organization';
import { service_providers, temp_investors, temp_Projects } from '../../../utils/tempJson/_tempItems';
import Projects from '../../../components/private/dashboard/profileTypes/Projects';
import Investor from '../../../components/private/dashboard/profileTypes/Investor';
import { switchInvestor, switchNotVerified, swithchVerified } from '../../../store/slices/displaySettingsSlice';
import { getAllInvestor } from '../../../helper/investors/investorRequestCall';
import userAvatar from '../../../assets/userAvatar2.jpg'


function Dashboard() {
  const { isDark, verifiedCompanies, notVerifiedCompanies,investor } = useSelector((state) => state.displaySettingsState)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [warning, setWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState("")
  const [companyLogos, setCompanyLogos] = useState([])
  const [logoMap, setLogoMap] = useState({});
  const [openActivity, setOpenActivity] = useState(false)
  const [openBookmarks, setOpenBookmarks] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [openOrg, setOpenOrg] = useState(true)
  const [openProject, setOpenProject] = useState(false)
  const [openInvestors, setOpenInvestor] = useState(false)
  const [unverified, setUnverified] = useState([])

  const [investors, setInvestors] = useState([])
  // const filterVerify = companyLogos && companyLogos.data.filter(company => company.isVerified)
  // const filterNotVerify = companyLogos && companyLogos.data.filter(company => company.isVerified)
  const token = localStorage.getItem('authToken')
  const decoded = jwtDecode(token)
  const userId = decoded.userId


  const fetchInvestor=()=>{
    getAllInvestor()
    .then((res)=>{
      if(res.status===200){
        setInvestors(res.data.data)
      }
    })
  }

  useEffect(()=>{
      fetchInvestor()
  },[])
  const openProfile = (id) => {
    navigate('/organization/profile', { state: { org_id: id } })
  }

  const openInvestorProfile = (id) => {
    navigate('/investor/profile', { state: { investor_id: id } })
  }



  const handleOrgOpen = () => {
    setOpenOrg(true)
    setOpenInvestor(false)
    setOpenProject(false)
  }

  const handleProjectOpen = () => {
    setOpenProject(true)
    setOpenOrg(false)
    setOpenInvestor(false)
  }

  const handleInvestorOpen = () => {
    setOpenInvestor(true)
    setOpenOrg(false)
    setOpenProject(false)
  }
  const handleServiceProvider = (id, verified) => {
    navigate('/service-provider/option', { state: { org_id: id } })
  }

  const handleProject = (id, verified) => {

    navigate('/clients/post-project', { state: { org_id: id } })
  }

  const handleSellCompany = (verified) => {
    if (verified) {
      navigate('/company/sell')
    } else {
      setWarning(true)
      setWarningMessage("Your organization is under verification! Once it's done, you will be able to access this feature.")
    }
  }

  const handleServiceProviderUnverified = (id, verified) => {
    setWarning(true)
    setWarningMessage("Your organization is under verification! Once it's done, you will be able to access this feature.")
  }

  const handleProjectUnverified = (id, verified) => {
    setWarning(true)
    setWarningMessage("Your organization is under verification! Once it's done, you will be able to access this feature.")
  }

  const handleSellCompanyUnverified = (verified) => {
    setWarning(true)
    setWarningMessage("Your organization is under verification! Once it's done, you will be able to access this feature.")
  }



  const fetchUnverified = () => {
    unverifiedCompanies(userId)
      // console.log(unverifiedCompanies(userId))
      .then((res) => {
        console.log("res", res)
        if (res.status === 200) {
          setUnverified(res.data.data)
        } else {
          console.log("error")
        }
      })
      .catch((err) => {
        console.error("error", err)
      })
  }




  const handleBookMark = () => {
    setOpenBookmarks(true)
    setOpenActivity(false)
  }

  const handleActivity = () => {
    setOpenActivity(true)
    setOpenBookmarks(false)
  }
  const fetchCompany = () => {
    setLoading(true)
    userOrganization(userId)
      .then((res) => {
        if (res.status === 200) {
          setCompanyLogos(res.data.data)
        }
      })
      .catch((err) => { console.error("Error", err) })
      .finally(() => setLoading(false))
  }

  const fetchLogo = async (companyId) => {
    await getCompanyLogo(companyId)
      .then((res) => {
        if (res.status === 200) {
          const blob = res.data
          const imageUrl = URL.createObjectURL(blob)
          setLogoMap((prev) => ({ ...prev, [companyId]: imageUrl }));
        }
      })
      .catch((err) => {
        console.error("error:", err)
      })
  }
  useEffect(() => {
    fetchUnverified()
    fetchCompany()
  }, [])

  useEffect(() => {
    companyLogos.forEach((company) => {
      if (!logoMap[company.id]) {
        fetchLogo(company.companyId);
      }
    });
  }, [companyLogos]);



  const openNotVerified = localStorage.setItem("notVerified", true);

  return (
    <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10 px-3`}>
      <div className={`flex  ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4`}>
        <div className='w-full mt-2 lg:w-auto shadow-lg border '>
          <div className='flex mt-2 '>
            <button className='flex bg-blue-700 p-2 me-4 mt-6 ml-2 rounded-lg text-white hover:bg-blue-500' onClick={() => navigate('/investment/register')}>Register as Investor <MdAssignmentAdd className='mt-1 ml-2' /></button>
            <button className='flex bg-blue-700 p-2 me-4 mt-6 rounded-lg text-white hover:bg-blue-500' onClick={() => navigate('/register-company/company-detail')}>Register Organization <MdAssignmentAdd className='mt-1 ml-2' /></button>
          </div>
          <div className='flex justify-between  mt-4'>
            <h1 className='flex text-xl ml-3 p-2 me-4 mt-1 text-gray-600'>Your Profiles</h1>
          </div>
          <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
            <li
              onClick={() => { dispatch(switchNotVerified()) }}
              className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
            >
              Under Verification
              {notVerifiedCompanies ?
                <FaMinus className='cursor-pointer'
                  onClick={() => { dispatch(switchNotVerified()) }}
                /> :
                <FaPlus
                  className='cursor-pointer'
                  onClick={() => { dispatch(switchNotVerified()) }}
                />}
            </li>
            {notVerifiedCompanies && <div>
              <NotVerifiedCompanies isLoading={isLoading} unverified={unverified} handleServiceProvider={handleServiceProviderUnverified} handleProject={handleProjectUnverified} handleSellCompany={handleSellCompanyUnverified} openProfile={openProfile} />
            </div>}
          </div>
          <div className={`w-full mt-1 space-y-6 rounded-lg px-6 p-2`} >
            <li
              onClick={() => dispatch(swithchVerified())}
              className='flex justify-between text-xl  cursor-pointer bg-blue-800 p-2 text-white'
            >
              Verified Companies
              {verifiedCompanies ?
                <FaMinus className='cursor-pointer'
                  onClick={() => dispatch(swithchVerified())}
                /> :
                <FaPlus
                  className='cursor-pointer'
                  onClick={() => dispatch(swithchVerified())}
                />}
            </li>
            {verifiedCompanies && <div>
              <VerifiedCompanies companyLogos={companyLogos} logoMap={logoMap} handleServiceProvider={handleServiceProvider} handleProject={handleProject} handleSellCompany={handleSellCompany} openProfile={openProfile} />
            </div>}

          </div>

          <div className={`w-full mt-1 space-y-6 rounded-lg px-6 p-2`} >
            <li
              onClick={() => dispatch(switchInvestor())}
              className='flex justify-between text-xl  cursor-pointer bg-blue-800 p-2 text-white'
            >
              Investor Profile
              {investor ?
                <FaMinus className='cursor-pointer'
                  onClick={() => dispatch(switchInvestor())}
                /> :
                <FaPlus
                  className='cursor-pointer'
                  onClick={() => dispatch(switchInvestor())}
                />}
            </li>
            {investor && <div>
              <div className={`flex flex-col h-full company-scrollable-container hide-scrollbar overflow-auto  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 shadow-lg px-3 space-y-2`}>
                <div className={`w-full mt-1 max-w-5xl space-y-6 rounded-lg px-6 p-2`} >
                  <div className="flex flex-wrap gap-2 p-2">
                    {investors && investors.map((item) => (
                      <div className='flex w-full border p-2'>
                        <div className='flex flex-row justify-between gap-2 p-2' >

                          <img
                            src={userAvatar}
                            // src={item.logo}
                            // alt={item.legalEntityName}
                            // onClick={handleCompanyDetail}
                            onClick={() => openInvestorProfile(item.investorId)}
                            className={` rounded-full h-14 w-14 bg-white cursor-pointer`}
                          />

                          <h1 className='text-center text-gray-500 ml-5 mt-3'>{item.firstName}</h1>
                        </div>
                        
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>}

          </div>
        </div>
        <div className='flex flex-col w-full lg:w-full lg:max-w-full'>
          <DshboardActions handleActivity={handleActivity} handleBookMark={handleBookMark} openActivity={openActivity} openBookmarks={openBookmarks} />
          <FilterProfiles handleOrgOpen={handleOrgOpen} handleProjectOpen={handleProjectOpen} handleInvestorOpen={handleInvestorOpen} openInvestors={openInvestors} openProject={openProject} openOrg={openOrg} />
          <div className='p-1 h-full border'>
            {/* {openBookmarks && <div className='scrollable-container hide-scrollbar h-full'>
              {openOrg && <Organization service_providers={service_providers} />}
              {openProject && <Projects  projects_list={temp_Projects}/>}
              {openInvestors && <Investor  investors={temp_investors}/>}
            </div>} */}
          </div>
        </div>
        {/* <div className={`w-full max-w-7xl justify-center ${!isDark ? 'bg-slate-200' : 'bg-slate-500 '} rounded-lg h-80 px-6 py-2`}>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Activity</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
                {companyDetail?.charts && (
                  <ActivityChart items={companyDetail.charts} />
                )}
              </div>
            </div>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Chart</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <ResponseModal open={warning} message={warningMessage} onClose={() => setWarning(false)} resultColor={"#FFA500"} icon={<ReportProblemOutlinedIcon style={{ fontSize: 48 }} />} />
    </div>

  )
}

export default Dashboard