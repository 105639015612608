import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getCompanyPortfolios } from '../../../helper/serviceProvider/serviceProviderRequestCall';
import { RiDeleteBinFill } from "react-icons/ri";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { Tooltip } from 'recharts';
import { GoUnverified } from 'react-icons/go';
import { MdVerified } from 'react-icons/md';

function CompanyPortfolio({org_id}) {
    const { isDark } = useSelector((state) => state.displaySettingsState)
  const [allPortfololios, setPortfolios] = useState([])
  const [openDetail, setOpen] = useState(false);
  const [portfolioDetail,setPortfolioDetail]=useState(null)
  const navigate = useNavigate()

  const portfolioLength = allPortfololios && allPortfololios.length

  const fetchPortfolios =  () => {
    getCompanyPortfolios(org_id)
      .then((res) => {
        if (res.status === 200) {
          setPortfolios(res.data)
        }
      })
      .catch((err) => {
        console.error("error", err)
      })
  }

//   const handleViewPortflio=(id)=>{
//     getPortfolio(id)
//     .then((res)=>{
//         if(res.status === 200){
//             setPortfolioDetail(res.data)
//             setOpen(true)
//         }
//     })
//     .catch((err)=>console.error("error occurd:",err))
//   }

  useEffect(() => {
    fetchPortfolios()
  }, [])
  return (
    <Fragment>
      <div className={`flex flex-col h-screen overflow-auto  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 px-3 space-y-2`}>
        {allPortfololios && allPortfololios.map((items) => (
          <div key={items.id} className={` ${isDark ? 'bg-black' : 'bg-white'} border h-80 border-gray-300 shadow-lg w-full rounded-lg`}>
            <div className='flex justify-between p-2 border-b border-gray-200'>
              <span className='flex'>
              <h1 className='text-xl font-semibold'>{items.name}</h1>
              {
                items.active ?
                  <Tooltip placement='top' title='Active'>
                   <span className='cursor-pointer'><MdVerified className='ml-2' size={30} color='green' /></span>
                  </Tooltip> :
                  <Tooltip placement='top' title='Not active'>
                   <span className='cursor-pointer'><GoUnverified color='yellow' size={30} className='ml-2' /></span> 
                  </Tooltip>
              }
              </span>
              <h1 className={` ${isDark ? 'text-white' : 'text-black'} text-xl font-bold`}></h1>
              <div className='flex mt-1'>

                {/* <Tooltip placement='top' title="View details">
                  <button className='cursor-pointer me-2'  ><FaEye size={35} className='hover:text-yellow-300' /></button>
                </Tooltip>

                <Tooltip placement='top' title='Update'>
                  <Link to={`/service-provider/update-portfolio/${items.id}`} className='cursor-pointer me-2'><FaRegEdit size={35} className='hover:text-blue-600' /></Link>
                </Tooltip> */}

                {/* <Tooltip placement='top' title='Delete'>
                  <span className='cursor-pointer '><RiDeleteBinFill size={35} className='hover:text-red-600' /></span>
                </Tooltip> */}
              </div>
            </div>

            <div className='flex justify-between p-3'>
              <div>
                <span className='text-lg '>{items.services}</span>
                <p className='text-gray-500'>
                  {items.description.length > 150
                    ? (
                      <>
                        {items.description.substring(0, 150)}....
                        <button className='text-blue-500'>
                          View more
                        </button>
                      </>
                    )
                    : items.description}
                </p>
              </div>
            </div>
           {/* {openDetail && portfolioDetail && (<PortfolioDetail
              openDetail={openDetail}
              name={portfolioDetail.name}
              service={portfolioDetail.services}
              active={portfolioDetail.active}
              handleToClose={() => setOpen(!openDetail)}
              description={portfolioDetail.description}
            />)}  */}
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default CompanyPortfolio