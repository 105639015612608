import Axios from "axios"
import { Base_url, org_url, portfolio_url } from "../../utils/constant"


export const createPortfolio=(userId,name,companyId,services,description)=>{  
    return Axios({
        method:"POST",
        url:`${portfolio_url}/portfolio`,
        data:{
            userId,
            name,
            companyId,
            services,
            description 
        }
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}

export const getCompanyPortfolios=(id)=>{
    return Axios({
        method:"GET",
        url:`${portfolio_url}/portfolio/company/${id}`,
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}

export const getUsersPortfolio=(id)=>{
    return Axios({
        method:"GET",
        url:`${portfolio_url}/api/portfolio/user/${id}`,
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}

export const getPortfolio=(id)=>{
    return Axios({
        method:"GET",
        url:`${portfolio_url}/portfolio/${id}`,
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}

export const updatePortfolio=(userId,name,companyId,services,description)=>{  
    return Axios({
        method:"PUT",
        url:`${portfolio_url}/portfolio`,
        data:{
            userId,
            name,
            companyId,
            services,
            description 
        }
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}

export const getServices=()=>{
    return Axios({
        method:"GET",
        url:`${org_url}/services`,
    })
    .then((res=>{
        return res
    }))
    .catch((err)=>{
        return err
    })
}


