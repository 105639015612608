import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { IoCaretBackOutline, IoNotifications } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { IoMdMenu } from "react-icons/io";
// import {  sidebarToggle } from '../../slices/sidebarSlice';
import { private_routes } from '../../routes';
import { Badge, Tooltip } from 'antd';
import { RiMessage2Line } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { sidebarToggle } from '../../store/slices/sidebarSlice';
import ProfileDropdown from '../../utils/dropdowns/ProfileDropdown';
import userAvatar from "../../assets/userAvatar.jpg"
import PortfolioDropdown from '../../utils/dropdowns/PortfolioDropdown';



function PrivateHeader({ fontSize }) {
  const location = useLocation()
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const { isSidebar, isFilter } = useSelector((state) => state.sidebarState)
  const activeRoute = private_routes.find(route => route.path === location.pathname);
  const { user } = useSelector((state) => state.authenticationState)
  const [profileDropdown, setProfileDropdown] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const [isPortfolio, setisPortfolio] = useState(false)
  const navigate = useNavigate()
  const portfolioRef=useRef(null)
  const profileDropdownRef = useRef(null)

  const dispatch = useDispatch()

  const handleDropDown = () => {
    setProfileDropdown(prevState => !prevState)
  }

  const handleOutsideClickPortfolio = (event) => {
    if (portfolioRef.current && !portfolioRef.current.contains(event.target)) {
      setisPortfolio(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClickPortfolio)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClickPortfolio)
    }
  }, [])

  const handleOutsideClick = (event) => {
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
      setProfileDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <header className={`flex flex-col w-full pt-16 mt-2 z-20 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <header className='fixed w-full z-50' style={{ fontSize: `${fontSize + 6}px` }}>
        <div className={`flex flex-row  lg:flex-row justify-between p-2 py-2 ${!isDark ? 'bg-white' : 'dark-mode'} px-2 border-b-[1px] border-gray-500/[0.3]`}>
          <div className='flex flex-row justify-between'>
            <div className='flex items-center '>
              {/* {
                !isSidebar && location.pathname === '/service-provider/list' ?
                  <FaFilter
                    size={30}
                    onClick={handleSidebar}
                    color={`${isDark ? 'white' : 'black'}`}
                    className='ml-4 cursor-pointer'
                  /> : */}
              {/* <button className='flex p-2 bg-blue-700 text-white rounded-3xl cursor-pointer hover:bg-blue-500' onClick={()=>navigate(-1)}><IoCaretBackOutline className='mt-1' />Back</button> */}

              {/* <IoMdMenu
                size={30}
                onClick={handleSidebar}
                color={`${isDark ? 'white' : 'black'}`}
                className='ml-4 cursor-pointer'
              /> */}
              {/* } */}
              <div className='ml-5'>
                {/* <h1 className='text-xl font-bold'>{activeRoute && activeRoute.name}</h1> */}
              </div>
            </div>
          </div>
          <div className={`lg:flex lg:items-center text-black`}>
            <span className='flex justify-between cursor-pointer'>
              {/* <ul ref={portfolioRef}>
                <li className='mt-2 me-7 font-semibold'>
                  <button onClick={()=>setisPortfolio(!isPortfolio)} >Portfolio</button>
                  {isPortfolio &&
                    <PortfolioDropdown
                    isPortfolio={isPortfolio}
                    handlePortfolio={()=>setisPortfolio(!isPortfolio)}
                    />
                  }
                </li>
              </ul> */}
              {/* <Tooltip placement='bottom' title='All portfolios'>
                <NavLink to={'/service-provider/all-portfolios'}
                  className={({ isActive }) => (isActive ? 'bg-blue-600 text-white px-1 py-1 rounded-full me-2' : `${!isDark ? 'text-black me-2 p-1' : 'text-white me-2 p-1'} `)}
                ><ImProfile onClick={() => navigate('/service-provider/all-portfolios')} size={25} className='ml-1 me-1 mb-1 mt-1' /></NavLink>
              </Tooltip> */}
              <Tooltip placement='bottom' title='Messages'>
                <Badge count={0} showZero className='me-8 mt-2'>
                  <RiMessage2Line color={`${isDark ? 'white' : 'black'}`} size={30} className='me-1 ' />
                </Badge>
              </Tooltip>
              <Tooltip placement='bottom' title='Notification'>
                <Badge count={10} className='me-7 mt-2'>
                  <Link to={'/notifications'}><IoMdNotificationsOutline color={`${isDark ? 'white' : 'black'}`} size={30} /></Link>
                </Badge>
              </Tooltip>

              <div className='relative' ref={profileDropdownRef}>
                <div className='flex items-center space-x-3 cursor-pointer' onClick={() => handleDropDown()}>
                  <img src={userAvatar} alt='avatar' className='h-10 w-10 rounded-full' />
                </div>
                {profileDropdown && <ProfileDropdown
                  profileDropdown={profileDropdown}
                  handleOpenProfile={() => setOpenProfile(!openProfile)}
                />}
              </div>
            </span>
          </div>

        </div>
      </header>
    </header>
  )
}

export default PrivateHeader