import React from 'react'
import Select from 'react-select'

function SecondSteps({ timelines, formik }) {
  const timelineMaped = timelines.map((industry) => ({
    value: industry,
    label: industry
  }))

  const budget = [
    "0-1 Lakh",
    "1-5 Lakh",
    "5-10 Lakh",
    "10-20 Lakh",
    "20-50 Lakh",
    "50-100 Lakh",
    "1-5 Crore",
    "5-10 Crore",
    "10-20 Crore",
    "20-50 Crore",
    "50-100 Crore",
    "100+ Crore"
  ]

  const budgetMaped = budget.map((b) => ({
    value: b,
    label: b
  }))

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "100%", // Ensures full width of the Tailwind wrapper
      minWidth: "300px", // Ensures minimum width
    }),
    menu: (base) => ({
      ...base,
      width: "100%", // Matches the control width
      minWidth: "300px",
    }),
  };
  return (
    <div className='flex flex-col justify-center items-center '>
      <div className='flex flex-col justify-center items-center'>
      <div className='relative w-full mt-4'>
        <Select
          id='projectTimeLine'
          className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
          options={timelineMaped}
          value={timelineMaped.filter((option) =>
            formik.values.projectTimeLine.includes(option.value)
          )}
          onChange={(selectedOption) => {
            formik.setFieldValue("projectTimeLine", selectedOption ? selectedOption.value : "");
          }}
          placeholder="Choose The Timeline"
          styles={customStyles}
        onBlur={formik.handleBlur}
        />
        <label
          htmlFor='projectTimeLine'
          className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
        >
          Choose Timeline<span className='text-red-600 ml-1'>*</span>
        </label>
        {/* {formik.touched.typesOfInvestment && formik.errors.typesOfInvestment ?
          <div className='text-red-600 ml-3'>
            {formik.errors.typesOfInvestment}
          </div> : null
        } */}
      </div>

      <div className='relative w-full mt-4 mb-4'>
        <Select
          id='budgetRange'
          className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
          options={budgetMaped}
          value={budgetMaped.filter((option) =>
            formik.values.budgetRange.includes(option.value)
          )}
          // onChange={(selected) => {
          //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
          // }}
          onChange={(selectedOption) => {
            formik.setFieldValue("budgetRange", selectedOption ? selectedOption.value : "");
          }}
          placeholder="Choose The Budget"
          styles={customStyles}
        onBlur={formik.handleBlur}
        />
        <label
          htmlFor='budgetRange'
          className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
        >
          Choose Budget<span className='text-red-600 ml-1'>*</span>
        </label>
        {/* {formik.touched.typesOfInvestment && formik.errors.typesOfInvestment ?
          <div className='text-red-600 ml-3'>
            {formik.errors.typesOfInvestment}
          </div> : null
        } */}
      </div>

      <div>
        <div className='relative w-full mb-4'>
          <textarea
            id='skillsRequired'
            name='skillsRequired'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Skills and Experties Needed'
          onChange={formik.handleChange}
          value={formik.values.skillsRequired}
          onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                      <div className='text-red-600 ml-1'>
                          {formik.errors.lastName}
                      </div> : null
                  } */}
          <label
            htmlFor='skillsRequired'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Skills & Experties<span className='text-red-600 ml-1'>*</span>
          </label>
        </div>

        <div className='relative w-full mb-4'>
          <input
            type='number'
            id='numberOfResources'
            name='numberOfResources'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Project No Of Resources Needed'
          onChange={formik.handleChange}
          value={formik.values.numberOfResources}
          onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                      <div className='text-red-600 ml-1'>
                          {formik.errors.lastName}
                      </div> : null
                  } */}
          <label
            htmlFor='numberOfResources'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            No Of Resources <span className='text-red-600 ml-1'>*</span>
          </label>
        </div>
      </div>
      </div>
    </div >
  )
}

export default SecondSteps