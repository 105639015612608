import React from 'react'
import { FaEye } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function AllInvestors() {

    const { isDark, verifiedCompanies, notVerifiedCompanies } = useSelector((state) => state.displaySettingsState)
    const navigate=useNavigate()

    const handleViewInvestor=(id)=>{
        navigate('/admin/investor-details',{state:{ investor_id: id }})
    }
    const data= [
        {
            id: 1,
            firstName: "jhon",
            lastName: "doe",
            address: "Banglore,Karnataka",
            phone: "992893829",
            investmentCapacity: 70000,
            sourceOfFunds: "Salary",
            priorInvestments: [
                {
                    company: "abc",
                    amount: 7000,
                    document: null
                },
                {
                    company: "xyz",
                    amount: 70001,
                    document: null
                }
            ],
            typeOfInvestment: [
                "Equity",
                "debt"
            ],
            industryPreference: ["IT software","technology"],
            profile:"dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
        },
        {
            id: 2,
            firstName: "Ranav",
            lastName: "math",
            address: "chennai,Tamilnadu",
            phone: "834938974",
            investmentCapacity: 70900,
            sourceOfFunds: "Buisness",
            priorInvestments: [
                {
                    company: "yout",
                    amount: 700045,
                    document: null
                },
                {
                    company: "xyzth",
                    amount: 70001,
                    document: null
                }
            ],
            typeOfInvestment: [
                "Equity",
                "debt"
            ],
            industryPreference: ["IT software","technology"],
            profile:"dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
        },
        {
            id: 3,
            firstName: "Varun",
            lastName: "mahadev",
            address: "mysore,Karnataka",
            phone: "65786588",
            investmentCapacity: 90000,
            sourceOfFunds: "Buisness",
            priorInvestments: [
                {
                    company: "xyz limited",
                    amount: 57547,
                    document: null
                },
                {
                    company: "uhu",
                    amount: 6788,
                    document: null
                }
            ],
            typeOfInvestment: [
                "Equity",
                "debt"
            ],
            industryPreference: ["IT software","technology"],
            profile:"dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
        },
        {
            id: 4,
            firstName: "Rghu",
            lastName: "mathth",
            address: "chennai,Karnataka",
            phone: "834938974",
            investmentCapacity: 70900,
            sourceOfFunds: "Buisness",
            priorInvestments: [
                {
                    company: "yout",
                    amount: 700045,
                    document: null
                },
                {
                    company: "xyzth",
                    amount: 70001,
                    document: null
                }
            ],
            typeOfInvestment: [
                "Equity",
                "debt"
            ],
            industryPreference: ["IT software","technology"],
            profile:"dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
        },
        {
            id: 5,
            firstName: "Rghu",
            lastName: "mathth",
            address: "chennai,Karnataka",
            phone: "834938974",
            investmentCapacity: 70900,
            sourceOfFunds: "Buisness",
            priorInvestments: [
                {
                    company: "yout",
                    amount: 700045,
                    document: null
                },
                {
                    company: "xyzth",
                    amount: 70001,
                    document: null
                }
            ],
            typeOfInvestment: [
                "Equity",
                "debt"
            ],
            industryPreference: ["IT software","technology"],
            profile:"dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
        }
    ]
    return (
        <div
            className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
        >
            <div className={`flex w-full max-w-7xl max-h-full h-full ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-11 mt-4 px-2`}>
                <table className="table-auto border-collapse border border-gray-400 w-full">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 px-4 py-2">ID</th>
                            <th className="border border-gray-300 px-4 py-2">First Name</th>
                            <th className="border border-gray-300 px-4 py-2">Last Name</th>
                            <th className="border border-gray-300 px-4 py-2">Phone Number</th>
                            <th className="border border-gray-300 px-4 py-2">Location</th>
                            <th className="border border-gray-300 px-4 py-2"></th>
                        </tr>
                    </thead>
                    {data.map((investors) => (
                        <tbody >
                            <tr className='p-2 border'>
                                <td className='p-2 text-center'>{investors.id}</td>
                                <td className='p-2 text-center'>{investors.firstName}</td>
                                <td className='p-2 text-center'>{investors.lastName}</td>
                                <td className='p-2 text-center'>{investors.phone}</td>
                                <td className='p-2 text-center'>{investors.address}</td>
                                {/* <td className={`p-2 text-center ${companies.isVerified ? 'text-blue-600' : 'text-red-500'}`}>{companies.isVerified ? "Verified" : "Pending"}</td> */}
                                <td className='p-2 text-center'><button onClick={()=>handleViewInvestor(investors.id)} className='cursor-pointer'><FaEye color='blue' size={40} /></button></td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default AllInvestors