import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FirstSteps from '../../../../components/private/clients/registerSteps/FirstSteps'
import SecondSteps from '../../../../components/private/clients/registerSteps/SecondSteps'
import ThirdStep from '../../../../components/private/clients/registerSteps/ThirdStep'
import { message } from 'antd'
import { IoIosArrowBack } from 'react-icons/io'
import SuccessModal from '../../../../utils/Modal/SuccessModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdNavigateNext } from 'react-icons/md'
import { getTimeline, projectRegister } from '../../../../helper/project/projectRequestCalls'
import { useAddProjectForm } from '../../../../helper/project/projectFormHook'

function ClientRegistration() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [registerdSteps, setRegisterdSteps] = useState(0)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [timelines, setTimelines] = useState([])
    const location = useLocation();
    const orgId = location.state?.org_id;
    const navigate = useNavigate()

    const fetchTimeline = () => {
        getTimeline()
            .then((res) => {
                setTimelines(res.data.data)
                console.log("res", res)
            })
            .catch((err) => console.error("error", err))
    }

    useEffect(() => {
        fetchTimeline()
    }, [])

    const formTitles = ['Project', 'Requirements', 'Submission Guidelines']

    const handleSubmit = (values) => {
        // console.log("values",values)
        const { projectTitle,
            projectDescription,
            objectiveAndGoals,
            projectTimeLine,
            budgetRange,
            skillsRequired,
            numberOfResources,
            preferredSubmissionFormat,
            deadline } = values

        const payload = {
            projectTitle:projectTitle,
            projectDescription:projectDescription,
            objectiveAndGoals: objectiveAndGoals,
            projectTimeLine:projectTimeLine,
            budgetRange: budgetRange,
            skillsRequired:skillsRequired,
            numberOfResources: numberOfResources,
            preferredSubmissionFormat: preferredSubmissionFormat,
            deadline:deadline
       }
       console.log("payload",payload)
       projectRegister(payload)
       .then((res)=>{
        if(res.status===200){
            setOpenSuccess(true)
        }else{
            message.error("regitration failed")
        }
       })
       .catch((err)=>console.error("err",err))
    }

    const formik = useAddProjectForm(handleSubmit)

    const pageDisplay = () => {
        if (registerdSteps === 0) {
            return <FirstSteps formik={formik} />
        } else if (registerdSteps === 1) {
            return <SecondSteps timelines={timelines} formik={formik} />
        } else {
            return <ThirdStep formik={formik} />
        }
    }

    

    const handleNextStep = () => {
        const errors = formik.errors;
        // console.log(errors)
        // console.log(Object.keys(errors).length)
        if (registerdSteps === 0 && !errors.projectTitle && !errors.projectDescription &&!errors.objectiveAndGoals ) {
            setRegisterdSteps((prev) => prev + 1);
        } else if (registerdSteps === 1 && !errors.projectTimeLine &&!errors.budgetRange &&!errors.skillsRequired ) {
            setRegisterdSteps((prev) => prev + 1);
        } else {
            message.error("Please fill required fields....")
        }
    }



    const handleRegister = () => {
        if (registerdSteps === formTitles.length - 1) {
            formik.handleSubmit()
        } else {
            handleNextStep()
        }
    }

    return (
        <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`w-full max-w-4xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-slate-500 '} space-y-10 rounded-lg h-full mt-11`}>
                <div className="relative w-full bg-gray-200 rounded-full h-2.5 my-4 ">
                    <div
                        className="absolute top-0 left-0 bg-blue-700 h-2.5 rounded-full"
                        style={{ width: `${((registerdSteps + 1) / formTitles.length) * 100}%` }}
                    ></div>
                
                    
                    <div className="absolute w-full flex justify-between text-sm text-gray-600 mt-3">
                        {formTitles.map((title, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <span
                                    className={`flex items-center ${index < registerdSteps
                                        ? 'text-blue-800 font-semibold '
                                        : index === registerdSteps
                                            ? 'text-blue-700 font-bold '
                                            : 'text-gray-600'
                                        }`}
                                >
                                    {index < registerdSteps && (
                                        <svg
                                            className="w-4 h-4 mr-1  font-bold rounded-full"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    )}
                                    {title}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className='flex flex-col space-y-8 px-5 py-8 border shadow-md '
                >
                    <div className='flex flex-col justify-between'>
                        {pageDisplay()}
                    </div>

                    <div className='flex justify-between '>
                        {registerdSteps == 0 && <button className='flex btn bottom-0 right-0 ml-56'
                            onClick={() => navigate(-1)}
                        ><IoIosArrowBack className='mt-0.5 ' /> Back</button>}
                        {!registerdSteps == 0 && <button className='flex btn bottom-0 right-0 ml-56'
                            onClick={() => {
                                setRegisterdSteps((curSteps) => curSteps - 1)
                            }}
                        ><IoIosArrowBack className='mt-0.5 ' /> Back</button>}
                        <button type='submit' className='btn ml-auto me-56'
                            onClick={handleRegister}
                        >
                            {registerdSteps === formTitles.length - 1 ? "Submit" : <div className='flex'>Next <MdNavigateNext className='mt-1' /></div>}
                        </button>
                        <SuccessModal open={openSuccess} onClose={() => setOpenSuccess(false)} message={"Project Posted Successfully"} path={'/dashboard'} buttonName="Go to dashboard" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientRegistration