import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { darkModeSwitcher, decreaseFontSize, increaseFontSize, resetFontSize } from '../../../store/slices/displaySettingsSlice'
import { MdDarkMode } from "react-icons/md";

function DisplaySettings({setDefaultFont, fontSize, increaseFont, decreaseFont }) {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const dispatch=useDispatch()
    return (
        <div className='flex me-2 mt-1'>
            {/* <button
                className='font-control p-3 '
                onClick={()=>dispatch(decreaseFontSize())}
                style={{ height: '30px', width: '20px', }}
            >
                A-
            </button>
            <button
                className='font-control ml-2 p-3'
                onClick={()=>dispatch(resetFontSize())}
                style={{ height: '30px', width: '20px', }}
            >
                A
            </button>
            <button
                className='font-control ml-2 p-3'
                onClick={()=>dispatch(increaseFontSize())}
                style={{ height: '30px', width: '20px', }}
            >
                A+
            </button> */}
            <MdDarkMode
                className={`ml-2 p-2 rounded-lg cursor-pointer ${!isDark ? 'bg-black text-white' : 'bg-blue-500 text-white'}`}
                onClick={() => dispatch(darkModeSwitcher())}
                size={30} />
        </div>
    )
}

export default DisplaySettings