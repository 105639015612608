import React, { Fragment } from 'react'
import verificationImage from '../../../assets/UnderVerification.webp'
import { useSelector } from 'react-redux'
import { message, Tooltip } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { HiEye } from 'react-icons/hi2'


function NotVerifiedCompanies({ unverified,handleServiceProvider,handleProject,handleSellCompany,openProfile,isLoading  }) {
    const { isDark } = useSelector((state) => state.displaySettingsState)

    
    return (
        <Fragment>
            <div className={`flex flex-col h-full overflow-auto scrollable-container hide-scrollbar   w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2 shadow-lg px-3 space-y-2 ${
          isLoading ? "blur-sm opacity-80" : "blur-none opacity-100"
        } transition-all duration-300`}>
                <div className={`w-full mt-1 max-w-5xl space-y-6 rounded-lg px-6 p-2`} >
                    <div className="flex flex-wrap gap-2 p-2">
                        {unverified && unverified.map((item) => (
                            <div className='flex w-full border p-2'>
                                <div className='flex flex-col gap-2 p-2' key={item.id}>
                            
                                        <img
                                            src={verificationImage}
                                            alt={item.legalEntityName}
                                            onClick={()=>openProfile(item.companyId)}
                                            className={` rounded-full h-14 w-14 bg-white cursor-pointer`}
                                        />

                                    {/* <span className='absolute text-blue-700 hover:text-blue-950 font-bold  text-center ml-20 cursor-pointer'>
                                    <Tooltip placement='top' title="view details">
                                        <Link to={`/organization/profile/${item.id}`}><HiEye size={20} /></Link>
                                    </Tooltip>
                                </span> */}
                                    <h1 className='text-center text-gray-500'>{item.legalEntityName}</h1>
                                </div>
                                <div className='flex flex-col justify-center items-center  ml-52'>
                                    <button onClick={()=>handleServiceProvider(item.id,item.verified)} className='bg-blue-600 p-2 w-48 text-white rounded-full text-sm hover:bg-white border hover:border-blue-600 hover:text-black'>List as a Service Provider</button>
                                    <button onClick={()=>handleProject(item.id,item.verified)} className='bg-blue-600 p-2 w-48 text-center  mt-2 text-white rounded-full text-sm hover:bg-white border hover:border-blue-600 hover:text-black'>Post Project</button>
                                    <button onClick={()=>handleSellCompany(item.verified)} className='bg-blue-600 p-2 w-48 text-center mt-2 text-white rounded-full text-sm hover:bg-white border hover:border-blue-600 hover:text-black'>Sell Your Company</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotVerifiedCompanies