import React, { useEffect, useState } from 'react'
import underConstruction from '../../../assets/workstation.avif'
import { useSelector } from 'react-redux'
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import { MdVerified } from 'react-icons/md'
import { GoUnverified } from 'react-icons/go'
import { Tooltip } from 'recharts'
import { CiLocationOn, CiMail } from 'react-icons/ci'
import { FcAbout } from 'react-icons/fc'
import { FaUsers } from 'react-icons/fa'
import { SiAmazonsimpleemailservice, SiWebmoney } from "react-icons/si";
import { FaLocationDot } from "react-icons/fa6";
import { companyProfile, getCompanyLogo, orgReviews } from '../../../helper/organization/orgRequestCall'
import { useParams } from 'react-router-dom'
import { IoCaretBackOutline } from "react-icons/io5";
import CompanyPortfolio from '../../../components/private/serviceProvider/CompanyPortfolio'
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaRegFlag } from "react-icons/fa";


function ServiceProviderDetail() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [profileDetail, setProfileDetail] = useState(null)
  const [reviews, setReviews] = useState([])
  const { org_id } = useParams()
  const [logo, setLogo] = useState(null)
  // const navigate = useNavigate()
  const rating = 4
  const totalStars = 5
  const companyProfileDetail = "TempX Solutions specializes in providing businesses with reliable, flexible, and skilled temporary staffing solutions. With a commitment to excellence and efficiency, we bridge the gap between employers and talented professionals, ensuring seamless operations across industries.  Founded on the principles of innovation and adaptability, TempX Solutions leverages cutting-edge technology to match the right candidates with the right opportunities. From short-term projects to seasonal hiring needs, we pride ourselves on delivering customized staffing solutions that empower businesses to thrive in dynamic market conditions."

  const fetchReview = () => {
    orgReviews(org_id)
      .then((res) => {
        if (res.status === 200) {
          setReviews(res.data.data)
        }
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(() => {
    fetchReview()
  }, [])

  const getOrgLogo = () => {
    getCompanyLogo(org_id)
      .then((res) => {
        const url = URL.createObjectURL(res.data);
        setLogo(url)
        console.log("data", url)
      })
      .catch((err) => {
        console.error("errpr", err)
      })
  }
  const getCompanyProfile = () => {
    companyProfile(org_id)
      .then((res) => {
        if (res.status === 200) {
          setProfileDetail(res.data.data)
          // setVerified(res.data.verified)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getCompanyProfile()
    // console.log("profile",profile).
    getOrgLogo()
  }, [])
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
      {profileDetail &&
        <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
          {/* <div className='flex justify-between w-full mt-1'> */}

          <div className='flex justify-end w-full mt-1'>

            {/* <Link
                            to={`/organization/update-profile/${profile.id}`}
                            className='flex bg-blue-700 hover:bg-blue-500 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                        >
                            Update profile <MdModeEdit className='mt-1 ml-2' color={'white'} />
                        </Link> */}
            <a
              href={profileDetail.companyUrl}
              target='_blank'
              className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
            >
              Visit Website <SiWebmoney className='mt-1 ml-1' />
            </a>

            <button className='flex hover:bg-blue-800 border-2 border-blue-600 p-2 me-4 mt-4 rounded-lg text-black hover:text-blue-50 hover:shadow-lg'>Connect <TiMessages className='ml-1 mt-1' /></button>
          </div>
          {/* </div> */}
          <div className='flex justify-between max-w-7xl  p-2 relative'>
            <img
              src={logo ? logo : defaultLogo}
              alt='logo'
              className='relative h-32 w-44 rounded-xl shadow-lg'
            />

            <div className='flex flex-row gap-3'>
              <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                <h1 className='text-black text-xl'>Total Reviews</h1>
                <span className='mt-2 text-blue-600'>{reviews && reviews.length}</span>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-7 p-2 h-full '>
            <div className={`flex flex-row gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full h-auto shadow-lg rounded-lg p-5`}>
              <div className='flex flex-col w-full'>
                <h1 className='text-blue-500'>About</h1>
                <p className='ml-2'>{profileDetail.businessDescription}</p>
              </div>
              <div className='border-l p-2 px-6 max-w-96'>
                <span>{profileDetail.legalEntityName}</span>
                <Tooltip placement='top' title="Location" >
                </Tooltip>
                {/* <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><CiMail className='me-1 mt-1' /> {profileDetail.officialMailId}</span> */}

                {/* <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}>CIN: {profileDetail.cin}</span> */}
                {/* <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}> PAN:{profileDetail.pan}</span> */}
                <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}>  <FaUsers className='mt-1 ml-2 me-2' />{profileDetail.noOfEmployees}</span>
                <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}>  <SiAmazonsimpleemailservice className='mt-1 ml-2 me-2' />{profileDetail.services}</span>
                <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}>  <FaRegFlag className='mt-1 ml-2 me-2' />{profileDetail.establishmentYear}</span>
              </div>
            </div>

            <div className='flex w-full '>
              <div className='mt-3  border-gray-300'>
                <ul className='flex flex-col  text-gray-600 text-xl ml-9'>
                  <li className='flex'><FaInstagram className='mt-1 me-1' /> <a href={profileDetail.instagramUrl} target='_blank' className='flex'>Visit <MdArrowOutward className='mt-1' /></a></li>
                  <li className='flex'><FaLinkedin className='mt-1 me-1' /> <a href={profileDetail.linkedInUrl} target='_blank' className='flex'>Visit <MdArrowOutward className='mt-1' /></a></li>
                  <li className='flex'><FaFacebook className='mt-1 me-1' /> <a href={profileDetail.facebookUrl} target='_blank' className='flex'>Visit <MdArrowOutward className='mt-1' /></a></li>
                  <li className='flex'><FaXTwitter className='mt-1 me-1' /> <a href={profileDetail.twitterUrl} target='_blank' className='flex'>Visit <MdArrowOutward className='mt-1' /></a></li>
                  <li className='flex'><FaYoutube className='mt-1 me-1' /> <a href={profileDetail.youtubeUrl} target='_blank' className='flex'>Visit <MdArrowOutward className='mt-1' /></a></li>
                </ul>
              </div>

              <div className='mt-2 ml-36 border-l border-gray-300'>
                <h1 className='ml-2 font-bold'>Location details</h1>
                <div className='flex ml-2 text-gray-600'>
                  <FaLocationDot className='mt-1 ' /> {profileDetail.communicationAddress.addressLine1},<br />
                  {profileDetail.communicationAddress.addressLine2},{profileDetail.communicationAddress.postalCode},<br />
                  {/* {profileDetail.location.city.name},{profileDetail.location.state.name},<br />
                  {profileDetail.location.country.name} */}
                </div>
              </div>

              <div className='mt-2 ml-36 border-l border-gray-300'>
                <h1 className='ml-2 font-bold'>Verification status</h1>
                <div className='flex ml-2 bg-green-300 p-2 rounded-md shadow-md'>
                  <MdVerified className='mt-1 text-green-600' />
                  <span className='font-semibold'>Verified by inqubee</span>
                </div>
              </div>
            </div>
            <div className={`${isDark ? 'bg-slate-700' : 'bg-white'} h-auto rounded-lg w-full shadow-lg`}>
              
              <div className='p-2 mt-4 shadow-lg space-y-5'>
              <h1 className='text-center text-lg text-blue-600'>Reviews</h1>
                {reviews?reviews.map((review) => (
                  <div key={review.id}>
                    <div className={`flex flex-row gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full h-auto shadow-lg rounded-lg p-5`}>
                      <div className='flex flex-col w-full'>
                        <h1 className='text-blue-500'>Feedback Summary</h1>
                        <p className='ml-2'>{review.reviewComment}</p>
                      </div>
                      <div className='border-l p-2 px-6 w-full'>
                        <h1>Reviewer</h1>
                        <ul className='flex flex-col'>
                          <li className='text-sm text-gray-600'>{review.reviewerName}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )):<div>No Reviews Found</div>}
              </div>
            </div>
            <div className={`${isDark ? 'bg-slate-700' : 'bg-white'} h-auto rounded-lg w-full shadow-lg`}>

              <div className='p-2'>
                <CompanyPortfolio org_id={org_id} />
              </div>
            </div>
          </div>

        </div>}
    </div>
  )
}

export default ServiceProviderDetail