import React from 'react'
import { useSelector } from 'react-redux'
import UnderConstruction from '../../../assets/workstation.avif'

function PostRequirments() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
        <div className='flex flex-col justify-center w-full h-full items-center'>
             <img src={UnderConstruction} alt='under-construction' className='h-60 w-60'/>
             <p>Under construction</p>
        </div>
    </div>
  )
}

export default PostRequirments