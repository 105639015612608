import React, { useState } from 'react'
import { TiTick } from "react-icons/ti";
import PrivacyPolicyModal from '../../../../utils/Modal/PrivacyPolicyModal';
import TermsAndConditionModal from '../../../../utils/Modal/TermsAndConditionModal';
import BasicPlan from '../plans/BasicPlan';
import PremiumPlan from '../plans/PremiumPlan';
import RefundModal from '../../../../utils/Modal/RefundModal';
import { basic_plan, premium_plan } from '../../../../utils/constant';
import FreePlan from '../plans/FreePlan';
import { message } from 'antd';
import PaymentMethod from '../../../../utils/Modal/PaymentMethod';
import PremiumPaymentMethod from '../../../../utils/Modal/PremiumPaymentMethod';

function LastStep(props) {

    const { formik,
        billingName,
        setBillingName,
        billingAddress,
        setAddresss,
        billingSecondAddress,
        setSecondAddresss,
        billingPincode,
        setPincode,
        billingGst,
        setGst,
        countries,
        province,
        cities,
        billingCountry,
        setBillingCountry,
        billingProvince,
        setBillingProvince,
        billingCity,
        setBillingCity,
        billingCountry2,
        setBillingCountry2,
        billingProvince2,
        setBillingProvince2,
        billingCity2,
        setBillingCity2,
        billingPhoneNumber,
        setPhoneNumber,
        billingEmail,
        setEmail,
        billingName2,
        setBillingName2,
        billingAddress2,
        setAddresss2,
        billingSecondAddress2,
        setSecondAddresss2,
        billingPincode2,          //props for components
        setPincode2,
        billingGst2,
        setGst2,
        phoneNumber2,
        setPhoneNumber2,
        email2,
        setEmail2,
        openMethod,
        openPremiumMethod,
        handleSubmitBill,
        handleSubmitPremiumBill,
        setOpenMethod,
        setOpenPremiumMethod,
        selectedPlan,
        plan
    } = props

    const [privacy, setOpenPrivacy] = useState(false)
    const [terms, setOpenTerms] = useState(false)
    const [refund, setOpenRefund] = useState(false)
    const [openBasic, setOpenBasic] = useState(false)
    const [knowBasic, setKnowBasic] = useState(false)
    const [openFree, setOpenFree] = useState(false)
    const [openBuyBasic, setOpenBuyBasic] = useState(false)
    const [openBuyPremium, setOpenBuyPremium] = useState(false)
    const [knowFree, setknowFree] = useState(false)
    const [openPremium, setOpenPremium] = useState(false)
    const [knowPremium, setKnowPremium] = useState(false)

    const handleOpenFree = () => {
        setOpenFree(!openFree)
        selectedPlan("Free")
        setOpenBasic(false)
        setOpenPremium(false)
    }

    const handleOpenBasic = () => {
        setOpenBasic(!openBasic)
        setOpenFree(false)
        selectedPlan("basic")
        setOpenPremium(false)
    }

    const handleOpenPremium = () => {
        setOpenPremium(!openPremium)
        setOpenBasic(false)
        selectedPlan("premium")
        setOpenFree(false)
    }
    let basic_amount = 9999
    let Premium_amount = 19999


    const handleBuy = () => {
        setOpenBuyBasic(true)
        // console.log("plan",openBuyPlan)
    }

    return (
        <div className='flex flex-col'>
            {/* <div className='flex gap-3 justify-center items-center'>
                <div className='relative bg-white rounded mt-2 border-2 border-gray-400 w-full shadow-xl'>
                    <label className=' flex justify-center bg-white text-black items-center text-xl text-center py-2 px-4 rounded cursor-pointer border-gray-400'>Attach Company Registration document <FaFileArrowUp className='ml-2' /></label>
                    <input
                        type='file'
                        className='absolute inset-4 opacity-0 cursor-pointer border-gray-400'
                        accept='.pdf, .doc, .docx'
                        onChange={handleFileChange}
                        ref={docRef}
                        required
                    />
                    {selectedFile && (
                        <div className='flex flex-col items-center space-y-2'>
                            <p className='text-black'>{selectedFile.name}</p>
                            <div className="relative p-2">
                                <a href={previewFile} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src='https://img.icons8.com/ios-filled/100/000000/pdf.png'
                                        alt='pdf icon'
                                        className='w-10 h-10'
                                    />
                                </a>
                                <button
                                    onClick={() => {
                                        setSelectedfile(null)
                                        setPreviewFile(null)
                                        URL.revokeObjectURL(previewFile)
                                    }}
                                    className='absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                >
                                    &times;
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className='relative bg-white rounded justify-center border-2 border-gray-400 items-center mt-1 w-full shadow-xl'>
                    <label className=' flex justify-center bg-white text-black items-center text-xl text-center py-2 px-4 rounded cursor-pointer '>Add Company Logo<MdAddPhotoAlternate className='ml-2' /></label>
                    <input
                        type='file'
                        onChange={handleLogo}
                        multiple
                        ref={fileRef}
                        accept='image/*'
                        className='absolute inset-4 opacity-0 cursor-pointer '
                    />
                    {selectedLogo && (
                        <div className='flex flex-col items-center space-y-2'>
                            <p className='text-black'>{selectedLogo.name}</p>
                            <div className="relative p-2">
                                <img
                                    src={previewlogo}
                                    alt='company logo'
                                    className='w-32 h-10'
                                />
                                <button
                                    onClick={handleRemoveLogo}
                                    className='absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                >
                                    &times;
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div> */}
            <div className='bg-white border p-2'>
                <div className='border-b p-1'>
                    <h1 >Select Plan</h1>
                </div>
                <div className='py-3'>
                    <div>
                        <label className="flex items-center mb-4 ">
                            <input
                                type='checkbox'
                                name='options'
                                className='hidden peer mt-1'
                                // checked={plan==}
                                onChange={() => handleOpenFree()}
                            />
                            <div className='w-4 h-4 border-2  border-gray-700  mt-1 bg-white peer-checked:border-blue-700'>
                                {openFree && <TiTick className='h-3 w-3' />}
                            </div>
                            <div className='w-full flex flex-row justify-between  mt-1 ml-2'><span className='mt-1 font-semibold'>Basic Plan<button className='text-blue-600 ml-2' onClick={() => setknowFree(!knowFree)} type='button'>Know more</button></span><span className='text-lg text-slate-700'>Rs.0</span></div>
                        </label>
                        {(openFree || knowFree) && <div className='flex w-full justify-between p-2'>
                            <FreePlan />
                        </div>}

                    </div>
                    <div>
                        <label className="flex items-center mb-4 ">
                            <input
                                type='checkbox'
                                name='options'
                                className='hidden peer mt-1'
                                onChange={() => handleOpenBasic()}
                            />
                            <div className='w-4 h-4 border-2  border-gray-700  mt-1 bg-white peer-checked:border-blue-700'>
                                {openBasic && <TiTick className='h-3 w-3' />}
                            </div>
                            <div className='w-full flex flex-row justify-between  mt-1 ml-2'><span className='mt-1 font-semibold'>Premium Plan<button className='text-blue-600 ml-2' onClick={() => setKnowBasic(!knowBasic)} type='button'>Know more</button></span><span className='text-lg text-slate-700'>Rs.{basic_plan}</span></div>
                        </label>
                        {(openBasic || knowBasic) && <div className='flex  w-full justify-between p-2'>
                            <BasicPlan />
                            {openBasic &&
                                <div className='mt-20'><button type='button' className='p-2 bg-blue-800 text-white rounded' onClick={() => handleBuy()}>Buy Now</button></div>
                            }
                        </div>}
                        {openBuyBasic &&
                            <div className='flex flex-col justify-center items-center p-2 '>
                                <h1 className='mt-2 mb-2 font-bold text-center'>Billing Address</h1>
                                <form onSubmit={handleSubmitBill}>
                                    {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingName'
                                            name='billingName'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Name'
                                            value={billingName}
                                            onChange={(e) => setBillingName(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingName'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Billing Name <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}
                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingAddress'
                                            name='billingAddress'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Address'
                                            value={billingAddress}
                                            onChange={(e) => setAddresss(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingAddress'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Address line1 <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingSecondAddress'
                                            name='billingSecondAddress'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Address'
                                            value={billingSecondAddress}
                                            onChange={(e) => setSecondAddresss(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingSecondAddress'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Address Line2<span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingPincode'
                                            name='billingPincode'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Pincode'
                                            value={billingPincode}
                                            onChange={(e) => setPincode(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingPincode'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Pin Code<span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='flex flex-col'>
                                        <select name='billingCountry'
                                            onChange={(e) => setBillingCountry(e.target.value)}
                                            value={billingCountry}
                                            className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Country <span className='text-red-600 ml-1'>*</span></option>
                                            {countries.map((country) => (
                                                <option key={country.countryId} value={country.countryId} >
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>

                                        <select name='billingProvince'
                                            onChange={(e) => setBillingProvince(e.target.value)}
                                            value={billingProvince}
                                            className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Select state <span className='text-red-600 ml-1'>*</span></option>
                                            {province.map((state) => (
                                                <option key={state.provinceId} value={state.provinceId} >
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>

                                        <select name='billingCity'
                                            onChange={(e) => setBillingCity(e.target.value)}
                                            value={billingCity}
                                            className='shadow border rounded w-96 mb-4 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
                                            {cities.map((city) => (
                                                <option key={city.cityId} value={city.cityId} >
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingGst'
                                            name='billingGst'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter GST Number'
                                            value={billingGst}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingGst'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            GST
                                        </label>
                                    </div> */}
                                    {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Phone Number'
                                            value={billingPhoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                        <label
                                            htmlFor='phoneNumber'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Phone <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Email Address'
                                            value={billingEmail}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingEmail'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Email <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}
                                    <div className='flex justify-center items-center'>
                                        <button className='bg-blue-950 p-2 text-white rounded' type='submit'>Submit</button>
                                        <button className='text-red-700 cursor-pointer ml-3' onClick={() => setOpenBuyBasic(false)}>Cancel</button>
                                    </div>
                                </form>
                            </div>}
                    </div>
                    <div>
                        <label className="flex items-center mb-4 ">
                            <input
                                type='checkbox'
                                name='options'
                                className='hidden peer mt-1'
                                onChange={() => handleOpenPremium()}
                            />
                            <div className='w-4 h-4 border-2  border-gray-700  mt-1 bg-white peer-checked:border-blue-700'>
                                {openPremium && <TiTick className='h-3 w-3' />}
                            </div>
                            <div className='w-full flex flex-row justify-between  mt-1 ml-2'><span className='mt-1 font-semibold'>Sponsor Plan<button className='text-blue-600 ml-2' onClick={() => setKnowPremium(!knowPremium)} type='button'>Know more</button></span><span className='text-lg text-slate-700'>Rs.{premium_plan}</span></div>
                        </label>
                        {(knowPremium || openPremium) && <div className='flex w-full justify-between p-2'>
                            <PremiumPlan />
                            {openPremium &&
                                <div className='mt-32'><button type='button' className='p-2 bg-blue-800 text-white rounded' onClick={() => setOpenBuyPremium(true)}>Buy Now</button></div>
                            }

                        </div>}
                        {openBuyPremium &&
                            <div className='flex flex-col justify-center items-center p-2 '>
                                <h1 className='mt-2 mb-2 font-bold text-center'>Billing Address</h1>
                                <form onSubmit={handleSubmitPremiumBill}>
                                    {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingName2'
                                            name='billingName2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Name'
                                            value={billingName2}
                                            onChange={(e) => setBillingName2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingName2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Billing Name <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}

                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingAddress2'
                                            name='billingAddress2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Address'
                                            value={billingAddress2}
                                            onChange={(e) => setAddresss2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingAddress2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Address Line1<span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>

                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingSecondAddress2'
                                            name='billingSecondAddress2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Address'
                                            value={billingSecondAddress2}
                                            onChange={(e) => setSecondAddresss2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingSecondAddress2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Address Line2<span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>

                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingPincode2'
                                            name='billingPincode2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Pincode'
                                            value={billingPincode2}
                                            onChange={(e) => setPincode2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingPincode2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Pin Code<span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='flex flex-col'>
                                        <select name='billingCountry2'
                                            onChange={(e) => setBillingCountry2(e.target.value)}
                                            value={billingCountry2}
                                            className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Country <span className='text-red-600 ml-1'>*</span></option>
                                            {countries.map((country) => (
                                                <option key={country.countryId} value={country.countryId} >
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        <select name='billingProvince2'
                                            onChange={(e) => setBillingProvince2(e.target.value)}
                                            value={billingProvince2}
                                            className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Select state <span className='text-red-600 ml-1'>*</span></option>
                                            {province.map((state) => (
                                                <option key={state.provinceId} value={state.provinceId} >
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                        <select name='billingCity2'
                                            onChange={(e) => setBillingCity2(e.target.value)}
                                            value={billingCity2}
                                            className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                        >
                                            <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
                                            {cities.map((city) => (
                                                <option key={city.cityId} value={city.cityId} >
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <div className='relative w-full mb-4 mt-4'>
                                        <input
                                            type='text'
                                            id='billingGst2'
                                            name='billingGst2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter GST Number'
                                            value={billingGst2}
                                            onChange={(e) => setGst2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingGst2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            GST
                                        </label>
                                    </div>

                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='phoneNumber2'
                                            name='phoneNumber2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Phone Number'
                                            value={phoneNumber2}
                                            onChange={(e) => setPhoneNumber2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='phoneNumber2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Phone <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>

                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='email'
                                            id='email2'
                                            name='email2'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Email Address'
                                            value={email2}
                                            onChange={(e) => setEmail2(e.target.value)}
                                        />
                                        <label
                                            htmlFor='email2'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Email <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}
                                    <div className='flex justify-center items-center'>
                                        <button className='bg-blue-950 p-2 text-white rounded' type='submit'>Submit</button>
                                        <button className='text-red-700 cursor-pointer ml-3' onClick={() => setOpenBuyPremium(false)}>Cancel</button>
                                    </div>
                                </form>
                            </div>}
                    </div>
                </div>
            </div>
            {!openFree && <div className='relative w-full mb-4 mt-4'>
                <input
                    type='text'
                    id='paymentRefrence'
                    name='paymentRefrence'
                    className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                    placeholder='Enter Refrence Code'
                    onChange={formik.handleChange}
                    value={formik.values.paymentRefrence}
                    onBlur={formik.handleBlur}
                />
                <label
                    htmlFor='paymentRefrence'
                    className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Payment Refrence
                </label>
            </div>}
            <div className=''>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='privacyPolicy'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.privacyPolicy}
                        />
                        <div className={`w-4 h-4 border-2 ${formik.touched.privacyPolicy && formik.errors.privacyPolicy ? 'border-red-600' : 'border-gray-700'}  mt-1 bg-white peer-checked:border-blue-700`}>
                            {formik.values.privacyPolicy && <TiTick className='h-3 w-3' />}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenPrivacy(true)}> Privacy Policy</button>.</span>
                    </label>
                    {
                        formik.touched.privacyPolicy && formik.errors.privacyPolicy ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.privacyPolicy}
                            </div> : null
                    }
                </div>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='termsAndCondition'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.termsAndCondition}
                        />
                        <div className={`w-4 h-4 border-2 ${formik.touched.termsAndCondition && formik.errors.termsAndCondition ? 'border-red-600' : 'border-gray-700'}  mt-1 bg-white peer-checked:border-blue-700`}>
                            {formik.values.termsAndCondition && <TiTick className='h-3 w-3' />}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenTerms(true)}>Terms and Condition</button>.</span>
                    </label>
                    {
                        formik.touched.termsAndCondition && formik.errors.termsAndCondition ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.termsAndCondition}
                            </div> : null
                    }
                </div>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='refundPolicy'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.refundPolicy}
                        />
                        <div className={`w-4 h-4 border-2 ${formik.touched.refundPolicy && formik.errors.refundPolicy ? 'border-red-600' : 'border-gray-700'}  mt-1 bg-white peer-checked:border-blue-700`}>
                            {formik.values.refundPolicy && <TiTick className='h-3 w-3' />}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenRefund(true)}>Refund Policy</button>.</span>
                    </label>
                    {
                        formik.touched.refundPolicy && formik.errors.refundPolicy ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.refundPolicy}
                            </div> : null
                    }
                </div>
            </div>
            <PrivacyPolicyModal open={privacy} handleClose={() => setOpenPrivacy(false)} />
            <TermsAndConditionModal open={terms} handleClose={() => setOpenTerms(false)} />
            <RefundModal open={refund} handleClose={() => setOpenRefund(false)} />
            <PaymentMethod openMethod={openMethod} handleToClose={() => setOpenMethod(false)} basic_amount={basic_amount} />
            <PremiumPaymentMethod handleToClose={() => setOpenPremiumMethod(false)} openMethod={openPremiumMethod} premium_amount={Premium_amount} />
        </div>
    )
}

export default LastStep