import React from 'react'
import { useSelector } from 'react-redux'
import { FaRegEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function AllOrganizations() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const navigate=useNavigate()
    const data = [
        { id: 1, legalEntityName: "xyz Private limited", cin: "283331", pan: "131323", phone: "909989890", mailId: 'xyz@mail.com', isVerified: false },
        { id: 2, legalEntityName: "xyz Private limited", cin: "283331", pan: "131323", phone: "909989890", mailId: 'xyz@mail.com', isVerified: true },
        { id: 3, legalEntityName: "xyz Private limited", cin: "283331", pan: "131323", phone: "909989890", mailId: 'xyz@mail.com', isVerified: false },
    ];

    const handleViewCompany=(id)=>{
        navigate('/admin/companydetail',{state:{ org_id: id }})
        // console.log("state:",id)
    }
    return (
        <div
            className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
        >
            <div className={`flex w-full max-w-7xl max-h-full h-full ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-11 mt-4 px-2`}>
                <table className="table-auto border-collapse border border-gray-400 w-full">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 px-4 py-2">ID</th>
                            <th className="border border-gray-300 px-4 py-2">Legal entity name</th>
                            <th className="border border-gray-300 px-4 py-2">CIN</th>
                            <th className="border border-gray-300 px-4 py-2">PAN</th>
                            <th className="border border-gray-300 px-4 py-2">Phone Number</th>
                            <th className="border border-gray-300 px-4 py-2">Official Mail Id</th>
                            <th className="border border-gray-300 px-4 py-2">Verification status</th>
                            <th className="border border-gray-300 px-4 py-2"></th>
                        </tr>
                    </thead>
                    {data.map((companies) => (
                        <tbody >
                            <tr className='p-2 border'>
                                <td className='p-2 text-center'>{companies.id}</td>
                                <td className='p-2 text-center'>{companies.legalEntityName}</td>
                                <td className='p-2 text-center'>{companies.cin}</td>
                                <td className='p-2 text-center'>{companies.pan}</td>
                                <td className='p-2 text-center'>{companies.phone}</td>
                                <td className='p-2 text-center'>{companies.mailId}</td>
                                <td className={`p-2 text-center ${companies.isVerified ? 'text-blue-600' : 'text-red-500'}`}>{companies.isVerified ? "Verified" : "Pending"}</td>
                                <td className='p-2 text-center'><button onClick={()=>handleViewCompany(companies.id)} className='cursor-pointer'><FaRegEye color='blue' size={40}/></button></td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default AllOrganizations