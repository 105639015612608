import React from 'react'
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box, DialogTitle } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IoMdClose } from "react-icons/io";

function ResponseModal({ onClose, open, message,resultColor,icon }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle className='flex justify-end items-end '><IoMdClose onClick={onClose} className='cursor-pointer'/></DialogTitle>
      <DialogContent style={{ textAlign: 'center', padding: '2rem' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={80}
          height={80}
          borderRadius="50%"
          bgcolor={resultColor} // Success color
          color="white"
          margin="auto"
        >
          {icon}
        </Box>
        
        <Typography variant="body2" color="black" sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default ResponseModal