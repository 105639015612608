import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message, Spin } from 'antd'
import { useLoginForm } from '../../../helper/auth/authFormHook'
import { signinUser } from '../../../helper/auth/authRequestCall'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

function Login() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const[userName,setUserName]=useState("")

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSignIn = (values) => {
    setLoading(true)
    const username = values.userName.toLowerCase()
    const password = values.password
    signinUser(username, password)
      .then((data) => {
        if (data && data.status === 200) {
          const token = data.data.data.token
          const refreshToken = data.data.data.token
          localStorage.setItem('authToken', token)
          localStorage.setItem('refresh', refreshToken)
          message.success("Login successful")
          window.location.href = '/dashboard'
        } else if(data.status===401){
          message.error("User not activated")
        }else{
          message.error("Login Failed")
        }
      })
      .catch((err) => {
        console.error("Error occurred", err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const formik = useLoginForm(handleSignIn)
  return (
    <Fragment>
      <div className={`flex flex-col justify-center items-center min-h-screen p-8 ${!isDark ? 'bg-blue-950' : 'dark-mode'} bg-opacity-90`}>
        <div className={`w-full max-w-xs md:max-w-sm p-8 space-y-6 ${!isDark ? 'bg-blue-100' : 'bg-slate-500'} rounded-lg`}>
          <h1 className='text-black text-2xl font-bold text-center mb-4'>Sign In</h1>
          <form className='flex flex-col space-y-4' onSubmit={formik.handleSubmit}>
            <div className='relative w-full mb-4'>
              <input
                type='email'
                id='userName'
                placeholder='Enter your email'
                className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100 ${formik.touched.userName && formik.errors.userName ? 'border-red-600' : 'border-gray-400'}`}
                onChange={formik.handleChange}
                // onChange={(e)=>setUserName(e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                // value={userName}
              />
              <label htmlFor='userName' className='flex absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-blue-100'>
                Email<span className='text-red-600 ml-1 '>*</span>
              </label>
              {formik.touched.userName && formik.errors.userName && <div className='text-red-700 ml-2 mt-1'>{formik.errors.userName}</div>}
            </div>

            <div className='relative w-full mb-4'>
              <input
                type={showPassword ? "text" : "password"}
                id='password'
                placeholder='Enter your password'
                className={`w-full p-2 border border-gray-800 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-blue-100 ${formik.touched.password && formik.errors.password ? 'border-red-600' : 'border-gray-400'}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <label htmlFor='password' className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-blue-100'>
                Password <span className='text-red-600 ml-1'>*</span>
              </label>
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEye/> : <FaEyeSlash/>}
              </button>
              {formik.touched.password && formik.errors.password && <div className='text-red-700 ml-2 mt-1'>{formik.errors.password}</div>}
            </div>

            <div className="flex justify-between mt-2 text-sm">
              <p>Don't have an account? <Link to='/register' className='text-black font-bold underline'>Sign up</Link></p>
              <Link to='/login/forgot-password' className='text-blue-700'>Forgot password?</Link>
            </div>

            <div className='p-2 text-center'>
              <span className='text-gray-800 text-sm'>By continuing, you agree to Inkqubee's Terms and Conditions of Use.</span>
            </div>

            <div className='flex justify-center items-center'>
              {loading ? (
                <button className='btn w-full' disabled>
                  <Spin className='custom-spinner' />
                </button>
              ) : (
                <input type='submit' value='Sign In' className='btn w-full' />
              )}
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default Login
