import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { investorProfile } from '../../../../helper/investors/investorRequestCall'
import { useParams } from 'react-router-dom'

function InvestorDetail() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [investorDetail,setDetail]=useState(null)
  const {id}=useParams()

  const getInvestorProfile = () => {
    // console.log("profile")
    investorProfile(id)
        .then((res) => {
            if (res.status === 200) {
                setDetail(res.data.data)
                // setVerified(res.data.verified)
            }
        })
        .catch((err) => {
            console.error(err)
        })
}

useEffect(()=>{
    getInvestorProfile()
},[])

  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
        <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
          {investorDetail && (
            <>
            <div className='flex flex-col'>
              <div className='flex flex-col w-full'>
                <h1 className='text-center text-lg text-blue-400'>Personal Info</h1>
                <div className="flex flex-col w-full justify-start items-start bg-white p-6 rounded-lg shadow-md text-black space-y-4 mt-4">
                  <div className="flex justify-between w-full">
                    <label className="text-gray-500 font-semibold">Name:</label>
                    <span className="text-gray-400 ml-4">{investorDetail.firstName} {investorDetail.lastName}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-500 font-semibold">Investment Size:</label>
                    <span className="text-gray-400 ml-4">{investorDetail.investmentSize.minInvestment}-{investorDetail.investmentSize.maxInvestment}</span>
                  </div>
                </div>
  
                <h1 className='text-center mt-2 text-lg text-blue-400'>Investment Preference</h1>
                <div className='flex  w-full justify-start items-start bg-white p-6 rounded-lg shadow-md text-black space-y-4 mt-4'>
                  <div className="flex flex-col  w-full">
                    <label className="text-gray-500 font-semibold">Types Of Investment:</label>
                    <ul  className='flex p-1'>
                          <li className='p-2 rounded-full border text-gray-700'>{investorDetail.investmentType}</li>
                        </ul>
                  </div>
  
                  <div className="flex flex-col justify-between w-full">
                    <label className="text-gray-500 font-semibold">Industry Preference:</label>
                    <div className='grid grid-cols-2 p-2'>
                      {investorDetail.industryPreferences.map((industries, index) => (
                        <ul key={index} className='flex p-1'>
                          <li className='p-2 rounded-full border text-gray-700'>{industries}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
  
                  <div className="flex flex-col justify-between w-full">
                    <label className="text-gray-500 font-semibold">Location Preference:</label>
                    <div className='grid grid-cols-2 p-2'>
                      {investorDetail.preferredLocations.map((location, index) => (
                        <ul key={index} className='flex p-1'>
                          <li className='p-2 rounded-full border text-gray-700'>{location}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-full h-auto ml-2 justify-start bg-white p-6 rounded-lg shadow-md text-black space-y-4 mt-4'>
                <h1 className='text-center text-lg text-blue-400'>About</h1>
                <p className='text-gray-700'>{investorDetail.profileDescription}</p>
              </div>
            </div>
            </>
            )}
        </div>
      </div >
  )
}

export default InvestorDetail