import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filterTogle, sidebarToggle } from '../store/slices/sidebarSlice';
import userAvatar from "../../src/assets/userAvatar.jpg"
import { jwtDecode } from 'jwt-decode';
import { getUserProfile } from '../helper/auth/authRequestCall';
import { setUserDetails } from '../store/slices/authSlice';


function AppSidebar({ menuItems }) {
    const location = useLocation();
    const [active, setActive] = useState(location.pathname);
    const { isSidebar, isFilter } = useSelector((state) => state.sidebarState)
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const { user } = useSelector((state) => state.authenticationState)
    const [hover, setHover] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getUser=()=>{
        const token = localStorage.getItem('authToken')
        const decoded=jwtDecode(token)
        const userId=decoded.userId
        getUserProfile(userId)
        .then(data=>{
         const userProfile = data.data
         dispatch(setUserDetails(userProfile))
        })
        .catch(err =>{
          console.error(err)
        })
  }

  useEffect(()=>{
     getUser()
  },[])

    useEffect(() => {
        setActive(location.pathname);
    }, [location.pathname]);


    const handleRouteActive = (path) => {
        setActive(path)
    }

    // useEffect(()=>{
    //     if (location.pathname === "/service-provider/list") {
    //         dispatch(filterTogle(true))
    //         dispatch(sidebarToggle(false))
    //       }
    // },[location,dispatch])

    return (
        <aside className={`${isSidebar ? "hidden" : "block"} sticky top-0 ${hover ? "w-64" : "w-10"} h-screen ${isDark ? 'bg-slate-900' : 'bg-blue-800'} border-r-2 duration-300 border-gray-500`} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <div className='flex flex-col  mt-4'>
                <ul className='space-y-4 '>
                    
                        {/* <div className='px-4'> */}
                        {/* {user &&
                        <div className='flex flex-col items-center cursor-pointer p-2' >
                           <img src={userAvatar} alt='avatar' className={` ${hover ? "h-20 w-20" : "h-8 w-16"} rounded-full mt-11 ml-1` }/>
                           {hover && <span className='p-6 px-7 text-white'>{user.firstName}</span> }
                        </div>} */}
                        {/* </div> */}
                    


                    {menuItems && menuItems.map((item, index) => ( 

                        <li key={index} className='mt-8'>
                           {item.disabled ? <Link
                                onClick={() => handleRouteActive(item.to)}
                                className={`flex p-2 font-medium text-gray-300 '
                                    }`}
                            >
                                <span className='me-3 '>{item.icon}</span>
                                {hover && <span className="text-sm">{item.name}</span>}
                            </Link> : <Link
                                to={item.to}
                                onClick={() => handleRouteActive(item.to)}
                                className={`flex p-2 font-medium  ${active === item.to ? 'bg-blue-500 text-white' : 'text-gray-200 '
                                    }`}
                            >
                                <span className='me-3 '>{item.icon}</span>
                                {hover && <span className="text-sm">{item.name}</span>}
                            </Link>}
                        </li>
                    ))}
                </ul>
                {/* <div className='ml-5 border-t p-2 bottom-0 mt-56'>
                <button className='text-white flex hover:text-red-700'>
                  <BiLogOutCircle className='mt-1 mb-2 me-2' size={20}/> <span className='mt-0.5'>Logout</span> 
                </button>
            </div> */}
            </div>
        </aside>
    )
}

export default AppSidebar