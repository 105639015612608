import React from 'react'
import { FaFile } from "react-icons/fa";
import { Base_url } from '../../../utils/constant';
import { Tooltip } from 'antd';
import { FaExternalLinkAlt } from "react-icons/fa";

function CompanyOverview({ profile }) {
    // const documentName = profile.registered_document.split("/").pop();
    return (
        <div>
            <table border='1' style={{ width: "100%", textAlign: "left" }}>
                <tbody >
                   {profile.legalEntityName &&<tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Legal entity name</td>
                        <td className='border border-gray-200 p-2'>{profile.legalEntityName}</td>
                    </tr>} 
                    <tr >
                        <td className='border border-gray-200 p-2 font-semibold'>CIN</td>
                        <td className='border border-gray-200 p-2'>{profile.cin}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>PAN</td>
                        <td className='border border-gray-200 p-2'>{profile.pan}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Official mail</td>
                        <td className='border border-gray-200 p-2'>{profile.officialMailId}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Brand name</td>
                        <td className='border border-gray-200 p-2'>{profile.companyBrandName}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Number of employees</td>
                        <td className='border border-gray-200 p-2'>{profile.noOfEmployees}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Address</td>
                        <td className='border border-gray-200 p-2'>{profile.communicationAddress.addressLine1},{profile.communicationAddress.addressLine2},{profile.communicationAddress.postalCode}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>State and country</td>
                        <td className='border border-gray-200 p-2'>{profile.communicationAddress.state.name},{profile.communicationAddress.country.name}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Services</td>
                        <td className='border border-gray-200 p-2'>{profile.services}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Description</td>
                        <td className='border border-gray-200 p-2'>{profile.businessDescription}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Establishment Year</td>
                        <td className='border border-gray-200 p-2'>{profile.establishmentYear}</td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Company Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.companyUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>LinkedIn Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.companyUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Instagram Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.instagramUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Twiter(X) Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.twitterUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Youtube Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.youtubeUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>Facebook Url</td>
                        <td className='border border-gray-200 p-2'>
                            <a href={profile.facebookUrl} target='_blank' className='flex text-blue-500 hover:text-blue-900 cursor-pointer'>View <FaExternalLinkAlt className='mt-1 ml-1'/></a>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className='border border-gray-200 p-2 font-semibold'>File</td>
                        <td className='border border-gray-200 p-4'>
                        <Tooltip placement='top' title="view file">
                           <a href={`${Base_url}/${profile.registered_document}`} 
                           target='_blank' rel="noopener noreferrer"  
                           className='flex border border-gray-500 bg-white text-black hover:bg-gray-300 p-2 w-56 cursor-pointer'><FaFile  className='mt-1 me-1'/>{documentName}</a> 
                        </Tooltip>
                        </td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default CompanyOverview