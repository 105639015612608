import React from 'react'
import Header from '../../components/public/Header'
import '../../App.css';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/public/Footer';
import AppSidebar from '../../components/AppSidebar';
import PrivateHeader from '../../components/private/PrivateHeader';
import { adminMenu } from '../../utils/tempJson/_menuItems';
import FilterBar from '../../components/FilterBar';
import { useSelector } from 'react-redux';

function AdminLayout({
    auth,
    children, selectedItems, setSelectedItems}) {
    const location = useLocation()
    const { isSidebar } = useSelector((state) => state.sidebarState)
    return (
        <div className='flex flex-col min-h-screen'>
            <Header auth={auth} />
            <PrivateHeader />
            <div className='flex flex-1'>
                <AppSidebar menuItems={adminMenu} />
                {/* {location.pathname === "/service-provider/list" && isSidebar && <FilterBar selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>} */}
                {children}
            </div>
            <Footer />
        </div >
    )
}

export default AdminLayout
