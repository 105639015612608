import React from 'react';

import { useNavigate } from 'react-router-dom';
import { basic_plan, premium_plan } from '../../../utils/constant';

const PackageTable = () => {
  const navigate = useNavigate();

  return (
    <div className="package-table flex flex-col min-h-screen overflow-auto justify-center items-center w-full bg-blue-300 py-2">
      
      <header className="header mt-28">
        <h1 className='package-h1'>Inkqubee Packages</h1>
      </header>

      <div className="package-container">
        <section className="package-card">
          <h3 className="basic-plan-heading">Basic Plan</h3>
          <div className="price-container">
            <p className="price">
              INR {basic_plan} <span className="star">★</span> per month
            </p>
          </div>
          <ul className="features-list">
            <li>Essential features for individuals or small businesses.</li>
            <li>Priority listing for your services.</li>
            <li>Verified badge for authenticity.</li>
            <li>Limited portfolio and case study display.</li>
            <li>Client feedback and reviews.</li>
            <li>Reliable, cost-effective, and provides essential features!</li>
          </ul>
          <div className="buttons-container">
            <a href="/contact" className="get-in-touch-btn">Get in Touch</a>
            <button className="payment-btn" onClick={()=>navigate('/Payment-Page')}>Make Payment</button>
          </div>
        </section>

        <section className="package-card priority">
          <h3 className="sponsor-plan-heading">Premium Plan</h3>
          <div className="price-container">
            <p className="price">
              INR {premium_plan} <span className="star">★</span> per month
            </p>
          </div>
          <ul className="features-list">
            <li>Premium plan with unlimited portfolio and advanced features.</li>
            <li>Priority listing with top visibility.</li>
            <li>Verified badge for authenticity.</li>
            <li>Unlimited portfolio and case study display.</li>
            <li>Client feedback and reviews.</li>
            <li>Advanced verification options (CWTIN, etc.).</li>
            <li>Social media integration.</li>
            <li>No competitor profiles shown when viewing your profile.</li>
            <li>Ideal for businesses seeking maximum visibility, reach, and exposure!</li>
          </ul>
          <div className="buttons-container">
            <a href="/contact" className="get-in-touch-btn">Get in Touch</a>
            <button className="payment-btn" onClick={()=>navigate('/Payment-Page')}>Make Payment</button>
          </div>
          
        </section>
      </div>
    </div>
  );
};

export default PackageTable;
